import {Box} from "@material-ui/core"
import {RawDraftContentState} from "draft-js"
import AppTypography from "../base/AppTypography"
import CommentForm from "./CommentForm"
import CommentsList from "./CommentsList"

export default function CommentThread(props: {
  onSubmit: (values: {comment_text: RawDraftContentState}) => void
  threadId?: number
  title: string
}) {
  return (
    <Box bgcolor="grey.300" borderRadius={"borderRadius"} minWidth={300}>
      <Box marginLeft={2} paddingTop={1}>
        <AppTypography>{props.title}</AppTypography>
      </Box>
      {props.threadId && <CommentsList commentThreadId={props.threadId} />}
      <Box padding={1}>
        <CommentForm onSubmit={props.onSubmit} disableToolbar />
      </Box>
    </Box>
  )
}
