import {useRouteMatch} from "react-router-dom"
import SitePage from "../../components/attendee-site/page/SitePage"
import AttendeeSite from "../../components/attendee-site/website/AttendeeSite"
import App404Message from "../../components/base/App404Message"
import AppLoadingScreen from "../../components/base/AppLoadingScreen"
import PageBody from "../../components/page/PageBody"
import PageContainer from "../../components/page/PageContainer"
import {replaceDashes} from "../../utils"
import {
  useAttendeeLandingPageName,
  useAttendeeLandingWebsiteName,
  useRetreat,
} from "../../utils/retreatUtils"
import LoadingPage from "../misc/LoadingPage"
import NotFound404Page from "../misc/NotFound404Page"

export default function AttendeeSitePage() {
  let router = useRouteMatch<{websiteName: string; pageName: string}>()
  let {websiteName, pageName} = router.params
  let activePage = replaceDashes(pageName ?? "home")
  let [website, websiteLoading] = useAttendeeLandingWebsiteName(
    replaceDashes(websiteName)
  )
  let [retreat, retreatLoading] = useRetreat(website?.retreat_id ?? -1)
  let [page, pageLoading] = useAttendeeLandingPageName(
    website?.id ?? -1,
    activePage
  )
  if (retreat && retreat.id === 1791) {
    window.location.replace("https://croallhands2022.com/")
    return <LoadingPage />
  }
  return websiteLoading || retreatLoading ? (
    <LoadingPage />
  ) : !website || !retreat ? (
    <NotFound404Page />
  ) : (
    <PageContainer>
      <PageBody>
        <AttendeeSite
          activePage={activePage}
          retreat={retreat}
          website={website}
          pageBody={
            pageLoading ? (
              <AppLoadingScreen />
            ) : !page ? (
              <App404Message />
            ) : (
              <SitePage pageId={page.id} />
            )
          }
        />
      </PageBody>
    </PageContainer>
  )
}
