import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  TextField,
  useTheme,
} from "@material-ui/core"
import {Close, InsertDriveFile} from "@material-ui/icons"
import {Autocomplete} from "@material-ui/lab"
import {convertFromRaw, convertToRaw, EditorState} from "draft-js"
import {useFormik} from "formik"
import {ReactElement, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import * as yup from "yup"
import {
  FileModel,
  ItineraryEventLabel,
  ItineraryEventModel,
} from "../../models/retreat"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {RootState} from "../../store"
import {ApiAction} from "../../store/actions/api"
import {
  deleteFileToEvent,
  deleteItineraryEvent,
  getItineraryEvent,
  patchItineraryEvent,
  postComment,
  postCommentThread,
  postFileToEvent,
  postItineraryEvent,
} from "../../store/actions/retreat"
import {useScrollToComment} from "../../utils/commentUtils"
import {splitFileName} from "../attendee-site/EditWebsiteForm"
import AppTypography from "../base/AppTypography"
import AppUploadFile from "../base/AppUploadFile"
import {AppWysiwygEditor} from "../base/AppWysiwyg"
import CommentForm from "../comments/CommentForm"
import CommentsList from "../comments/CommentsList"
import ItineraryEventDropDown from "./ItineraryEventDropdown"
import {addToDate} from "./ItineraryForm"

let useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    minWidth: "600px",
    gap: theme.spacing(1),
    "& .MuiOutlinedInput-root:not(:hover):not(:focus-within) .MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
    "& .input:not(:hover):not(:focus-within)": {
      border: "none",
    },
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  timesWrapper: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },
  submitButton: {
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  },
  autocomplete: {
    minWidth: 160,
    width: "fit-content",
    "& .MuiChip-deleteIcon": {
      display: "none",
    },
  },
}))
export const ITINERARY_EVENT_LABELS = [
  "MEETING",
  "ACTIVITY",
  "MEAL",
  "TRANSPORTATION",
]
export default function ItineraryEventForm(props: {
  itineraryId: number
  onSubmit: () => void
  days: number
  eventId?: number
  open: boolean
  onClose: () => void
  startDate?: string
}) {
  let classes = useStyles()
  let theme = useTheme()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [loadingEvent, setLoadingEvent] = useState(false)
  let event = useSelector((state: RootState) => {
    if (props.eventId) {
      return state.retreat.itineraryEvents[props.eventId]
    }
  })
  let startCommentId = useScrollToComment()
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadEvent(eventId: number) {
      setLoadingEvent(true)
      await dispatch(getItineraryEvent(eventId))
      setLoadingEvent(false)
    }
    if (!event && props.eventId) {
      loadEvent(props.eventId)
    }
  }, [dispatch, event, props.eventId])
  let formik = useFormik({
    initialValues: {
      title: event?.title ?? "",
      description: event?.description
        ? EditorState.createWithContent(convertFromRaw(event.description))
        : EditorState.createEmpty(),
      day: event?.day,
      start_time: event?.start_time ?? "",
      end_time: event?.end_time ?? "",
      location: event?.location ?? "",
      label: event?.label,
      files: (event?.files ?? []) as FileModel[] | undefined,
    },
    onSubmit: async (values, formikHelpers) => {
      let formikFiles = [...(values.files ? values.files : [])]
      let postValues = {
        ...values,
        description: convertToRaw(values.description.getCurrentContent()),
        day: values.day,
      }
      delete postValues.files
      let response = props.eventId
        ? ((await dispatch(
            patchItineraryEvent(postValues, props.eventId)
          )) as unknown as ApiAction)
        : ((await dispatch(
            postItineraryEvent(postValues, props.itineraryId)
          )) as unknown as ApiAction)
      if (!response.error) {
        formikHelpers.resetForm()
        let filesToPost = event
          ? fileDifference(formikFiles, event?.files)
          : formikFiles
        await Promise.all(
          filesToPost.map(async (file) => {
            let fileResponse = (await dispatch(
              postFileToEvent({
                event_id: response.payload.event.id,
                file_id: file.id,
              })
            )) as unknown as ApiAction
            if (!fileResponse.error) {
              dispatch({
                type: "ADD_FILE_TO_EVENT",
                file: file,
                event_id: response.payload.event.id,
              })
            }
          })
        )
        props.onSubmit()
      }
    },
    validationSchema: yup.object({
      title: yup.string().required("Event Title required"),
    }),
  })
  let {resetForm} = formik
  useEffect(() => {
    resetForm({
      values: {
        title: event?.title ?? "",
        description: event?.description
          ? EditorState.createWithContent(convertFromRaw(event.description))
          : EditorState.createEmpty(),
        day: event?.day,
        start_time: event?.start_time ?? "",
        end_time: event?.end_time ?? "",
        location: event?.location ?? "",
        label: event?.label,
        files: (event?.files ?? []) as FileModel[] | undefined,
      },
    })
  }, [event, resetForm])

  function returnSaveOnChange(field: string, eventId: number) {
    return (e: any) => {
      formik.setFieldValue(field, e.target.value)
      dispatch(
        patchItineraryEvent(
          {[field]: e.target.value ? e.target.value : null},
          eventId
        )
      )
    }
  }
  function returnSaveOnBlur(field: string, eventId: number) {
    return () => {
      if (!formik.errors[field as keyof typeof formik.errors]) {
        dispatch(
          patchItineraryEvent(
            {[field]: formik.values[field as keyof typeof formik.values]},
            eventId
          )
        )
      }
    }
  }
  function getDaysOptions(days: number) {
    let returnValue = [<option value={"none"} key={"none"}></option>]
    for (let i = 0; i < days; i++) {
      returnValue.push(
        <option value={i + 1} key={i + 1}>
          {props.startDate
            ? new Intl.DateTimeFormat("en-US", {
                weekday: "short",
                day: "numeric",
                month: "long",
              }).format(new Date(addToDate(props.startDate, i + 2)))
            : `Day ${i + 1}`}
        </option>
      )
    }
    return returnValue
  }

  function fileDifference(fileArr1: FileModel[], fileArr2: FileModel[]) {
    let fileMap: {[id: number]: true} = {}
    fileArr1.forEach((file) => {
      fileMap[file.id] = true
    })
    return fileArr2.filter((file) => !fileMap[file.id])
  }
  let [retreat] = useRetreat()
  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog
        className={classes.form}
        open={props.open}
        onClose={props.onClose}
        maxWidth={"md"}
        fullWidth>
        <DialogContent>
          <Box display={"flex"} alignItems="center">
            <TextField
              error={!!formik.errors.title}
              helperText={formik.errors.title}
              variant="outlined"
              onBlur={
                props.eventId
                  ? returnSaveOnBlur("title", props.eventId)
                  : undefined
              }
              id="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              placeholder="Activity Title"
              fullWidth
              InputProps={{
                classes: {
                  input: classes.title,
                },
              }}
            />
            {props.eventId && event && (
              <ItineraryEventDropDown
                onDuplicate={async () => {
                  let values = {...event} as Partial<ItineraryEventModel>
                  delete values.id
                  delete values.itinerary_id
                  delete values.created_at
                  delete values.created_by_user_id
                  delete values.files
                  delete values.comment_thread_id
                  let response = (await dispatch(
                    postItineraryEvent(values, event!.itinerary_id)
                  )) as unknown as ApiAction
                  await Promise.all(
                    event!.files.map(async (file) => {
                      let fileResponse = (await dispatch(
                        postFileToEvent({
                          event_id: response.payload.event.id,
                          file_id: file.id,
                        })
                      )) as unknown as ApiAction
                      if (!fileResponse.error) {
                        dispatch({
                          type: "ADD_FILE_TO_EVENT",
                          file: file,
                          event_id: response.payload.event.id,
                        })
                      }
                    })
                  )
                  props.onClose()
                }}
                onDelete={() => {
                  props.onClose()
                  dispatch(deleteItineraryEvent(props.eventId as number))
                }}
              />
            )}
          </Box>

          <SideLabelInput
            label="Date"
            input={
              <TextField
                size="small"
                variant={"outlined"}
                select
                id="day"
                onChange={(e: any) => {
                  let day = e.target.value !== "none" ? e.target.value : null
                  formik.setFieldValue("day", day)
                  props.eventId &&
                    dispatch(
                      patchItineraryEvent(
                        {
                          day,
                        },
                        props.eventId!
                      )
                    )
                }}
                value={formik.values.day ?? "none"}>
                {getDaysOptions(props.days)}
              </TextField>
            }
          />

          <SideLabelInput
            label="Start Time"
            input={
              <TextField
                id="start_time"
                size="small"
                onChange={
                  props.eventId
                    ? returnSaveOnChange("start_time", props.eventId)
                    : formik.handleChange
                }
                value={formik.values.start_time}
                type="time"
                variant={"outlined"}
              />
            }
          />
          <SideLabelInput
            label="End Time"
            input={
              <TextField
                size="small"
                id="end_time"
                onChange={
                  props.eventId
                    ? returnSaveOnChange("end_time", props.eventId)
                    : formik.handleChange
                }
                value={formik.values.end_time}
                type="time"
                variant={"outlined"}
              />
            }
          />
          <SideLabelInput
            label="Location"
            input={
              <TextField
                size="small"
                onBlur={
                  props.eventId
                    ? returnSaveOnBlur("location", props.eventId)
                    : undefined
                }
                variant={"outlined"}
                id="location"
                onChange={formik.handleChange}
                value={formik.values.location}
              />
            }
          />
          <SideLabelInput
            label="Label"
            input={
              <Autocomplete
                size="small"
                autoSelect
                multiple
                className={classes.autocomplete}
                filterSelectedOptions
                value={formik.values.label ? [formik.values.label] : []}
                options={ITINERARY_EVENT_LABELS}
                getOptionLabel={(option) => {
                  if (option) {
                    return `${
                      option[0].toUpperCase() + option.slice(1).toLowerCase()
                    }`
                  } else return ""
                }}
                id="assignees"
                filterOptions={(x) => x}
                onChange={(e, newVals) => {
                  let newValue = formik.values.label ? newVals[1] : newVals[0]
                  formik.setFieldValue("label", newValue)
                  if (props.eventId) {
                    dispatch(
                      patchItineraryEvent(
                        {
                          // @ts-ignore
                          label: newValue
                            ? (newValue as ItineraryEventLabel)
                            : null,
                        },
                        props.eventId
                      )
                    )
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant={"outlined"} id="assignees" />
                )}
              />
            }
          />

          <SideLabelInput
            label="Description"
            input={
              <Box
                className="input"
                border={"1px solid black"}
                borderRadius={theme.shape.borderRadius}
                onBlur={() => {
                  if (!formik.errors.description && event) {
                    dispatch(
                      patchItineraryEvent(
                        {
                          description: convertToRaw(
                            formik.values.description.getCurrentContent()
                          ),
                        },
                        event.id
                      )
                    )
                  }
                }}>
                <AppWysiwygEditor
                  editorState={formik.values.description}
                  onEditorStateChange={(val) => {
                    formik.setFieldValue("description", val)
                  }}
                />
              </Box>
            }
            multiline
          />
          <div>
            <Box
              clone
              style={{
                fontSize: "0.9rem",
                color: theme.palette.grey[600],
                width: "30%",
                marginRight: "auto",
              }}>
              {<AppTypography noWrap>Attachments</AppTypography>}
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems="center"
              gridGap={8}>
              {formik.values.files && formik.values.files.length > 0 && (
                <Box
                  overflow={"auto"}
                  flex={1}
                  display={"flex"}
                  flexDirection={"row"}
                  gridGap={8}>
                  {formik.values.files &&
                    formik.values.files.map((file) => {
                      return (
                        <EventFile
                          key={file.id}
                          url={file.file_url}
                          onDelete={
                            props.eventId
                              ? () => {
                                  dispatch(
                                    deleteFileToEvent(
                                      file.id,
                                      props.eventId as number
                                    )
                                  )
                                }
                              : () => {
                                  if (formik.values.files) {
                                    let files = [...formik.values.files]
                                    let index = formik.values.files.findIndex(
                                      (arrFile) => {
                                        return arrFile.id === file.id
                                      }
                                    )
                                    if (index !== -1) {
                                      files.splice(index, 1)
                                    }
                                    formik.setFieldValue("files", files)
                                  }
                                }
                          }
                        />
                      )
                    })}
                </Box>
              )}

              <Box>
                <AppUploadFile
                  alt
                  accepts="image/png, image/jpg, application/pdf, image/jpeg"
                  rightText={""}
                  id="file"
                  handleChange={async (file) => {
                    if (props.eventId) {
                      let response = (await dispatch(
                        postFileToEvent({
                          event_id: props.eventId ?? -1,
                          file_id: file.id,
                        })
                      )) as unknown as ApiAction
                      if (!response.error) {
                        dispatch({
                          type: "ADD_FILE_TO_EVENT",
                          file: file,
                          event_id: props.eventId,
                        })
                      }
                    } else {
                      formik.setFieldValue("files", [
                        ...(formik.values.files ? formik.values.files : []),
                        file,
                      ])
                    }
                  }}
                />
              </Box>
            </Box>
            {event?.comment_thread_id && (
              <SideLabelInput
                input={
                  <CommentsList
                    commentThreadId={event.comment_thread_id}
                    startCommentId={startCommentId}
                  />
                }
                label="Comments"
                multiline
              />
            )}
          </div>
        </DialogContent>
        {!props.eventId ? (
          <Box bgcolor={"grey.300"} paddingTop={1}>
            <DialogActions>
              <Button
                className={classes.submitButton}
                type="submit"
                onClick={() => {
                  formik.handleSubmit()
                }}
                color="primary"
                variant="contained">
                Create Event
              </Button>
            </DialogActions>
          </Box>
        ) : (
          <Box bgcolor={"grey.300"} paddingTop={1}>
            <DialogActions>
              <Box width="100%">
                <CommentForm
                  onSubmit={async (values) => {
                    if (event?.comment_thread_id) {
                      dispatch(
                        postComment({
                          ...values,
                          comment_thread_id: event.comment_thread_id,
                          retreat_id: retreat.id,
                          redirect: `/itinerary/builder/events/${event.id}?comment=:id`,
                        })
                      )
                    } else {
                      let threadResponse = (await dispatch(
                        postCommentThread()
                      )) as unknown as ApiAction
                      if (!threadResponse.error && props.eventId) {
                        let eventResponse = (await dispatch(
                          patchItineraryEvent(
                            {
                              comment_thread_id:
                                threadResponse.payload.comment_thread.id,
                            },
                            props.eventId
                          )
                        )) as unknown as ApiAction

                        if (!eventResponse.error) {
                          dispatch(
                            postComment({
                              ...values,
                              comment_thread_id:
                                threadResponse.payload.comment_thread.id,
                              retreat_id: retreat.id,
                              redirect: event
                                ? `/itinerary/builder/events/${event.id}?comment=:id`
                                : "/itinerary/builder",
                            })
                          )
                        }
                      }
                    }
                  }}
                />
              </Box>
            </DialogActions>
          </Box>
        )}
      </Dialog>
    </form>
  )
}

export function SideLabelInput(props: {
  label: string
  input: ReactElement
  multiline?: boolean
  placeholder?: ReactElement
  hidePlaceholder?: boolean
  viewer?: boolean
}) {
  let theme = useTheme()
  let [active, setActive] = useState(false)
  return (
    <Box
      display={"flex"}
      gridGap={props.multiline ? 8 : 60}
      width={"100%"}
      alignItems="flex-start"
      flexDirection={props.multiline ? "column" : "row"}>
      <Box
        clone
        style={{
          fontSize: "0.9rem",
          color: theme.palette.grey[600],
          width: props.viewer ? "60%" : "40%",
          marginRight: "auto",
        }}>
        <AppTypography noWrap>{props.label}</AppTypography>
      </Box>
      <Box marginRight={props.multiline ? 0 : 16} width="100%">
        {props.placeholder && !props.hidePlaceholder ? (
          active ? (
            <Box>{props.input}</Box>
          ) : (
            <Box
              onMouseEnter={() => {
                setActive(true)
              }}>
              {props.placeholder}
            </Box>
          )
        ) : (
          <Box>{props.input}</Box>
        )}
      </Box>
    </Box>
  )
}

export function EventFile(props: {url: string; onDelete?: () => void}) {
  return (
    <Box
      display={"flex"}
      bgcolor={"background.default"}
      borderRadius={10}
      border={"1px solid black"}
      alignItems={"center"}>
      <Box
        clone
        height={55}
        display={"flex"}
        padding={2}
        maxWidth={200}
        style={{textDecoration: "none"}}
        alignItems={"center"}>
        <a href={props.url} target={"_blank"} rel="noreferrer">
          <InsertDriveFile />
          &nbsp;
          <AppTypography underline={false} noWrap>
            {splitFileName(props.url)}
          </AppTypography>
        </a>
      </Box>
      {props.onDelete && (
        <IconButton size="small" onClick={props.onDelete}>
          <Close fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}
