import {Box, Tab, Tabs} from "@material-ui/core"
import {push} from "connected-react-router"
import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import AttendeeFlightsDataGrid from "../../components/attendee-site/AttendeeFlightsDataGrid"
import {PageBlock} from "../../components/attendee-site/block/PageBlock"
import AttendeeSite from "../../components/attendee-site/website/AttendeeSite"
import AppLoadingScreen from "../../components/base/AppLoadingScreen"
import AttendeeFlightTab from "../../components/flights/AttendeeFlightTab"
import AppTabPanel from "../../components/page/AppTabPanel"
import PageBody from "../../components/page/PageBody"
import PageContainer from "../../components/page/PageContainer"
import {SampleLockedAttendees} from "../../models/retreat"
import {AppRoutes} from "../../Stack"
import {replaceDashes, useQuery} from "../../utils"
import {
  useAttendeeLandingPage,
  useAttendeeLandingWebsiteName,
  useMyAttendee,
  useRetreat,
} from "../../utils/retreatUtils"
import {useLoggedInUser} from "../../utils/userUtils"
import LoadingPage from "../misc/LoadingPage"
import NotFound404Page from "../misc/NotFound404Page"

export default function AttendeeSiteFlightsPage() {
  let dispatch = useDispatch()
  let router = useRouteMatch<{websiteName: string}>()
  let {websiteName} = router.params
  let [website, websiteLoading] = useAttendeeLandingWebsiteName(
    replaceDashes(websiteName)
  )
  let [retreat, retreatLoading] = useRetreat(website?.retreat_id ?? -1)
  let [page, pageLoading] = useAttendeeLandingPage(
    retreat?.flights_page_id ?? -1
  )
  let [user, userLoading] = useLoggedInUser()
  let [attendee] = useMyAttendee(retreat != null ? retreat.id : -1)

  // Tab related
  let [tabQuery, setTabQuery] = useQuery("tab")
  let [tabValue, setTabValue] = useState<string | undefined>(undefined)
  useEffect(() => {
    let TABS = ["instructions", "my-flight"]
    if (!retreat?.hide_group_flights) {
      TABS.push("group")
    }
    setTabValue(tabQuery && TABS.includes(tabQuery) ? tabQuery : "instructions")
  }, [tabQuery, setTabValue, retreat])

  useEffect(() => {
    if (!userLoading && !user) {
      dispatch(
        push(
          AppRoutes.getPath(
            "SigninPage",
            {
              websiteName: websiteName,
            },
            {
              next: encodeURIComponent(
                AppRoutes.getPath("AttendeeSiteFlightsPage", {
                  websiteName: websiteName,
                })
              ),
              "login-type": "attendee",
            }
          )
        )
      )
    }
  }, [user, userLoading, dispatch, websiteName])

  return websiteLoading || retreatLoading || !retreat || userLoading ? (
    <LoadingPage />
  ) : !website ? (
    <NotFound404Page />
  ) : (
    <PageContainer>
      <PageBody>
        <AttendeeSite
          retreat={retreat}
          website={website}
          hideLogo
          activePage="flights"
          pageBody={
            <>
              <Box clone mb={2}>
                <Tabs
                  value={tabValue}
                  onChange={(e, newVal) =>
                    setTabQuery(newVal === "instructions" ? null : newVal)
                  }
                  variant="fullWidth"
                  indicatorColor="primary">
                  <Tab value="instructions" label="Flight Instructions" />
                  {/* Newsela Hack */}
                  {retreat.id !== 3817 && (
                    <Tab value="my-flight" label="My Flight" />
                  )}
                  {!retreat.hide_group_flights && (
                    <Tab value="group" label="Group Flights" />
                  )}
                </Tabs>
              </Box>
              <Box clone width="100%">
                <AppTabPanel show={tabValue === "my-flight"}>
                  {attendee ? (
                    <AttendeeFlightTab
                      attendee={attendee ?? SampleLockedAttendees[0]}
                      hideHeader
                      receiptRestricted={retreat.require_flight_receipts}
                    />
                  ) : (
                    "Oops, looks like you are not an attendee on this retreat"
                  )}
                </AppTabPanel>
              </Box>
              {!retreat.hide_group_flights && (
                <Box clone width="100%" height="100%" minHeight={300}>
                  <AppTabPanel show={tabValue === "group"}>
                    <AttendeeFlightsDataGrid retreatId={retreat.id} />
                  </AppTabPanel>
                </Box>
              )}
              <Box clone width="100%">
                <AppTabPanel show={tabValue === "instructions"}>
                  {pageLoading ? (
                    <AppLoadingScreen />
                  ) : page?.block_ids ? (
                    page.block_ids.map((blockId) => (
                      <PageBlock blockId={blockId} />
                    ))
                  ) : (
                    <div>
                      Your flight booking instructions will be rendered here
                      once you have created them in the dashboard
                    </div>
                  )}
                </AppTabPanel>
              </Box>
            </>
          }></AttendeeSite>
      </PageBody>
    </PageContainer>
  )
}
