import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField,
  useTheme,
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {convertToRaw, EditorState} from "draft-js"
import {useFormik} from "formik"
import {useRef, useState} from "react"
import {useDispatch} from "react-redux"
import {FileModel} from "../../models/retreat"
import {
  LabelModel,
  TaskPriorityName,
  TaskPriorityValues,
  TaskStatusEnum,
  TaskStatusName,
  TaskStatusValues,
} from "../../models/task"
import {ApiAction} from "../../store/actions/api"
import {
  postAssigneeToTask,
  postFileToTask,
  postLabelToTask,
  postTask,
} from "../../store/actions/task"
import {useUsers} from "../../utils/retreatUtils"
import AppTypography from "../base/AppTypography"
import AppUploadFile from "../base/AppUploadFile"
import {AppWysiwygEditor} from "../base/AppWysiwyg"
import {EventFile, SideLabelInput} from "../itinerary/ItineraryEventForm"
import {LabelFormModal} from "./TaskViewer"

let useStyles = makeStyles((theme) => ({
  dialog: {
    position: "relative",
    top: "10%",
    left: "21%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    minWidth: "600px",
    gap: theme.spacing(1),
    "& .MuiOutlinedInput-root:not(:hover):not(:focus-within) .MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  timesWrapper: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },
  submitButton: {
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  },
  autocomplete: {
    minWidth: 160,
    width: "fit-content",
  },
  singleAutocomplete: {
    minWidth: 160,
    width: "fit-content",
    "& .MuiChip-deleteIcon": {
      display: "none",
    },
  },
}))

type TaskFormModalProps = {
  open: boolean
  sideNav?: boolean
  onClose: () => void
  retreat_id: number
  retreatUsers: number[]
  labels: {
    [id: number]: LabelModel | undefined
  }
}
function TaskFormModal(props: TaskFormModalProps) {
  let {open, retreatUsers, labels, retreat_id} = props
  let [newLabelDialog, setNewLabelDialog] = useState(false)

  let classes = useStyles()
  let theme = useTheme()
  let dispatch = useDispatch()

  let [assigneeOptions, loadingAssignees] = useUsers(retreatUsers)

  let taskFormModalRef = useRef()
  let formik = useFormik({
    initialValues: {
      title: "",
      description: EditorState.createEmpty(),
      due_date: undefined,
      status: TaskStatusEnum.TO_DO,
      priority: undefined,
      assignees: [] as number[],
      files: [] as FileModel[],
      labels: [] as number[],
    },
    onSubmit: async (values) => {
      let formikFiles = [...(values.files ? values.files : [])]
      let formikAssignees = [...(values.assignees ? values.assignees : [])]
      let formikLabels = [...(values.labels ? values.labels : [])]
      let postValues = {
        ...values,
        files: [],
        assignees: [],
        labels: [],
        description: convertToRaw(values.description.getCurrentContent()),
      }
      let response = (await dispatch(
        postTask(postValues, props.retreat_id)
      )) as unknown as ApiAction
      if (!response.error) {
        let filesToPost = formikFiles
        let assigneesToPost = formikAssignees
        let labelsToPost = formikLabels
        await Promise.all(
          filesToPost.map(async (file) => {
            let fileResponse = (await dispatch(
              postFileToTask({
                task_id: response.payload.task.id,
                file_id: file.id,
              })
            )) as unknown as ApiAction
            if (!fileResponse.error) {
              dispatch({
                type: "ADD_FILE_TO_TASK",
                file: file,
                task_id: response.payload.task.id,
              })
            }
          }) &&
            assigneesToPost.map(async (assignee) => {
              await dispatch(
                postAssigneeToTask({
                  task_id: response.payload.task.id,
                  user_id: assignee,
                })
              )
            }) &&
            labelsToPost.map(async (label) => {
              await dispatch(
                postLabelToTask({
                  task_id: response.payload.task.id,
                  label_id: label,
                })
              )
            })
        )
        props.onClose()
      }
    },
  })
  // useEffect(() => {
  //   async function loadAssignees(retreatUsers: number[]) {
  //     setLoadingAssignees(true)
  //     await Promise.all(retreatUsers.map((userId) => dispatch(getUser(userId))))
  //     setLoadingAssignees(false)
  //   }
  //   if (!loadingAssignees) {
  //     let missingAssignees = retreatUsers
  //       .map((userId) => (!assigneeOptions[userId] ? userId : undefined))
  //       .filter((id) => id)
  //     if (missingAssignees.length > 0) {
  //       loadAssignees(missingAssignees as number[])
  //     }
  //   }
  // }, [
  //   retreatUsers,
  //   dispatch,
  //   labels,
  //   loadingAssignees,
  //   labels,
  //   assigneeOptions,
  // ])

  return (
    <Dialog
      open={open}
      ref={taskFormModalRef}
      className={classes.dialog}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"md"}
      fullWidth
      scroll={"paper"}>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            id="title"
            error={!!formik.errors.title}
            helperText={formik.errors.title}
            variant="outlined"
            value={formik.values.title}
            placeholder="Task Title"
            required
            fullWidth
            InputProps={{
              classes: {
                input: classes.title,
              },
            }}
            onChange={formik.handleChange}
          />

          <SideLabelInput
            label="Due Date"
            input={
              <TextField
                id="due_date"
                value={formik.values.due_date}
                type="date"
                variant={"outlined"}
                InputLabelProps={{shrink: true}}
                onChange={formik.handleChange}
              />
            }
          />
          <SideLabelInput
            label="Assignees"
            input={
              <Autocomplete
                autoSelect
                className={classes.autocomplete}
                multiple
                disableClearable
                filterSelectedOptions
                value={formik.values.assignees}
                options={Object.keys(assigneeOptions).map((userId) =>
                  parseInt(userId)
                )}
                getOptionLabel={(option) => {
                  return `${assigneeOptions[option]?.first_name} ${assigneeOptions[option]?.last_name}`
                }}
                id="assignees"
                filterOptions={(x) => x}
                onChange={(e, newVals, changeReason) => {
                  if (changeReason !== "blur") {
                    formik.setFieldValue(
                      "assignees",
                      Array.from(new Set(newVals.sort()))
                    )
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant={"outlined"} id="assignees" />
                )}
              />
            }
          />
          <SideLabelInput
            label="Labels"
            input={
              <Autocomplete
                autoSelect
                className={classes.autocomplete}
                multiple
                disableClearable
                filterSelectedOptions
                value={formik.values.labels}
                options={Object.keys(labels)
                  .map((labelId) => parseInt(labelId))
                  .concat(-1)} // <- flag for creating new label
                getOptionLabel={(option) => {
                  return option !== -1
                    ? `${labels[option]?.text}`
                    : "+ Create new label"
                }}
                id="labels"
                filterOptions={(x) => x}
                onChange={(e, newVals, changeReason, changedOption) => {
                  if (changeReason !== "blur") {
                    if (changedOption?.option === -1) {
                      setNewLabelDialog(true)
                      return
                    }
                    formik.setFieldValue(
                      "labels",
                      Array.from(new Set(newVals.sort()))
                    )
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant={"outlined"}
                    id="labels"
                  />
                )}
              />
            }
          />
          <LabelFormModal
            retreatId={retreat_id}
            open={newLabelDialog}
            onClose={() => setNewLabelDialog(false)}
            onSuccess={(label) => {
              return formik.setFieldValue("labels", [
                ...(formik.values.labels ? formik.values.labels : []),
                label.id,
              ])
            }}
          />
          <SideLabelInput
            label="Status"
            input={
              <Autocomplete
                disableClearable
                size="small"
                autoSelect
                multiple
                className={classes.singleAutocomplete}
                filterSelectedOptions
                value={formik.values.status ? [formik.values.status] : []}
                options={TaskStatusValues}
                getOptionLabel={(option) => {
                  return TaskStatusName[option]
                }}
                id="status"
                filterOptions={(x) => x}
                onChange={(e, newVals, changeReason) => {
                  if (changeReason !== "blur") {
                    let newValue = formik.values.status
                      ? newVals[1]
                      : newVals[0]
                    formik.setFieldValue("status", newValue)
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant={"outlined"} id="status" />
                )}
              />
            }
          />
          <SideLabelInput
            label="Priority"
            input={
              <Autocomplete
                size="small"
                autoSelect
                multiple
                className={classes.singleAutocomplete}
                filterSelectedOptions
                value={formik.values.priority ? [formik.values.priority] : []}
                options={TaskPriorityValues}
                getOptionLabel={(option) => {
                  if (option) {
                    return TaskPriorityName[option]
                  } else return ""
                }}
                id="priority"
                filterOptions={(x) => x}
                onChange={(e, newVals, changeReason) => {
                  if (changeReason !== "blur") {
                    let newValue = formik.values.priority
                      ? newVals[1]
                      : newVals[0]
                    formik.setFieldValue("priority", newValue)
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant={"outlined"} id="priority" />
                )}
              />
            }
          />

          <SideLabelInput
            label="Description"
            input={
              <AppWysiwygEditor
                editorState={formik.values.description}
                onEditorStateChange={(val) => {
                  formik.setFieldValue("description", val)
                }}
              />
            }
            multiline
          />
          <Box
            clone
            style={{
              fontSize: "0.9rem",
              color: theme.palette.grey[600],
              width: "30%",
              marginRight: "auto",
            }}>
            {<AppTypography noWrap>Files</AppTypography>}
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems="center"
            gridGap={8}>
            {formik.values && formik.values && (
              <Box
                overflow={"auto"}
                flex={1}
                display={"flex"}
                flexDirection={"row"}
                gridGap={8}>
                {formik.values.files &&
                  formik.values.files.map((file) => {
                    return (
                      <EventFile
                        url={file.file_url}
                        onDelete={() => {
                          if (formik.values.files) {
                            let files = [...formik.values.files]
                            let index = formik.values.files.findIndex(
                              (arrFile) => {
                                return arrFile.id === file.id
                              }
                            )
                            if (index !== -1) {
                              files.splice(index, 1)
                            }
                            formik.setFieldValue("files", files)
                          }
                        }}
                      />
                    )
                  })}
              </Box>
            )}
          </Box>
          <Box>
            <AppUploadFile
              alt
              accepts="image/png, image/jpg, application/pdf, image/jpeg"
              rightText={""}
              id="file"
              handleChange={async (file) => {
                formik.setFieldValue("files", [
                  ...(formik.values.files ? formik.values.files : []),
                  file,
                ])
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            className={classes.submitButton}
            type="submit"
            variant="contained">
            Create Task
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default TaskFormModal
