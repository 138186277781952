import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {RootState} from "../store"
import {getUserHome} from "../store/actions/user"

export function useLoggedInUser() {
  let dispatch = useDispatch()
  let user = useSelector((state: RootState) => state.user.user)
  let [loading, setLoading] = useState(!user)
  useEffect(() => {
    async function loadUser() {
      setLoading(true)
      await dispatch(getUserHome())
      setLoading(false)
    }
    if (!user) {
      loadUser()
    }
  }, [dispatch, user])
  return [user, loading] as const
}
