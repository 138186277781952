import {
  Button,
  ButtonProps,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import {Menu} from "@material-ui/icons"
import clsx from "clsx"
import {push} from "connected-react-router"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {Link as ReactRouterLink} from "react-router-dom"
import {ImageModel} from "../../../models"
import {
  AttendeeLandingWebsiteModel,
  RetreatModel,
} from "../../../models/retreat"
import {UserModel} from "../../../models/user"
import {AppRoutes} from "../../../Stack"
import {FlokTheme} from "../../../theme"
import {titleToNavigation} from "../../../utils"
import {ImageUtils} from "../../../utils/imageUtils"
import {useAttendeeLandingPages} from "../../../utils/retreatUtils"
import {useLoggedInUser} from "../../../utils/userUtils"
import AppUserSettings from "../../base/AppUserSettings"

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(3),
    width: "100%",
    "& > *": {
      width: "33%",
      display: "flex",
    },
    "& > *:first-child": {
      justifyContent: "flex-start",
    },
    "& > *:last-child": {
      justifyContent: "flex-end",
    },
  },
  logo: {
    height: 50,
  },
  navContainer: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}))

type PageNavItem = {to: string; title: string; active: boolean}
const AUTHED_PAGES = ["registration", "flights", "itinerary"]

type SiteHeaderProps = {
  retreat: RetreatModel
  website: AttendeeLandingWebsiteModel
  activePage: string | "registration" | "flights" | "itinerary"
  adminView?: boolean
}

function SiteHeader(props: SiteHeaderProps) {
  let dispatch = useDispatch()
  let classes = useStyles()
  let [user] = useLoggedInUser()
  let [pages] = useAttendeeLandingPages(props.website.page_ids)

  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )

  let activePage = titleToNavigation(props.activePage)
  let websiteName = titleToNavigation(props.website.name)
  let registrationLive = props.retreat.registration_live || props.adminView
  let itineraryLive = props.retreat.show_itinerary_on_landing || props.adminView
  /* Newsela Hack */
  let flightsLive =
    props.retreat.flights_live || props.adminView || props.retreat.id === 3817
  let homeLink = AppRoutes.getPath(
    "AttendeeSiteHome",
    {
      websiteName: websiteName,
    },
    props.adminView ? {view: "admin"} : undefined
  )
  let registerLink = registrationLive
    ? AppRoutes.getPath(
        "AttendeeSiteFormPage",
        {websiteName},

        props.adminView ? {view: "admin"} : undefined
      )
    : undefined
  let navItems: PageNavItem[] = pages.map((page) => ({
    to: AppRoutes.getPath(
      "AttendeeSitePage",
      {
        websiteName,
        pageName: titleToNavigation(page.title),
      },

      props.adminView ? {view: "admin"} : undefined
    ),
    title: page.title,
    active: titleToNavigation(page.title) === activePage,
  }))
  if (flightsLive) {
    navItems.push({
      to: AppRoutes.getPath(
        "AttendeeSiteFlightsPage",
        {websiteName},
        props.adminView ? {view: "admin"} : undefined
      ),
      title: "Flights",
      active: activePage === "flights",
    })
  }
  if (itineraryLive) {
    navItems.push({
      to: AppRoutes.getPath(
        "AttendeeSiteItineraryPage",
        {websiteName},
        props.adminView ? {view: "admin"} : undefined
      ),
      title: "Itinerary",
      active: activePage === "itinerary",
    })
  }
  let logoImage: Pick<ImageModel, "image_url" | "alt"> = props.website
    .logo_image
    ? props.website.logo_image
    : {alt: "Flok logo", image_url: ImageUtils.getImageUrl("logoIconTextTrans")}

  return isSmallScreen ? (
    <MobileSiteHeader
      homeLink={homeLink}
      registerLink={registerLink}
      navItems={navItems}
      logo={logoImage}
      user={user}
    />
  ) : (
    <div className={classes.root}>
      <Link to={homeLink} component={ReactRouterLink}>
        <img
          src={logoImage.image_url}
          className={classes.logo}
          alt={logoImage.alt}
        />
      </Link>
      <div className={classes.navContainer}>
        {navItems.map((navItem) => (
          <Link
            underline={navItem.active ? "always" : "none"}
            to={navItem.to}
            key={navItem.title}
            color="inherit"
            component={ReactRouterLink}>
            {navItem.title}
          </Link>
        ))}
      </div>
      <div>
        {AUTHED_PAGES.includes(props.activePage) ? (
          user ? (
            <AppUserSettings
              collapsable
              user={user}
              onLogoutSuccess={() => dispatch(push(homeLink))}
            />
          ) : (
            <div>&nbsp;</div>
          )
        ) : (
          <RegisterNowButton to={registerLink} />
        )}
      </div>
    </div>
  )
}
export default SiteHeader

let useMobileStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(3),
    width: "100%",
    "& > *": {
      display: "flex",
    },
    "& > *:first-child": {
      justifyContent: "flex-start",
    },
    "& > *:last-child": {
      justifyContent: "flex-end",
    },
  },
  logo: {
    width: 120,
  },
  drawer: {
    width: "50%",
    minWidth: 300,
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    "& > *:first-child": {
      // Logo container (because className doesn't work on Link element)
      display: "flex",
      justifyContent: "center",
    },
  },
  registerButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  userSettings: {
    display: "flex",
    justifyContent: "center",
    marginTop: "auto",
  },
}))

type MobileSiteHeaderProps = {
  navItems: PageNavItem[]
  homeLink: string
  registerLink?: string
  logo: Pick<ImageModel, "alt" | "image_url">
  user?: UserModel
}
function MobileSiteHeader(props: MobileSiteHeaderProps) {
  let classes = useMobileStyles()
  let dispatch = useDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false)
  return (
    <>
      <div className={classes.header}>
        <Link to={props.homeLink} component={ReactRouterLink}>
          <img
            src={props.logo.image_url}
            className={classes.logo}
            alt={props.logo.alt}
          />
        </Link>
        <IconButton onClick={() => setDrawerOpen(true)}>
          <Menu />
        </IconButton>
      </div>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
        }}
        classes={{paper: classes.drawer}}>
        <Link to={props.homeLink} component={ReactRouterLink}>
          <img
            src={props.logo.image_url}
            className={classes.logo}
            alt={props.logo.alt}
          />
        </Link>
        <List>
          {props.navItems.map((navItem) => (
            <Link
              component={ReactRouterLink}
              key={navItem.title}
              to={navItem.to}
              underline={navItem.active ? "always" : "none"}
              color="inherit">
              <ListItem button>
                <Typography variant="h3">{navItem.title}</Typography>
              </ListItem>
            </Link>
          ))}
          <div className={classes.registerButton}>
            <RegisterNowButton to={props.registerLink} />
          </div>
        </List>
        {props.user && (
          <div className={classes.userSettings}>
            <AppUserSettings
              user={props.user}
              onLogoutSuccess={() => dispatch(push(props.homeLink))}
            />
          </div>
        )}
      </Drawer>
    </>
  )
}

let useBannerStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer,
    position: "absolute",
    width: "100%",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  placeholder: {
    position: "relative",
    display: "block",
  },
}))

type AdminSiteBannerProps = {
  adminView: boolean
  setAdminView: (val: boolean) => void
}

export function AdminSiteBanner(props: AdminSiteBannerProps) {
  let classes = useBannerStyles()
  return (
    <>
      <header className={clsx(classes.root, classes.placeholder)}>
        &nbsp;
      </header>
      <header className={classes.root}>
        <Typography color="inherit" variant="body2">
          Viewing as an {props.adminView ? "admin" : "attendee"} switch to{" "}
          <Link
            color="inherit"
            component="button"
            underline="always"
            onClick={() => props.setAdminView(!props.adminView)}>
            {props.adminView ? "attendee" : "admin"}
          </Link>
          ?
        </Typography>
      </header>
    </>
  )
}

function RegisterNowButton(props: {to?: string}) {
  const buttonProps: Pick<ButtonProps, "color" | "variant" | "size"> = {
    color: "primary",
    variant: "contained",
    size: "small",
  }
  const registerText = "Register Now"
  return props.to ? (
    <Button {...buttonProps} component={ReactRouterLink} to={props.to}>
      {registerText}
    </Button>
  ) : (
    <Tooltip title="Registration isn't live yet">
      <span>
        <Button {...buttonProps} disabled>
          {registerText}
        </Button>
      </span>
    </Tooltip>
  )
}
