import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Constants} from "../config"
import {
  AttendeeLandingWebsitePageModel,
  RetreatModel,
  RetreatToTask,
} from "../models/retreat"
import {RootState} from "../store"
import {
  getAttendee,
  getBlock,
  getComment,
  getCommentThread,
  getItinerary,
  getItineraryEvent,
  getMyAttendee,
  getNotifications,
  getPage,
  getPageByName,
  getRetreat,
  getRetreatAttendees,
  getRetreatByGuid,
  getUser,
  getWebsite,
  getWebsiteByName,
} from "../store/actions/retreat"
import {getLabel, getTask, getTaskTemplateGroups} from "../store/actions/task"

export function useRetreatAttendees(retreatId: number) {
  let dispatch = useDispatch()
  let attendeesList = useSelector(
    (state: RootState) => state.retreat.retreatAttendees[retreatId]
  )
  let attendeesListLoaded = !!attendeesList?.length
  let attendeesObject = useSelector((state: RootState) => {
    return state.retreat.attendees
  })
  let attendees = (attendeesList ? attendeesList : []).map(
    (id) => attendeesObject[id]
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadAttendees() {
      setLoading(true)
      await dispatch(getRetreatAttendees(retreatId))
      setLoading(false)
    }
    if (!attendeesListLoaded) {
      loadAttendees()
    }
  }, [attendeesListLoaded, dispatch, retreatId])
  return [attendees, loading] as const
}

/**
 * Deprecated
 * @param retreatGuid
 * @returns
 */
export function useRetreatByGuid(retreatGuid: string) {
  let dispatch = useDispatch()
  let [loading, setLoading] = useState(false)
  let retreat = useSelector(
    (state: RootState) => state.retreat.retreatsByGuid[retreatGuid]
  )
  useEffect(() => {
    async function loadRetreat() {
      setLoading(true)
      dispatch(getRetreatByGuid(retreatGuid))
      setLoading(false)
    }
    if (!retreat) {
      loadRetreat()
    }
  }, [retreat, dispatch, retreatGuid])
  return [retreat, loading] as const
}

export function parseRetreatTask(task: RetreatToTask, baseUrl: string) {
  let parsedTask = {...task}
  if (task.link) {
    parsedTask.link = task.link.replaceAll(Constants.retreatBaseUrlVar, baseUrl)
  }
  if (task.description) {
    parsedTask.description = task.description.replaceAll(
      Constants.retreatBaseUrlVar,
      baseUrl
    )
  }
  return parsedTask
}

export function useAttendeeLandingWebsite(websiteId: number) {
  let website = useSelector((state: RootState) => {
    return state.retreat.websites[websiteId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    if (!website) {
      dispatch(getWebsite(websiteId))
    }
  }, [website, dispatch, websiteId])

  return website
}

export function useAttendeeLandingPage(pageId: number) {
  let dispatch = useDispatch()
  let page = useSelector((state: RootState) => {
    return state.retreat.pages[pageId]
  })
  let [loading, setLoading] = useState(!page)
  useEffect(() => {
    async function loadPage() {
      setLoading(true)
      await dispatch(getPage(pageId))
      setLoading(false)
    }
    if (!page) {
      loadPage()
    }
  }, [page, dispatch, pageId])

  return [page, loading] as const
}

export function useAttendeeLandingPages(pageIds: number[]) {
  let dispatch = useDispatch()
  let pages = useSelector((state: RootState) =>
    pageIds.reduce<{[id: number]: AttendeeLandingWebsitePageModel | undefined}>(
      (prev, id) => ({...prev, [id]: state.retreat.pages[id]}),
      {}
    )
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadPages(pageIds: number[]) {
      setLoading(true)
      await Promise.all(pageIds.map((id) => dispatch(getPage(id))))
      setLoading(false)
    }
    if (!loading) {
      let missingPageIds = Object.keys(pages)
        .filter((id) => !pages[parseInt(id)])
        .map((id) => parseInt(id))
      if (missingPageIds.length) {
        loadPages(missingPageIds)
      }
    }
  }, [dispatch, pages, loading])
  let pageList: AttendeeLandingWebsitePageModel[] = pageIds
    .map((id) => pages[id])
    .filter((page) => page) as AttendeeLandingWebsitePageModel[]

  return [pageList, loading] as const
}

export function useAttendeeLandingPageBlock(blockId: number) {
  let dispatch = useDispatch()
  let block = useSelector((state: RootState) => {
    return state.retreat.blocks[blockId]
  })
  let [loading, setLoading] = useState(!block)
  useEffect(() => {
    async function loadBlock() {
      setLoading(true)
      await dispatch(getBlock(blockId))
      setLoading(false)
    }
    if (!block) {
      loadBlock()
    }
  }, [block, dispatch, blockId])

  return [block, loading] as const
}

export function useAttendeeLandingPageName(
  websiteId: number,
  pageName: string
) {
  let dispatch = useDispatch()
  let [loading, setLoading] = useState(true)
  let page = useSelector((state: RootState) => {
    return Object.values(state.retreat.pages).find(
      (page) => page?.title.toLowerCase() === pageName.toLowerCase()
    )
  })

  useEffect(() => {
    async function loadPage() {
      setLoading(true)
      await dispatch(getPageByName(websiteId, pageName))
      setLoading(false)
    }
    if (!page) {
      loadPage()
    } else {
      setLoading(false)
    }
  }, [page, dispatch, pageName, websiteId])

  return [page, loading] as const
}

export function useAttendeeLandingWebsiteName(websiteName: string) {
  let [loading, setLoading] = useState(true)
  let website = useSelector((state: RootState) => {
    return Object.values(state.retreat.websites).find(
      (website) => website?.name.toLowerCase() === websiteName.toLowerCase()
    )
  })
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadWebsite() {
      setLoading(true)
      await dispatch(getWebsiteByName(websiteName))
      setLoading(false)
    }
    if (!website) {
      loadWebsite()
    } else {
      setLoading(false)
    }
  }, [website, dispatch, websiteName])

  return [website, loading] as const
}

export function useMyAttendee(retreatId: number) {
  let dispatch = useDispatch()
  let attendeeId = useSelector((state: RootState) => {
    return state.user.myAttendeeByRetreat[retreatId]
  })
  useEffect(() => {
    if (attendeeId == null) {
      dispatch(getMyAttendee(retreatId))
    }
  }, [retreatId, dispatch, attendeeId])

  let attendee = useSelector((state: RootState) => {
    if (attendeeId != null) return state.retreat.attendees[attendeeId]
  })
  useEffect(() => {
    if (attendeeId != null && attendee == null) {
      dispatch(getAttendee(attendeeId))
    }
  }, [attendeeId, attendee, dispatch])

  return [attendee]
}

export function useRetreat(retreatId: number) {
  let [loading, setLoading] = useState(true)
  let retreat = useSelector((state: RootState) => {
    return state.retreat.retreats[retreatId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadRetreat() {
      setLoading(true)
      await dispatch(getRetreat(retreatId))
      setLoading(false)
    }
    if (!retreat) {
      loadRetreat()
    } else {
      setLoading(false)
    }
  }, [retreat, dispatch, retreatId])

  return [retreat, loading] as const
}

export function getRetreatName(retreat: RetreatModel) {
  if (retreat.retreat_name != null) {
    return retreat.retreat_name
  } else return `${retreat.company_name}'s Retreat`
}

export function useItinerary(itineraryId: number) {
  let [loading, setLoading] = useState(false)
  let itinerary = useSelector((state: RootState) => {
    return state.retreat.itineraries[itineraryId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadItinerary() {
      if (itineraryId !== -1) {
        setLoading(true)
        await dispatch(getItinerary(itineraryId))
        setLoading(false)
      }
    }
    if (!itinerary) {
      loadItinerary()
    }
  }, [dispatch, itinerary, itineraryId])
  return [itinerary, loading] as const
}

export function useItineraryEvents(eventIds: number[]) {
  let events = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.itineraryEvents).filter(
      ([key, event]) => event && eventIds.indexOf(event.id) !== -1
    )
    return Object.fromEntries(entries)
  })
  let [loadingEvents, setLoadingEvents] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadEvents(eventIds: number[]) {
      setLoadingEvents(true)
      await Promise.all(eventIds.map((id) => dispatch(getItineraryEvent(id))))
      setLoadingEvents(false)
    }
    if (!loadingEvents) {
      let missingEvents = eventIds
        .map((id) => (!events[id] ? id : undefined))
        .filter((id) => id)
      if (missingEvents.length > 0) {
        loadEvents(missingEvents as number[])
      }
    }
  }, [dispatch, eventIds, events, loadingEvents])
  return [events, loadingEvents] as const
}

export function useItineraryEvent(eventId: number) {
  let [loading, setLoading] = useState(false)
  let event = useSelector((state: RootState) => {
    return state.retreat.itineraryEvents[eventId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadEvent() {
      setLoading(true)
      await dispatch(getItineraryEvent(eventId))
      setLoading(false)
    }
    if (!event) {
      loadEvent()
    }
  }, [dispatch, event, eventId])
  return [event, loading] as const
}

export function useCommentThread(commentThreadId: number) {
  let [threadLoading, setThreadLoading] = useState(false)
  let thread = useSelector((state: RootState) => {
    return state.retreat.commentThreads[commentThreadId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    if (!thread) {
      loadThread()
    }
    async function loadThread() {
      setThreadLoading(true)
      await dispatch(getCommentThread(commentThreadId))
      setThreadLoading(false)
    }
  }, [thread, dispatch, commentThreadId])

  return [thread, threadLoading] as const
}

export function useComment(commentId: number) {
  let [commentLoading, setCommentLoading] = useState(false)
  let comment = useSelector((state: RootState) => {
    return state.retreat.comments[commentId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    if (!comment) {
      loadComment()
    }
    async function loadComment() {
      setCommentLoading(true)
      await dispatch(getComment(commentId))
      setCommentLoading(false)
    }
  }, [comment, dispatch, commentId])

  return [comment, commentLoading] as const
}

export function useUser(userId: number) {
  let [userLoading, setUserLoading] = useState(false)
  let user = useSelector((state: RootState) => {
    return state.retreat.users[userId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    if (!user) {
      loadUser()
    }
    async function loadUser() {
      setUserLoading(true)
      await dispatch(getUser(userId))
      setUserLoading(false)
    }
  }, [user, dispatch, userId])

  return [user, userLoading] as const
}

export function useUsers(userIds: number[]) {
  let users = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.users).filter(
      ([key, user]) => userIds.indexOf(user?.id as number) !== -1
    )
    return Object.fromEntries(entries)
  })
  let [loadingUsers, setLoadingUsers] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadUsers(userIds: number[]) {
      setLoadingUsers(true)
      await Promise.all(userIds.map((id) => dispatch(getUser(id))))
      setLoadingUsers(false)
    }
    if (!loadingUsers) {
      let missingUsers = userIds
        .map((id) => (!users[id] ? id : undefined))
        .filter((id) => id)
      if (missingUsers.length > 0) {
        loadUsers(missingUsers as number[])
      }
    }
  }, [dispatch, userIds, users, loadingUsers])
  return [users, loadingUsers] as const
}

export function useTasks(taskIds: number[]) {
  let tasks = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.tasks).filter(
      ([key, task]) => taskIds.indexOf(task?.id as number) !== -1
    )
    return Object.fromEntries(entries)
  })
  let [loadingTasks, setLoadingTasks] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadTasks(taskIds: number[]) {
      setLoadingTasks(true)
      await Promise.all(taskIds.map((id) => dispatch(getTask(id))))
      setLoadingTasks(false)
    }
    if (!loadingTasks) {
      let missingTasks = taskIds
        .map((id) => (!tasks[id] ? id : undefined))
        .filter((id) => id)
      if (missingTasks.length > 0) {
        loadTasks(missingTasks as number[])
      }
    }
  }, [dispatch, taskIds, tasks, loadingTasks])
  return [tasks, loadingTasks] as const
}

export function useCommentThreads(threadIds: number[]) {
  let threads = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.commentThreads).filter(
      ([key, thread]) => threadIds.indexOf(thread?.id as number) !== -1
    )
    return Object.fromEntries(entries)
  })
  let [loadingThreads, setLoadingThreads] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadThreads(threadIds: number[]) {
      setLoadingThreads(true)
      await Promise.all(threadIds.map((id) => dispatch(getCommentThread(id))))
      setLoadingThreads(false)
    }
    if (!loadingThreads) {
      let missingThreads = threadIds
        .map((id) => (!threads[id] ? id : undefined))
        .filter((id) => id)
      if (missingThreads.length > 0) {
        loadThreads(missingThreads as number[])
      }
    }
  }, [dispatch, threadIds, threads, loadingThreads])
  return [threads, loadingThreads] as const
}

export function useNotifications(retreatId: number, offset?: number) {
  let notifications = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.notifications).filter(
      ([key, notification]) =>
        notification && notification.retreat_id === retreatId
    )
    return Object.fromEntries(entries)
  })
  let [loadingNotifications, setLoadingNotifications] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadNotifications(retreatId: number) {
      setLoadingNotifications(true)
      await dispatch(getNotifications(retreatId, offset))
      setLoadingNotifications(false)
    }
    loadNotifications(retreatId)
  }, [dispatch, retreatId, offset])
  return [notifications, loadingNotifications] as const
}

export function useLabel(labelId: number) {
  let dispatch = useDispatch()
  let [loading, setLoading] = useState(false)
  let label = useSelector((state: RootState) => state.retreat.labels[labelId])
  useEffect(() => {
    async function loadLabel() {
      setLoading(true)
      dispatch(getLabel(labelId))
      setLoading(false)
    }
    if (!label) {
      loadLabel()
    }
  }, [label, dispatch, labelId])
  return [label, loading] as const
}

export function useLabels(labelIds: number[]) {
  let labels = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.labels).filter(
      ([key, label]) => labelIds.indexOf(label?.id as number) !== -1
    )
    return Object.fromEntries(entries)
  })
  let [loadingLabels, setLoadingLabels] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadLabels(threadIds: number[]) {
      setLoadingLabels(true)
      await Promise.all(labelIds.map((id) => dispatch(getLabel(id))))
      setLoadingLabels(false)
    }
    if (!loadingLabels) {
      let missingLabels = labelIds
        .map((id) => (!labels[id] ? id : undefined))
        .filter((id) => id)
      if (missingLabels.length > 0) {
        loadLabels(missingLabels as number[])
      }
    }
  }, [dispatch, labelIds, labels, loadingLabels])
  return [labels, loadingLabels] as const
}

export function useTaskTemplateGroups() {
  let taskTemplateGroups = useSelector((state: RootState) => {
    return state.task.taskTemplateGroups
  })
  let [loadingTaskTemplateGroups, setLoadingTaskTemplateGroups] =
    useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadTaskTemplateGroups() {
      setLoadingTaskTemplateGroups(true)
      await dispatch(getTaskTemplateGroups())
      setLoadingTaskTemplateGroups(false)
    }
    loadTaskTemplateGroups()
  }, [dispatch])

  return [taskTemplateGroups, loadingTaskTemplateGroups] as const
}
