import {Box} from "@material-ui/core"
import {Alert} from "@material-ui/lab"
import {push} from "connected-react-router"
import {useEffect} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import AttendeeSite from "../../components/attendee-site/website/AttendeeSite"
import FormProvider from "../../components/forms/FormProvider"
import FormResponseViewer from "../../components/forms/FormResponseViewer"
import FormViewer from "../../components/forms/FormViewer"
import PageBody from "../../components/page/PageBody"
import PageContainer from "../../components/page/PageContainer"
import {enqueueSnackbar} from "../../notistack-lib/actions"
import {AppRoutes} from "../../Stack"
import {ApiAction} from "../../store/actions/api"
import {postAttendeeRegRequest} from "../../store/actions/retreat"
import {replaceDashes} from "../../utils"
import {
  useAttendeeLandingWebsiteName,
  useMyAttendee,
  useRetreat,
} from "../../utils/retreatUtils"
import {useLoggedInUser} from "../../utils/userUtils"
import LoadingPage from "../misc/LoadingPage"
import NotFound404Page from "../misc/NotFound404Page"

export default function AttendeeSiteRegistrationPage() {
  let dispatch = useDispatch()
  let router = useRouteMatch<{websiteName: string; pageName: string}>()
  let {websiteName} = router.params
  let [website, websiteLoading] = useAttendeeLandingWebsiteName(
    replaceDashes(websiteName)
  )
  let [retreat, retreatLoading] = useRetreat(website?.retreat_id ?? -1)
  let [user, userLoading] = useLoggedInUser()
  let [attendee] = useMyAttendee(retreat != null ? retreat.id : -1)

  useEffect(() => {
    if (!userLoading && !user) {
      dispatch(
        push(
          AppRoutes.getPath(
            "SigninPage",
            {
              websiteName: websiteName,
            },
            {
              next: encodeURIComponent(
                AppRoutes.getPath("AttendeeSiteFormPage", {
                  websiteName: websiteName,
                })
              ),
              "login-type": "attendee",
            }
          )
        )
      )
    }
  }, [user, userLoading, dispatch, websiteName])

  return websiteLoading || retreatLoading || !retreat ? (
    <LoadingPage />
  ) : !website ||
    (!retreat.registration_live && !retreat.users.includes(user?.id ?? -1)) ? (
    <NotFound404Page />
  ) : (
    <PageContainer>
      <PageBody>
        <AttendeeSite
          hideLogo
          activePage="registration"
          retreat={retreat}
          website={website}
          pageBody={
            retreat.attendees_registration_form_id != null ? (
              attendee && attendee.registration_form_response_id ? (
                <>
                  {/* Customizable hack for Cro Metrics. Don't repeat this */}
                  <Box clone my={1}>
                    {attendee.retreat_id === 1791 ? (
                      <Alert color="success">
                        Thank you {attendee.first_name} for registering for the
                        2022 Cro Metrics All Hands! We can't wait to see you in
                        Douglasville, Georgia.
                        <br />
                        <br />
                        If you indicated that you are flying, someone from the
                        Planning Team will be reaching out to help book your
                        flight. Please have your travel information handy such
                        as frequent flyer or known traveler account numbers.
                        <br />
                        <br />
                        Be sure to join the #flok-crometrics-all channel for
                        more updates and to get hyped up about the event.
                        <br />
                        <br />
                        Get ready for Cro Metrics, IRL.
                      </Alert>
                    ) : (
                      <Alert color="success">
                        Congrats! You've successfully registered for this event.
                        <br />
                        <br />
                        Check out your registration submission below. Please
                        reach out to the Flok team if you need to update any of
                        the following responses.
                      </Alert>
                    )}
                  </Box>
                  <FormResponseViewer
                    formResponseId={attendee.registration_form_response_id}
                  />
                </>
              ) : (
                <FormProvider formId={retreat.attendees_registration_form_id}>
                  <FormViewer
                    onSuccess={async (formResponse) => {
                      if (attendee) {
                        let response = (await dispatch(
                          postAttendeeRegRequest(attendee?.id, formResponse.id)
                        )) as unknown as ApiAction
                        if (!response.error) {
                          dispatch(
                            enqueueSnackbar({
                              message:
                                "Successfully registered for the retreat",
                              options: {
                                variant: "success",
                              },
                            })
                          )
                        } else {
                          dispatch(
                            enqueueSnackbar({
                              message: "Something Went Wrong",
                              options: {
                                variant: "error",
                              },
                            })
                          )
                        }
                        return response
                      } else {
                        dispatch(
                          enqueueSnackbar({
                            message:
                              "Oops, you haven't been added as an attendee for this retreat.",
                            options: {
                              variant: "error",
                            },
                          })
                        )
                      }
                    }}
                  />
                </FormProvider>
              )
            ) : (
              <div>Attendee registration isn't live yet</div>
            )
          }
        />
      </PageBody>
    </PageContainer>
  )
}
