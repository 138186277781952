import {Box, Chip, Paper, Popover, useTheme} from "@material-ui/core"
import {ReactElement, useState} from "react"
import {CalendarDayEventModel} from "../../models/retreat"
import {formatTime} from "../../pages/dashboard/ItineraryBuilderPage"
import {useItineraryEvent} from "../../utils/retreatUtils"
import AppTypography from "../base/AppTypography"
import {AppWysiwygViewer} from "../base/AppWysiwyg"
import {EventFile, SideLabelInput} from "./ItineraryEventForm"

// just for now will replace later
function amPM(hour: number) {
  if (hour === 0) {
    return "12:00 AM"
  } else if (hour < 12) {
    return `${hour}:00 AM`
  } else if (hour === 12) {
    return "12:00 PM"
  } else {
    return `${hour % 12}:00 PM`
  }
}
export default function CalendarDay(props: {
  events: CalendarDayEventModel[]
  title?: string
  hideGrid?: boolean
  width?: number
  viewer?: boolean
  startHour: number
}) {
  let width = props.width ?? 500

  function getTopMargin(time: string, beginning: number) {
    let [hour, minute] = time.split(":")
    let timeNumber = parseInt(hour) + parseInt(minute) / 60
    return `${(timeNumber - beginning) * 60}px`
  }
  function getHeight(start: string, end: string, beginning: number) {
    let [startHour, startMinute] = start.split(":")
    let [endHour, endMinute] = end.split(":")
    let timeNumber =
      parseInt(endHour) -
      parseInt(startHour) +
      (parseInt(endMinute) - parseInt(startMinute)) / 60
    return `${timeNumber * 60}px`
  }

  function differenceInHours(dateString1: string, dateString2: string) {
    // must pass in dates in string format
    let date1 = new Date(`December 14, 2026 ${dateString1}`)
    let date2 = new Date(`December 14, 2026 ${dateString2}`)
    let difference = date1.getTime() - date2.getTime()
    let differenceInHours = Math.abs(difference / (1000 * 3600))
    return differenceInHours
  }

  function isOverlapping(events: CalendarDayEventModel[]) {
    let eventMap: any[] = []
    events = events.sort((a, b) => {
      return a.start_time > b.start_time ? 1 : -1
    })
    events.forEach((event, i) => {
      let previousEvent = eventMap[i - 1]
      if (previousEvent) {
        let lengthOfPreviousEvent = differenceInHours(
          previousEvent.start_time,
          previousEvent.end_time
        )
        let timeBetweenStarts = differenceInHours(
          previousEvent.start_time,
          event.start_time
        )
        if (lengthOfPreviousEvent > timeBetweenStarts) {
          eventMap.push({...event, overlap: previousEvent.overlap + 1})
        } else {
          eventMap.push({...event, overlap: 0})
        }
      } else {
        eventMap.push({...event, overlap: 0})
      }
    })
    return eventMap
  }
  return (
    <Box display={"flex"} flexDirection="column">
      {props.title && (
        <Box
          height={"20px"}
          marginLeft={"auto"}
          marginRight={"auto"}
          marginTop={2}
          marginBottom={2}>
          <AppTypography fontWeight="bold">{props.title}</AppTypography>
        </Box>
      )}
      <Box width={width} display={"flex"} gridGap={0}>
        <Box position={"relative"}>
          <Box
            height={"100%"}
            position={"relative"}
            top={0}
            bottom={0}
            left={0}
            right={0}>
            {isOverlapping(props.events)
              .filter((e) => e?.start_time)
              .map((event) => {
                return props.viewer ? (
                  <ItineraryEventWithPopper
                    key={event.id}
                    eventId={event.id}
                    eventItem={
                      <CalendarEvent
                        onClick={() => {}}
                        oneLine={
                          differenceInHours(event.start_time, event.end_time) <
                          1
                        }
                        label={event.label}
                        elevation={
                          event.overlap % 2 === 0 ? 1 : event.overlap + 1
                        }
                        location={event.location}
                        start={event.start_time}
                        end={event.end_time}
                        width={
                          width - 8 - ((event.overlap % 2) * (width / 5) + 8)
                        }
                        marginLeft={(event.overlap % 2) * (width / 5) + 8}
                        height={getHeight(
                          event.start_time,
                          event.end_time,
                          props.startHour
                        )}
                        title={event.title}
                        top={getTopMargin(event.start_time, props.startHour)}
                      />
                    }
                  />
                ) : (
                  <CalendarEvent
                    oneLine={
                      differenceInHours(event.start_time, event.end_time) < 1
                    }
                    label={event.label}
                    elevation={event.overlap % 2 === 0 ? 1 : event.overlap + 1}
                    onClick={event.onClick}
                    location={event.location}
                    start={event.start_time}
                    end={event.end_time}
                    width={width - 8 - ((event.overlap % 2) * (width / 5) + 8)}
                    marginLeft={(event.overlap % 2) * (width / 5) + 8}
                    height={getHeight(
                      event.start_time,
                      event.end_time,
                      props.startHour
                    )}
                    title={event.title}
                    top={getTopMargin(event.start_time, props.startHour)}
                  />
                )
              })}
            {!props.hideGrid &&
              [...Array((24 - props.startHour) * 2)].map((u, i) => {
                return (
                  <Box
                    key={i}
                    borderBottom={
                      i % 2 === 0 ? "0px solid black" : "0.5px solid #ECECEC"
                    }
                    borderTop={
                      i === 0 ? "1px solid #ECECEC" : "0.5px solid #ECECEC"
                    }
                    padding={0}
                    top={`${30 * i}px`}
                    margin={0}
                    border={"0.5px solid #ECECEC"}
                    height={`${30}px`}
                    width={width}></Box>
                )
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function CalendarEvent(props: {
  height: string
  title: string
  top: string
  start: string
  end: string
  location?: string
  marginLeft?: number
  onClick?: () => void
  elevation: number
  label?: string
  oneLine: boolean
  width: number
}) {
  let theme = useTheme()
  return (
    <Box
      clone
      onClick={props.onClick}
      height={props.height}
      bgcolor={"primary.light"}
      color={theme.palette.getContrastText(theme.palette.primary.light)}
      position="absolute"
      border={"0px solid black"}
      top={props.top}
      marginLeft={`${props.marginLeft}px`}
      padding={1}
      overflow={"hidden"}
      style={{cursor: props.onClick ? "pointer" : "auto"}}
      width={props.width}>
      <Paper elevation={props.elevation}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box
            display={"flex"}
            flexDirection={props.oneLine ? "row" : "column"}
            gridGap={props.oneLine ? 8 : 0}>
            {formatTime(props.start)} - {formatTime(props.end)}
            <br />
            <AppTypography fontWeight="bold">{props.title}</AppTypography>
          </Box>
          {props.label && !props.oneLine && (
            <Chip
              label={
                props.label[0].toUpperCase() +
                props.label.slice(1).toLowerCase()
              }
            />
          )}
        </Box>
      </Paper>
    </Box>
  )
}

function ItineraryEventViewer(props: {eventId: number}) {
  let [event] = useItineraryEvent(props.eventId)
  return (
    <Box
      clone
      padding={2}
      minWidth={350}
      display="flex"
      flexDirection="column"
      gridGap={8}>
      <Paper>
        <AppTypography fontWeight="bold">{event?.title}</AppTypography>
        <SideLabelInput
          viewer
          label="Time"
          input={
            <AppTypography noWrap>
              {event && event.start_time && event.end_time
                ? `${formatTime(event.start_time)} - ${formatTime(
                    event.end_time
                  )}`
                : "N/A"}
            </AppTypography>
          }
        />
        <SideLabelInput
          viewer
          label="Location"
          input={
            <AppTypography noWrap>{event?.location || "N/A"}</AppTypography>
          }
        />
        {event?.description && (
          <SideLabelInput
            viewer
            label="Description"
            multiline
            input={<AppWysiwygViewer content={event.description} />}
          />
        )}
        <SideLabelInput
          viewer
          multiline
          label="Attachments"
          input={
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems="center"
              gridGap={8}>
              <Box
                overflow={"auto"}
                flex={1}
                display={"flex"}
                flexDirection={"row"}
                gridGap={8}>
                {event?.files &&
                  event.files.map((file) => {
                    return <EventFile url={file.file_url} key={file.id} />
                  })}
              </Box>
            </Box>
          }
        />
      </Paper>
    </Box>
  )
}

function ItineraryEventWithPopper(props: {
  eventItem: ReactElement
  eventId: number
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <ItineraryEventViewer eventId={props.eventId as number} />
      </Popover>
      <Box onClick={handleClick}>{props.eventItem}</Box>
    </>
  )
}

export function CalendarHours(props: {startHour: number}) {
  return (
    <Box position={"relative"}>
      <Box
        height={"100%"}
        position={"relative"}
        top={0}
        bottom={0}
        left={0}
        right={0}>
        {[...Array((24 - props.startHour) * 2)].map((u, i) => {
          return (
            <Box
              key={i}
              padding={0}
              top={`${30 * i}px`}
              margin={0}
              height={`${30}px`}
              border={"0.5px solid #ECECEC"}
              borderBottom={
                i % 2 === 0 ? "0px solid black" : "0.5px solid #ECECEC"
              }
              borderTop={
                i === 0
                  ? "1px solid #ECECEC"
                  : i % 2 !== 0
                  ? "0px solid black"
                  : "0.5px solid #ECECEC"
              }
              borderLeft={"0px solid black"}
              width={"70px"}>
              {i % 2 === 0 ? amPM(i / 2 + props.startHour) : ""}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
