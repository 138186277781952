import {
  AppBar,
  Box,
  IconButton,
  ListItem,
  makeStyles,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core"
import {Assignment, Menu} from "@material-ui/icons"
import {Link as ReactRouterLink} from "react-router-dom"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import {FlokTheme} from "../../theme"
import AppImage from "../base/AppImage"
import AppNotificationsMenuItem from "../base/AppNotificationsMenuItem"
import {useSidebar} from "./PageSidenav"

let useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
}))

// Should change this to PageHeader.tsx once getting-started page stops using the component
type PageAppBarProps = {}
export default function PageAppBar(props: PageAppBarProps) {
  let classes = useStyles(props)
  let {openSidebar} = useSidebar()
  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [retreat, retreatIdx] = useRetreat()
  return (
    <AppBar
      className={classes.root}
      position={"sticky"}
      color={"primary"}
      elevation={0}>
      <Toolbar>
        {isSmallScreen && (
          <IconButton
            className={classes.menuButton}
            edge="start"
            size="medium"
            color="inherit"
            onClick={openSidebar}>
            <Menu />
          </IconButton>
        )}
        {isSmallScreen && (
          <AppImage
            height="30px"
            alt="White Flok logo"
            img="branding/logos/icon_text-empty_bg-white%40.5x.png"
          />
        )}
        <Box marginLeft="auto" display={"flex"}>
          <Tooltip title="Task management">
            <ListItem
              style={{justifyContent: "center"}}
              component={ReactRouterLink}
              color="inherit"
              button
              dense
              to={AppRoutes.getPath("TaskListPage", {
                retreatIdx: retreatIdx.toString(),
              })}>
              <Assignment />
            </ListItem>
          </Tooltip>
          <AppNotificationsMenuItem />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
