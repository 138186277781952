import {
  Badge,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
  withStyles,
} from "@material-ui/core"
import {CheckBox, List} from "@material-ui/icons"
import React from "react"

//SORTING POPPER BUTTON - START

let useStyles = makeStyles((theme) => ({
  headerButton: {
    margin: "10px",
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))
const GroupByOptions = ["status", "label", "priority", "assignee"]
export type GroupByOption =
  | "none"
  | "label"
  | "status"
  | "priority"
  | "assignee"
export function GroupByPopper(props: {
  selected: GroupByOption | null
  setGroupBy: (newValue: GroupByOption | null) => void
  showBadge: boolean
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  let classes = useStyles()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  let {showBadge} = props
  let open = Boolean(anchorEl)
  const id = open ? "sorting-popper" : undefined

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        aria-describedby={id}
        aria-controls="fade-menu"
        aria-haspopup="true"
        type="button"
        variant="contained"
        className={classes.headerButton}
        onClick={handleClick}>
        <List fontSize="small" />

        <Badge color={"primary"} variant={"dot"} invisible={!showBadge}>
          <Typography>Group By</Typography>
        </Badge>
      </Button>

      <StyledMenu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disableAutoFocusItem
        onClose={handleMenuClose}
        keepMounted>
        {GroupByOptions.map((option) => {
          return (
            <MenuItem
              key={option}
              onClick={() => {
                props.setGroupBy(option as GroupByOption)
              }}>
              {props.selected === option && (
                <ListItemIcon>
                  <CheckBox />
                </ListItemIcon>
              )}
              <Box marginLeft="auto">
                <ListItemText
                  primary={option[0].toUpperCase() + option.substring(1)}
                />
              </Box>
            </MenuItem>
          )
        })}
        <MenuItem
          onClick={() => {
            props.setGroupBy("none")
          }}>
          {props.selected === "none" && (
            <ListItemIcon>
              <CheckBox />
            </ListItemIcon>
          )}
          <Box marginLeft="auto">
            <ListItemText primary={"None"} />
          </Box>
        </MenuItem>
      </StyledMenu>
    </Box>
  )
}
