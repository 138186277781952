import {Fade, IconButton, Menu, MenuItem} from '@material-ui/core'
import {MoreVert} from '@material-ui/icons'
import {useState} from 'react'

export type DropdownOptionType = {
  onClick: () => void
  text: string
  icon: JSX.Element
  disabled?: boolean
}

type TaskThreeDotDropdownProps = {
  dropdownOptions: DropdownOptionType[]
}
function TaskThreeDotDropdown(props: TaskThreeDotDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  let {dropdownOptions} = props
  const menuOpen = Boolean(anchorEl)

  return (
    <div>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
        }}
        TransitionComponent={Fade}>
        {dropdownOptions.map((option) => {
          return (
            <MenuItem
              disabled={option.disabled}
              key={option.text}
              onClick={() => {
                option.onClick()
                return setAnchorEl(null)
              }}>
              {option.icon}
              &nbsp; {option.text}
            </MenuItem>
          )
        })}
      </Menu>
      <IconButton
        size="small"
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={(event) => {
          event.stopPropagation()
          setAnchorEl(event.currentTarget)
        }}>
        <MoreVert />
      </IconButton>
    </div>
  )
}
export default TaskThreeDotDropdown
