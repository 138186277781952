import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  makeStyles,
  TextField,
  TextFieldProps,
  Typography,
} from "@material-ui/core"
import {Alert} from "@material-ui/lab"
import clsx from "clsx"
import {push} from "connected-react-router"
import {useFormik} from "formik"
import _ from "lodash"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import * as yup from "yup"
import {AgendaType, RFPModel} from "../../models/retreat"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {RootState} from "../../store"
import {ApiAction} from "../../store/actions/api"
import {getLodgingTags} from "../../store/actions/lodging"
import {getRFP, patchRetreat, postRFP} from "../../store/actions/retreat"
import {getTextFieldErrorProps, useQuery} from "../../utils"
import AppAlertModal from "../base/AppAlertModal"
import AppRetreatDatesInputOpen from "../base/AppDatesRangeInputOpen"
import AppStepForm from "../base/AppStepForm"
import AppStepFormStep from "../base/AppStepFormStep"
import AppTypography from "../base/AppTypography"
import BeforeUnload from "../base/BeforeUnload"
import PageBody from "../page/PageBody"
import PageContainer from "../page/PageContainer"
import RFPSummaryViewer from "./RFPSummaryViewer"

const MAX_WIDTH = 950
let useStyles = makeStyles((theme) => ({
  multilineTextfield: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    maxWidth: MAX_WIDTH,
  },

  agendaNotesTextField: {
    marginLeft: "auto",
    maxWidth: MAX_WIDTH,
    marginRight: "auto",
  },
  submitButton: {
    float: "right",
    marginTop: theme.spacing(2),
    marginRight: "10%",
  },
  roundedCorners: {
    borderRadius: theme.shape.borderRadius,
  },
  whiteInput: {
    backgroundColor: theme.palette.common.white,
  },
  datesNotes: {
    marginTop: theme.spacing(2),
    width: 690,
    [theme.breakpoints.down("sm")]: {
      width: 350,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  step: {
    marginTop: "20%",
    marginLeft: 45,
  },
  datesStep: {
    marginTop: "5%",
    marginLeft: 45,
    display: "flex",
    flexDirection: "column",
  },
  datesQuestions: {
    marginLeft: 45,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    marginRight: "auto",
    marginTop: theme.spacing(3),
  },
  agendaMC: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  agendaLink: {
    marginTop: theme.spacing(0.6),
  },
  budgetQuestion: {
    marginLeft: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  generalNotes: {
    marginTop: theme.spacing(1),
  },
  summaryStep: {
    padding: 30,
  },
  summaryContainer: {
    marginTop: theme.spacing(2),
  },
  pageBody: {
    padding: theme.spacing(2),
  },
  yesNoButton: {
    maxWidth: "70%",
  },
  yesNoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  lodgingTags: {
    maxWidth: "75%",
    display: "flex",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
  },
}))
function RetreatRfpPage() {
  let dispatch = useDispatch()
  let classes = useStyles()
  let toDate = (dt?: Date) =>
    dt
      ? dt.toISOString().substring(0, dt.toISOString().indexOf("T"))
      : undefined

  let [retreat] = useRetreat()
  let [next] = useQuery("next")
  let minPlanningDate = new Date()
  minPlanningDate.setDate(minPlanningDate.getDate() + 90)

  let rfp = useSelector((state: RootState) => {
    if (retreat.request_for_proposal_id) {
      return state.retreat.RFPs[retreat.request_for_proposal_id]
    }
  })

  useEffect(() => {
    if (!rfp && retreat.request_for_proposal_id) {
      dispatch(getRFP(retreat.request_for_proposal_id))
    }
  }, [dispatch, rfp, retreat.request_for_proposal_id])

  let [showInstructions, setShowInstructions] = useState(false)

  let formik = useFormik({
    validationSchema: yup.object().shape({
      number_of_rooms: yup
        .number()
        .required("Number of rooms is required.")
        .min(1, "At least 1 room is required.")
        .typeError("Number of rooms is required."),
      has_exact_dates: yup.boolean().required(),
      exact_dates_start: yup.date().when("has_exact_dates", {
        is: true,
        then: yup.date().required("Start date is required."),
      }),
      exact_dates_end: yup.date().when("has_exact_dates", {
        is: true,
        then: yup.date().required("End date is required."),
      }),
      flexible_number_of_nights: yup.number().when("has_exact_dates", {
        is: false,
        then: yup
          .number()
          .required("Number of nights is required.")
          .min(1, "At least one night required."),
      }),
      agenda_type: yup.string().required("Agenda type is required."),
      corporate_address: yup.string().required("Address is required."),
      flexible_preferred_months: yup.array().when("has_exact_dates", {
        is: false,
        then: yup
          .array(yup.string())
          .min(1, "At least one preferred month is required")
          .required("At least one preferred month is required"),
      }),
      budget_notes: yup.string().required("Budget notes are required."),
      location_notes: yup.string().required("Location notes are required."),
    }),
    initialValues: {
      has_exact_dates: rfp?.has_exact_dates ?? true,
      exact_dates_start: rfp?.exact_dates_start
        ? new Date(rfp.exact_dates_start)
        : undefined,
      exact_dates_end: rfp?.exact_dates_end
        ? new Date(rfp.exact_dates_end)
        : undefined,
      flexible_number_of_nights: rfp?.flexible_number_of_nights ?? 0,
      dates_notes: rfp?.dates_notes ?? "",
      agenda_type: rfp?.agenda_type ?? "",
      agenda_notes: rfp?.agenda_notes ?? "",
      number_of_rooms: rfp?.number_of_rooms ?? undefined,
      corporate_address: rfp?.corporate_address ?? "",
      general_notes: rfp?.general_notes ?? "",
      flexible_preferred_months: rfp?.flexible_preferred_months ?? [],
      budget_notes: rfp?.budget_notes ?? "",
      location_notes: rfp?.location_notes ?? "",
      lodging_tag_ids: rfp?.lodging_tag_ids ?? [],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      let response = (await dispatch(
        postRFP({
          ...values,
          retreat_id: retreat.id,
          exact_dates_start: toDate(values.exact_dates_start),
          exact_dates_end: toDate(values.exact_dates_end),
          agenda_type: values.agenda_type as AgendaType,
        })
      )) as unknown as ApiAction
      if (!response.error) {
        let patchResponse = (await dispatch(
          patchRetreat(retreat.id, {
            request_for_proposal_id: response.payload.request_for_proposal.id,
          })
        )) as unknown as ApiAction
        if (!patchResponse.error) {
          setTakingForm(false)
          setShowInstructions(true)
        }
      }
    },
  })

  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  function finalValues(values: {
    has_exact_dates: boolean
    exact_dates_start?: Date
    exact_dates_end?: Date
    flexible_number_of_nights: number
    dates_notes: string
    agenda_type: string
    agenda_notes: string
    number_of_rooms?: number
    corporate_address: string
    general_notes: string
    location_notes: string
    lodging_tag_ids: number[]
  }) {
    let newValues = {...values} as RFPModel

    newValues.exact_dates_start = toDate(values.exact_dates_start)
    newValues.exact_dates_end = toDate(values.exact_dates_end)
    return newValues
  }

  const [lastNext, setLastNext] = useState<number | undefined>(undefined)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  let [adminQuery] = useQuery("admin")

  const [takingForm, setTakingForm] = useState(
    !retreat.request_for_proposal_id && !retreat.lodging_final_contract_url
  )

  let lodgingTags = useSelector((state: RootState) => {
    return state.lodging.lodgingTags
  })
  useEffect(() => {
    !Object.values(lodgingTags)[0] && dispatch(getLodgingTags())
  }, [dispatch, lodgingTags])

  const commonMultiLineInputProps: TextFieldProps = {
    InputProps: {
      classes: {
        root: classes.whiteInput,
      },
    },
    className: clsx(
      classes.agendaNotesTextField,
      classes.generalNotes,
      classes.roundedCorners
    ),
  }

  return takingForm ? (
    <>
      <BeforeUnload
        when={!_.isEqual(formik.values, formik.initialValues)}
        message="Are you sure you wish to leave this page? Changes you made have not been saved"
      />
      <AppStepForm
        onSubmit={formik.handleSubmit}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}>
        {/* Rooms question */}
        <AppStepFormStep
          handleNext={async () => {
            setLastNext(activeStep)
            formik.validateForm().then((res) => {
              if (!res.number_of_rooms) {
                handleNext()
              }
            })
          }}>
          <div className={classes.step}>
            <StepFormHeader
              title="How many rooms do you require?"
              subtitle="Please use your best estimate. This can be modified later on."
            />
            <div>
              <TextField
                variant="outlined"
                onBlur={() => {
                  formik.validateField("number_of_rooms")
                }}
                value={formik.values.number_of_rooms}
                id="number_of_rooms"
                onChange={formik.handleChange}
                {...getTextFieldErrorProps(formik, "number_of_rooms")}
                className={classes.multilineTextfield}
                InputProps={{
                  classes: {
                    input: clsx(classes.whiteInput, classes.roundedCorners),
                  },
                }}
                fullWidth
                type="number"
              />
            </div>
          </div>
        </AppStepFormStep>
        {/* Dates question */}
        <AppStepFormStep
          handleNext={async () => {
            setLastNext(activeStep)
            formik.validateForm().then((res) => {
              if (
                !res.exact_dates_start &&
                !res.exact_dates_end &&
                !res.flexible_number_of_nights &&
                !res.flexible_preferred_months
              ) {
                handleNext()
              }
            })
          }}>
          <div className={classes.datesStep}>
            <StepFormHeader title="Tell us a little bit about when you are planning to go" />
            <div className={classes.datesQuestions}>
              <AppRetreatDatesInputOpen
                preferredMonths={formik.values.flexible_preferred_months}
                onChangePreferredMonths={(vals) => {
                  formik.setFieldValue("flexible_preferred_months", vals)
                }}
                error={
                  !!(
                    formik.errors.exact_dates_start ||
                    formik.errors.exact_dates_end ||
                    formik.errors.has_exact_dates ||
                    formik.errors.flexible_number_of_nights ||
                    formik.errors.flexible_preferred_months
                  )
                }
                isExactDates={formik.values.has_exact_dates}
                onChangeIsExactDates={(isExact) => {
                  formik.setFieldValue("has_exact_dates", isExact)
                }}
                start={formik.values.exact_dates_start}
                end={formik.values.exact_dates_end}
                onChangeDateRange={(start, end) => {
                  formik.setFieldValue("exact_dates_start", start)
                  formik.setFieldValue("exact_dates_end", end)
                }}
                onChangeNumNights={(numNights) => {
                  formik.setFieldValue("flexible_number_of_nights", numNights)
                }}
                numNights={formik.values.flexible_number_of_nights}
                bufferDays={10}
              />
              <TextField
                value={formik.values.dates_notes}
                onChange={formik.handleChange}
                id="dates_notes"
                className={clsx(
                  classes.whiteInput,
                  classes.datesNotes,
                  classes.roundedCorners
                )}
                multiline
                label="What else should we know about these dates?"
                rows={4}
                variant="outlined"
                fullWidth
              />
              {lastNext === activeStep && (
                <FormHelperText error>
                  {formik.errors.exact_dates_start}
                  {formik.errors.exact_dates_start && <br />}
                  {formik.errors.exact_dates_end}
                  {formik.errors.exact_dates_end && <br />}
                  {formik.errors.flexible_number_of_nights}
                  {formik.errors.flexible_number_of_nights && <br />}
                  {formik.errors.flexible_preferred_months}
                </FormHelperText>
              )}
            </div>
          </div>
        </AppStepFormStep>
        {/* Agenda Question */}
        <AppStepFormStep
          handleNext={() => {
            setLastNext(activeStep)
            formik.validateForm().then((res) => {
              if (!res.agenda_type) {
                handleNext()
              }
            })
          }}>
          <div className={classes.step}>
            <StepFormHeader
              title="Choose the Agenda which best matches your planned
                  itinerary."
              subtitle="Venues need to get a sense of how much meeting space you will need"
            />

            <div>
              <div>
                <FormGroup>
                  <div className={classes.agendaMC}>
                    <Button
                      variant={
                        formik.values.agenda_type === "ALL_WORK"
                          ? "contained"
                          : "outlined"
                      }
                      size="large"
                      onClick={() => {
                        formik.setFieldValue("agenda_type", "ALL_WORK")
                      }}
                      color="primary">
                      All Work
                    </Button>
                    <Button
                      variant={
                        formik.values.agenda_type === "ALL_PLAY"
                          ? "contained"
                          : "outlined"
                      }
                      color="primary"
                      size="large"
                      onClick={() => {
                        formik.setFieldValue("agenda_type", "ALL_PLAY")
                      }}>
                      All Play
                    </Button>
                    <Button
                      variant={
                        formik.values.agenda_type === "WORK_PLAY_SPLIT"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        formik.setFieldValue("agenda_type", "WORK_PLAY_SPLIT")
                      }}
                      size="large"
                      color="primary">
                      Work and Play Split
                    </Button>
                    <Button
                      variant={
                        formik.values.agenda_type === "WORK_PLAY_ALT"
                          ? "contained"
                          : "outlined"
                      }
                      size="large"
                      onClick={() => {
                        formik.setFieldValue("agenda_type", "WORK_PLAY_ALT")
                      }}
                      color="primary">
                      Work and Play Alt
                    </Button>
                  </div>
                  <a
                    href="https://drive.google.com/file/d/1uFM60nJEdwh8SM3GzUrKkgNR6_fkCx7M/view"
                    className={classes.agendaLink}
                    rel="noopener noreferrer"
                    target="_blank">
                    What do these options mean?
                  </a>
                  {lastNext === activeStep && (
                    <FormHelperText error>
                      {formik.errors.agenda_type}
                    </FormHelperText>
                  )}
                </FormGroup>
              </div>
              <TextField
                {...commonMultiLineInputProps}
                value={formik.values.agenda_notes}
                onChange={formik.handleChange}
                id="agenda_notes"
                multiline
                placeholder="What else should we know about your agenda? Will you need breakout rooms? Are there any specific A/V 'must haves' for your team?"
                rows={4}
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
        </AppStepFormStep>
        {/* Address question */}
        <AppStepFormStep
          handleNext={() => {
            setLastNext(activeStep)
            formik.validateForm().then((res) => {
              if (!res.corporate_address) {
                handleNext()
              }
            })
          }}>
          <div className={classes.step}>
            <StepFormHeader
              title="Where is your Corporate Address?"
              subtitle="Hotels need this information to assign you to the correct group sales coordinator. Most remote companies just put down their legal company address or mailing address (often a PO box)."
            />

            <TextField
              variant="outlined"
              error={
                formik.errors &&
                !!formik.errors["corporate_address"] &&
                lastNext === activeStep
              }
              helperText={
                lastNext === activeStep &&
                formik.errors &&
                formik.errors["corporate_address"]
              }
              placeholder="123 Flok Way, New York, New York 10016"
              InputProps={{
                classes: {
                  input: clsx(classes.whiteInput, classes.roundedCorners),
                },
              }}
              value={formik.values.corporate_address}
              id="corporate_address"
              onChange={formik.handleChange}
              fullWidth
              className={classes.multilineTextfield}
            />
          </div>
        </AppStepFormStep>
        {/* Budget question */}
        <AppStepFormStep
          handleNext={() => {
            setLastNext(activeStep)
            formik.validateForm().then((res) => {
              if (!res.budget_notes) {
                handleNext()
              }
            })
          }}>
          <div className={classes.step}>
            <StepFormHeader
              title="Budget"
              subtitle="What is your overall or per person budget?"
            />
            <TextField
              multiline
              rows={4}
              id="budget_notes"
              onChange={formik.handleChange}
              value={formik.values.budget_notes}
              placeholder="This can be an estimate at this point, but it is very important to have an idea of your budgetary needs, so we can find the right property for you. We recommend a 3 night/4 day event which usually costs $2,500-$3,200 per person for Flights, Rooms, Food and Beverage, Meeting Space, Activities, Transportation, and Flok Planning fee."
              variant="outlined"
              fullWidth
              error={
                formik.errors &&
                !!formik.errors["budget_notes"] &&
                lastNext === activeStep
              }
              helperText={
                lastNext === activeStep &&
                formik.errors &&
                formik.errors["budget_notes"]
              }
              {...commonMultiLineInputProps}
            />
          </div>
        </AppStepFormStep>
        {/* Location question */}
        <AppStepFormStep
          handleNext={() => {
            setLastNext(activeStep)
            formik.validateForm().then((res) => {
              if (!res.location_notes) {
                handleNext()
              }
            })
          }}>
          <div className={classes.step}>
            <StepFormHeader
              title="Location Preferences"
              subtitle="The more information you are able to share with our team, the better the options we will be able to source for you."
            />
            <TextField
              multiline
              rows={4}
              id="location_notes"
              onChange={formik.handleChange}
              value={formik.values.location_notes}
              placeholder="Top 3 cities? Somewhere warm? Domestic or International?  City or nature?"
              variant="outlined"
              fullWidth
              error={
                formik.errors &&
                !!formik.errors["location_notes"] &&
                lastNext === activeStep
              }
              helperText={
                lastNext === activeStep &&
                formik.errors &&
                formik.errors["location_notes"]
              }
              {...commonMultiLineInputProps}
            />
          </div>
        </AppStepFormStep>

        {/* Overall Vibe Question */}
        <AppStepFormStep handleNext={handleNext}>
          <div className={classes.step}>
            <StepFormHeader
              title="Please select any of the tags that are of interest to you"
              subtitle="This will help us narrow down the search for the perfect venue"
            />
            <div className={classes.lodgingTags}>
              {Object.values(lodgingTags).map((tag) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={tag.name}
                        color="primary"
                        checked={
                          formik.values.lodging_tag_ids.indexOf(tag.id) !== -1
                        }
                        onChange={(e, checked) => {
                          if (checked) {
                            formik.setFieldValue("lodging_tag_ids", [
                              ...formik.values.lodging_tag_ids,
                              tag.id,
                            ])
                          } else {
                            let tags = [...formik.values.lodging_tag_ids]
                            let index = tags.indexOf(tag.id)
                            tags.splice(index, 1)
                            formik.setFieldValue("lodging_tag_ids", tags)
                          }
                        }}
                      />
                    }
                    label={tag.name}
                  />
                )
              })}
            </div>
          </div>
        </AppStepFormStep>
        {/* General notes question */}
        <AppStepFormStep handleNext={handleNext}>
          <div className={classes.step}>
            <StepFormHeader title="Is there anything else you'd like to tell us?" />
            <TextField
              multiline
              rows={4}
              id="general_notes"
              onChange={formik.handleChange}
              value={formik.values.general_notes}
              placeholder="What is the look and feel you are going for? Have you already started contacting hotels?"
              variant="outlined"
              fullWidth
              {...commonMultiLineInputProps}
            />
          </div>
        </AppStepFormStep>
        {/* Summary question */}
        <AppStepFormStep handleNext={() => {}}>
          <div className={classes.summaryStep}>
            <StepFormHeader
              title="Almost Done"
              subtitle="Please review your responses and press submit when you are ready"
            />
            <div className={classes.summaryContainer}>
              <RFPSummaryViewer rfp={finalValues(formik.values)} />
            </div>
          </div>
        </AppStepFormStep>
      </AppStepForm>
    </>
  ) : (
    <PageContainer>
      <PageBody appBar>
        <AppAlertModal
          open={showInstructions}
          title="We'll get to sourcing!"
          severity="success"
          text={
            "Your dedicated Flok account manager will start gathering proposals from hotels! In the meantime, feel free to checkout our explore page to browse hotels and directly request proposals yourself!"
          }
          onOk={() => {
            setShowInstructions(false)
            if (next) dispatch(push(decodeURIComponent(next)))
          }}
          onClose={() => {
            setShowInstructions(false)
            if (next) dispatch(push(decodeURIComponent(next)))
          }}
        />
        <div className={classes.pageBody}>
          <Dialog
            open={editDialogOpen}
            onClose={() => {
              setEditDialogOpen(false)
            }}>
            <DialogTitle>Want to Edit your Event Profile?</DialogTitle>
            <DialogContent>
              Since you have already submitted requests to hotels, you will need
              Flok's help with this. Please reach out to your account manager.
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setEditDialogOpen(false)
                }}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <div className={classes.header}>
            <div>
              <Typography variant="h1">
                Lodging
                <AppTypography variant="inherit" fontWeight="light">
                  - Event Profile
                </AppTypography>
              </Typography>
              <Typography variant="body1">Review your answers</Typography>
            </div>
            <div>
              {!retreat.lodging_final_contract_url && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (
                      !retreat.lodging_has_submitted_hotels ||
                      adminQuery === "true"
                    ) {
                      setTakingForm(true)
                      setActiveStep(0)
                    } else {
                      setEditDialogOpen(true)
                    }
                  }}>
                  Edit
                </Button>
              )}
            </div>
          </div>
          {!!retreat.lodging_final_contract_url && (
            <div className={classes.alert}>
              <Alert severity="warning">
                You may no longer make changes to your Event Profile once you
                are in contract with a hotel
              </Alert>
            </div>
          )}
          <div className={classes.summaryContainer}>
            <RFPSummaryViewer rfp={rfp} />
          </div>
        </div>
      </PageBody>
    </PageContainer>
  )
}
export default RetreatRfpPage

let useStepFormHeaderStyles = makeStyles((theme) => ({
  title: {
    color: (props: {titleColor?: string; subtitleColor?: string}) =>
      props.titleColor ?? theme.palette.common.black,
  },
  subtitle: {
    color: (props: {subtitleColor?: string}) =>
      props.subtitleColor ?? theme.palette.common.black,
    fontSize: "1.1rem",
    marginTop: theme.spacing(0.5),
    maxWidth: MAX_WIDTH,
  },
}))

function StepFormHeader(props: {
  title: string
  subtitle?: string
  titleColor?: string
  subtitleColor?: string
}) {
  let classes = useStepFormHeaderStyles(props)
  return (
    <>
      <Typography variant="h2" className={classes.title}>
        {props.title}
      </Typography>
      {props.subtitle && (
        <AppTypography fontWeight="light" className={classes.subtitle}>
          {props.subtitle}
        </AppTypography>
      )}
    </>
  )
}
