import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  useTheme,
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {convertFromRaw, convertToRaw, EditorState} from "draft-js"
import {useFormik} from "formik"
import {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux"
import * as yup from "yup"
import {FileModel} from "../../models/retreat"
import {
  LabelModel,
  TaskModel,
  TaskPriorityName,
  TaskPriorityValues,
  TaskStatusName,
  TaskStatusValues,
} from "../../models/task"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {ApiAction} from "../../store/actions/api"
import {postComment, postCommentThread} from "../../store/actions/retreat"
import {
  deleteAssigneeToTask,
  deleteFileToTask,
  deleteLabelToTask,
  patchTask,
  postAssigneeToTask,
  postFileToTask,
  postLabel,
  postLabelToTask,
} from "../../store/actions/task"
import {useScrollToComment} from "../../utils/commentUtils"
import {useUsers} from "../../utils/retreatUtils"
import AppTypography from "../base/AppTypography"
import AppUploadFile from "../base/AppUploadFile"
import {AppWysiwygEditor} from "../base/AppWysiwyg"
import CommentForm from "../comments/CommentForm"
import CommentsList from "../comments/CommentsList"
import {EventFile, SideLabelInput} from "../itinerary/ItineraryEventForm"
import TaskThreeDotDropdown, {DropdownOptionType} from "./TaskThreeDotDropdown"

let useStyles = makeStyles((theme) => ({
  dialog: {
    position: "relative",
    top: "10%",
    left: "21%",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    minWidth: "600px",
    gap: theme.spacing(1),
    "& .MuiOutlinedInput-root:not(:hover):not(:focus-within) .MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
    "& .input:not(:hover):not(:focus-within)": {
      border: "none",
    },
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    float: "right",
    marginTop: "16px",
  },
  autocomplete: {
    minWidth: 160,
    width: "fit-content",
  },
  singleAutocomplete: {
    minWidth: 160,
    width: "fit-content",
    "& .MuiChip-deleteIcon": {
      display: "none",
    },
  },
}))

type TaskViewerProps = {
  open: boolean
  task: TaskModel
  onClose: () => void
  onSubmit: () => void
  retreatUsers: number[]
  labels: {
    [id: number]: LabelModel | undefined
  }
  threeDotDropdownOptions?: DropdownOptionType[]
}
function TaskViewer(props: TaskViewerProps) {
  let {open, task, labels, threeDotDropdownOptions, retreatUsers} = props
  let [newLabelDialog, setNewLabelDialog] = useState(false)
  let classes = useStyles()
  let dispatch = useDispatch()
  let theme = useTheme()
  let startCommentId = useScrollToComment()

  let taskViewRef = useRef()
  let [retreat] = useRetreat()
  let [assigneeOptions, loadingAssignees] = useUsers(retreat.users)

  let formik = useFormik({
    initialValues: {
      title: task.title,
      description: task.description
        ? EditorState.createWithContent(convertFromRaw(task.description))
        : EditorState.createEmpty(),
      due_date: task.due_date,
      assignees: task.assignees,
      labels: task.labels,
      priority: task.priority,
      status: task.status,
      files: task.files,
    },
    onSubmit: async (values) => {
      let formikFiles = [...(values.files ? values.files : [])]
      let postValues = {
        ...values,
        files: [],
        assignees: [],
        labels: [],
        description: convertToRaw(values.description.getCurrentContent()),
      }
      let response = (await dispatch(
        patchTask(postValues, task.id)
      )) as unknown as ApiAction
      if (!response.error) {
        let filesToPost = fileDifference(formikFiles, task?.files)

        await Promise.all(
          filesToPost.map(async (file) => {
            let fileResponse = (await dispatch(
              postFileToTask({
                task_id: response.payload.task.id,
                file_id: file.id,
              })
            )) as unknown as ApiAction
            if (!fileResponse.error) {
              dispatch({
                type: "ADD_FILE_TO_TASK",
                file: file,
                event_id: response.payload.event.id,
              })
            }
          })
        )
        props.onSubmit()
      }
    },
    validationSchema: yup.object().shape({
      lodging_final_contract_url: yup.string().url("Enter a valid url"),
    }),
  })
  let {resetForm} = formik

  useEffect(() => {
    resetForm({
      values: {
        title: task.title,
        description: task.description
          ? EditorState.createWithContent(convertFromRaw(task.description))
          : EditorState.createEmpty(),
        priority: task.priority,
        due_date: task.due_date,
        assignees: task.assignees || [],
        labels: task.labels || [],
        status: task.status,
        files: task.files || [],
      },
    })
  }, [task, resetForm, dispatch])

  function fileDifference(fileArr1: FileModel[], fileArr2: FileModel[]) {
    let fileMap: {[id: number]: true} = {}
    fileArr1.forEach((file) => {
      fileMap[file.id] = true
    })
    return fileArr2.filter((file) => !fileMap[file.id])
  }
  async function handleAssigneesChange(
    changeReason: string,
    changedOption: any
  ) {
    if (changeReason === "remove-option") {
      return dispatch(
        deleteAssigneeToTask({
          task_id: task.id,
          user_id: changedOption.option,
        })
      )
    }
    if (changeReason === "select-option") {
      return dispatch(
        postAssigneeToTask({
          task_id: task.id,
          user_id: changedOption.option,
        })
      )
    }
  }
  async function handleLabelsChange(changeReason: string, changedOption: any) {
    if (changeReason === "remove-option") {
      return dispatch(
        deleteLabelToTask({
          taskId: task.id,
          labelId: changedOption.option,
        })
      )
    }
    if (changeReason === "select-option") {
      return dispatch(
        postLabelToTask({
          task_id: task.id,
          label_id: changedOption.option,
        })
      )
    }
  }
  function returnSaveOnBlur(field: string, taskId: number) {
    return () => {
      if (!formik.errors[field as keyof typeof formik.errors]) {
        dispatch(
          patchTask(
            {[field]: formik.values[field as keyof typeof formik.values]},
            taskId
          )
        )
      }
    }
  }

  function returnSaveOnChange(field: string, taskId: number) {
    return (e: any) => {
      formik.setFieldValue(field, e.target.value)
      dispatch(patchTask({[field]: e.target.value}, taskId))
    }
  }
  return (
    <Dialog
      open={open}
      className={classes.dialog}
      ref={taskViewRef}
      aria-labelledby="form-dialog-title"
      maxWidth={"md"}
      fullWidth
      onClose={props.onClose}
      scroll={"paper"}>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="row">
            <TextField
              id="title"
              error={!!formik.errors.title}
              helperText={formik.errors.title}
              variant="outlined"
              value={formik.values.title}
              placeholder="Task Title"
              required
              fullWidth
              InputProps={{
                classes: {
                  input: classes.title,
                },
              }}
              onChange={formik.handleChange}
              onBlur={returnSaveOnBlur("title", task.id)}
            />
            {threeDotDropdownOptions && (
              <TaskThreeDotDropdown dropdownOptions={threeDotDropdownOptions} />
            )}
          </Box>
          <SideLabelInput
            label="Due Date"
            input={
              <TextField
                id="due_date"
                value={formik.values.due_date}
                required
                variant={"outlined"}
                size="small"
                type="date"
                InputLabelProps={{shrink: true}}
                onChange={returnSaveOnChange("due_date", task.id)}
              />
            }
          />
          <SideLabelInput
            label="Assignees"
            input={
              <Autocomplete
                autoSelect
                multiple
                className={classes.autocomplete}
                filterSelectedOptions
                blurOnSelect={false}
                disableClearable
                size="small"
                value={formik.values.assignees}
                options={Object.keys(assigneeOptions).map((userId) =>
                  parseInt(userId)
                )}
                getOptionLabel={(option) => {
                  return `${assigneeOptions[option]?.first_name} ${assigneeOptions[option]?.last_name}`
                }}
                id="assignees"
                filterOptions={(x) => x}
                onChange={async (e, newVals, changeReason, changedOption) => {
                  if (changeReason !== "blur") {
                    await formik.setFieldValue(
                      "assignees",
                      Array.from(new Set(newVals.sort()))
                    )
                    handleAssigneesChange(changeReason, changedOption)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant={"outlined"}
                    id="assignees"
                  />
                )}
              />
            }
          />
          <SideLabelInput
            label="Labels"
            input={
              <Autocomplete
                autoSelect
                className={classes.autocomplete}
                multiple
                disableClearable
                filterSelectedOptions
                value={formik.values.labels}
                options={Object.keys(labels)
                  .map((labelId) => parseInt(labelId))
                  .concat(-1)} // <- flag for creating new label
                getOptionLabel={(option) => {
                  return option !== -1
                    ? `${labels[option]?.text}`
                    : "+ Create new label"
                }}
                id="labels"
                filterOptions={(x) => x}
                onChange={async (e, newVals, changeReason, changedOption) => {
                  if (changeReason !== "blur") {
                    if (changedOption?.option === -1) {
                      setNewLabelDialog(true)
                      return
                    }
                    await formik.setFieldValue(
                      "labels",
                      Array.from(new Set(newVals.sort()))
                    )
                    handleLabelsChange(changeReason, changedOption)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant={"outlined"}
                    id="labels"
                  />
                )}
              />
            }
          />
          <LabelFormModal
            onSuccess={(label) => {
              return dispatch(
                postLabelToTask({
                  task_id: task.id,
                  label_id: label.id,
                })
              ) as unknown as ApiAction
            }}
            open={newLabelDialog}
            onClose={() => setNewLabelDialog(false)}
            retreatId={retreat.id}
          />
          <SideLabelInput
            label="Status"
            input={
              <Autocomplete
                size="small"
                autoSelect
                multiple
                disableClearable
                className={classes.singleAutocomplete}
                filterSelectedOptions
                value={formik.values.status ? [formik.values.status] : []}
                options={TaskStatusValues}
                getOptionLabel={(option) => {
                  return TaskStatusName[option]
                }}
                id="status"
                filterOptions={(x) => x}
                onChange={(e, newVals, changeReason) => {
                  if (changeReason !== "blur") {
                    let newValue = formik.values.status
                      ? newVals[1]
                      : newVals[0]
                    formik.setFieldValue("status", newValue)
                    dispatch(patchTask({status: newValue}, task.id))
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant={"outlined"} id="status" />
                )}
              />
            }
          />

          <SideLabelInput
            label="Priority"
            input={
              <Autocomplete
                size="small"
                autoSelect
                multiple
                className={classes.singleAutocomplete}
                filterSelectedOptions
                value={formik.values.priority ? [formik.values.priority] : []}
                options={TaskPriorityValues}
                getOptionLabel={(option) => {
                  if (option) {
                    return TaskPriorityName[option]
                  } else return ""
                }}
                id="priority"
                filterOptions={(x) => x}
                onChange={(e, newVals, changeReason) => {
                  if (changeReason !== "blur") {
                    let newValue = formik.values.priority
                      ? newVals[1]
                      : newVals[0]
                    formik.setFieldValue("priority", newValue)
                    dispatch(patchTask({priority: newValue ?? null}, task.id))
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant={"outlined"} id="priority" />
                )}
              />
            }
          />

          <SideLabelInput
            label="Description"
            input={
              <Box
                className="input"
                border={"1px solid black"}
                borderRadius={theme.shape.borderRadius}
                onBlur={() => {
                  if (!formik.errors.description) {
                    dispatch(
                      patchTask(
                        {
                          description: convertToRaw(
                            formik.values.description.getCurrentContent()
                          ),
                        },
                        task.id
                      )
                    )
                  }
                }}>
                <AppWysiwygEditor
                  editorState={formik.values.description}
                  onEditorStateChange={(val) => {
                    formik.setFieldValue("description", val)
                  }}
                />
              </Box>
            }
            multiline
          />

          <Box
            clone
            style={{
              fontSize: "0.9rem",
              color: theme.palette.grey[600],
              width: "30%",
              marginRight: "auto",
            }}>
            {<AppTypography noWrap>Files</AppTypography>}
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems="center"
            gridGap={8}>
            {formik.values && formik.values && (
              <Box
                overflow={"auto"}
                flex={1}
                display={"flex"}
                flexDirection={"row"}
                gridGap={8}>
                {formik.values.files &&
                  formik.values.files.map((file) => {
                    return (
                      <EventFile
                        url={file.file_url}
                        key={file.id}
                        onDelete={() => {
                          dispatch(deleteFileToTask(file.id, task.id as number))
                        }}
                      />
                    )
                  })}
              </Box>
            )}
          </Box>
          <Box>
            <AppUploadFile
              alt
              accepts="image/png, image/jpg, application/pdf, image/jpeg"
              rightText={""}
              id="file"
              handleChange={async (file) => {
                let response = (await dispatch(
                  postFileToTask({
                    task_id: task.id,
                    file_id: file.id,
                  })
                )) as unknown as ApiAction
                if (!response.error) {
                  dispatch({
                    type: "ADD_FILE_TO_TASK",
                    file: file,
                    task_id: task.id,
                  })
                }
              }}
            />
          </Box>
          {task.comment_thread_id && (
            <SideLabelInput
              multiline
              label="Comments"
              input={
                <CommentsList
                  commentThreadId={task.comment_thread_id}
                  startCommentId={startCommentId}
                />
              }
            />
          )}
        </DialogContent>
        <Box bgcolor={"grey.300"} paddingTop={1}>
          <DialogActions>
            <Box width="100%">
              <CommentForm
                onSubmit={async (values) => {
                  if (task.comment_thread_id) {
                    dispatch(
                      postComment({
                        ...values,
                        comment_thread_id: task.comment_thread_id,
                        retreat_id: retreat.id,
                        redirect: `/tasks/${task.id}?comment=:id`,
                      })
                    )
                  } else {
                    let threadResponse = (await dispatch(
                      postCommentThread()
                    )) as unknown as ApiAction
                    if (!threadResponse.error) {
                      let eventResponse = (await dispatch(
                        patchTask(
                          {
                            comment_thread_id:
                              threadResponse.payload.comment_thread.id,
                          },
                          task.id
                        )
                      )) as unknown as ApiAction

                      if (!eventResponse.error) {
                        dispatch(
                          postComment({
                            ...values,
                            comment_thread_id:
                              threadResponse.payload.comment_thread.id,
                            retreat_id: retreat.id,
                            redirect: `/tasks/${task.id}?comment=:id`,
                          })
                        )
                      }
                    }
                  }
                }}
              />
            </Box>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  )
}

export default TaskViewer

export function LabelFormModal(props: {
  open: boolean
  onClose: () => void
  onSuccess: (label: LabelModel) => void
  retreatId: number
}) {
  let classes = useStyles()
  let [newLabel, setNewLabel] = useState({
    text: "",
    id: 0,
  })
  let dispatch = useDispatch()
  async function handleNewLabel(
    text: string,
    retreat_id: number,
    color?: string
  ) {
    let response = (await dispatch(
      postLabel({text, retreat_id, color})
    )) as unknown as ApiAction
    if (!response.error) {
      await props.onSuccess(response.payload.label)
      props.onClose()
    }
  }
  return (
    <Dialog
      open={props.open}
      className={classes.dialog}
      maxWidth={"sm"}
      fullWidth
      onClose={props.onClose}>
      <Box padding={"20px"}>
        <DialogTitle>Create new label</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Label title"
            variant="outlined"
            value={newLabel.text}
            id="new-label"
            required
            onChange={(e) => setNewLabel({...newLabel, text: e.target.value})}
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.buttons}
            onClick={() =>
              newLabel.text &&
              handleNewLabel(newLabel.text, props.retreatId, undefined)
            }>
            Create Label
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
