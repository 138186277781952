import {Button, makeStyles} from "@material-ui/core"
import {ControlPoint} from "@material-ui/icons"
import {useDispatch} from "react-redux"
import config, {IMAGE_SERVER_BASE_URL_KEY} from "../../config"
import {FileModel} from "../../models/retreat"
import {enqueueSnackbar} from "../../notistack-lib/actions"

let useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    height: "100%",
    aspectRatio: "16/9",
    backgroundColor: theme.palette.grey[300],
  },
}))
type AppUploadLargeImageProps = {
  handleChange: (file: FileModel) => void
  id: string
  type?: string
  accepts?: string
}

export default function AppUploadImageLargeButton(
  props: AppUploadLargeImageProps
) {
  let dispatch = useDispatch()

  let classes = useStyles()
  return (
    <Button variant="outlined" component="label" className={classes.button}>
      <ControlPoint fontSize="large" />
      <input
        type="file"
        accept={props.accepts ? props.accepts : "application/pdf"}
        hidden
        onChange={(e) => {
          if (e.target && e.target.files && e.target.files[0]) {
            let data = new FormData()
            data.append("file", e.target.files[0])
            fetch(
              `${config.get(IMAGE_SERVER_BASE_URL_KEY)}/api/files${
                props.type ? "?type=" + props.type : ""
              }`,
              {
                body: data,
                method: "POST",
                mode: "cors",
              }
            )
              .then((res) => res.json())
              .then(async (resdata) => {
                await props.handleChange(resdata.file)
              })
              .catch((error) => {
                dispatch(
                  enqueueSnackbar({
                    message: "Oops, something went wrong",
                    options: {
                      variant: "error",
                    },
                  })
                )
              })
          }
        }}
      />
    </Button>
  )
}
