import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  makeStyles,
} from "@material-ui/core"
import {useComment, useUser} from "../../utils/retreatUtils"
import {AppWysiwygViewer} from "../base/AppWysiwyg"

let useStyles = makeStyles((theme) => ({
  editorContainer: {
    "& div p:first-child": {
      marginTop: 2,
    },
    "& div p:last-child": {
      marginBottom: 2,
    },
  },
}))

export default function Comment(props: {id: number}) {
  let [comment] = useComment(props.id)
  let [user, userLoading] = useUser(comment?.created_by_user_id ?? -1)
  let userName = `${user?.first_name} ${user?.last_name}`
  let classes = useStyles()

  return comment ? (
    <Grid container wrap="nowrap" spacing={2} id={`comment-${props.id}`}>
      {userLoading ? (
        <Box padding={2} display="flex" width="100%">
          <Box marginLeft="auto" marginRight="auto">
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <>
          <Grid item>
            <Avatar alt={userName} src="images-not-implemented-yet.jpg" />
          </Grid>
          <Grid>
            <h4 style={{margin: 0, textAlign: "left"}}>{userName}</h4>
            <Box className={classes.editorContainer} margin={0}>
              <AppWysiwygViewer content={comment.comment_text} />
            </Box>
            {comment && (
              <p style={{textAlign: "left", color: "gray", marginTop: 2}}>
                {new Intl.DateTimeFormat("en-US", {
                  timeStyle: "short",
                  dateStyle: "short",
                }).format(new Date(comment.created_at))}
              </p>
            )}
          </Grid>
        </>
      )}
    </Grid>
  ) : (
    <></>
  )
}
