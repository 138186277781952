import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core"
import {
  Apartment,
  Done,
  Flight,
  People,
  Room,
  Settings,
} from "@material-ui/icons"
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab"
import {push} from "connected-react-router"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link as ReactRouterLink, Link as RouterLink} from "react-router-dom"
import AppTypography from "../../components/base/AppTypography"
import AppOverviewCard, {
  AppOverviewCardList,
} from "../../components/overview/AppOverviewCard"
import PageBody from "../../components/page/PageBody"
import {RetreatAttendeeModel} from "../../models/retreat"
import {AppRoutes, FlokPageName} from "../../Stack"
import {RootState} from "../../store"
import {getHotels} from "../../store/actions/lodging"
import {getRetreatName, useRetreatAttendees} from "../../utils/retreatUtils"
import {useRetreat} from "../misc/RetreatProvider"

let useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(2),
    "& > *:not(:first-child)": {
      paddingLeft: theme.spacing(1),
    },
  },
  overviewHeader: {
    marginBottom: theme.spacing(1),
  },
  headerIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: "top",
    "&.todo": {
      color: theme.palette.error.main,
    },
    "&.next": {
      color: theme.palette.warning.main,
    },
    "&.completed": {
      color: theme.palette.success.main,
    },
  },
  todoIcon: {},
  iconExpanded: {
    transform: "rotateZ(180deg)",
  },
  expandButtons: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  retreatNameLine: {display: "flex", alignItems: "center"},
  timeline: {
    "& .MuiTimelineItem-missingOppositeContent:before": {
      flex: 0,
    },
  },
  timelineElement: {
    "&:hover": {
      boxShadow: theme.shadows[1],
    },
  },
}))

export default function RetreatHomePage() {
  let classes = useStyles()
  // Path and query params
  let dispatch = useDispatch()
  let [retreat, retreatIdx] = useRetreat()
  let [attendees] = useRetreatAttendees(retreat.id)

  let [datesOverview, setDatesOverview] = useState<string | undefined>(
    undefined
  )

  function getRegisteredAttendees(attendees: RetreatAttendeeModel[]) {
    return attendees.filter(
      (attendee) => attendee.info_status === "INFO_ENTERED"
    )
  }
  function getAttendeesToRegister(attendees: RetreatAttendeeModel[]) {
    return attendees.filter((attendee) => attendee.info_status === "CREATED")
  }

  useEffect(() => {
    if (
      Date.parse(retreat.lodging_final_start_date ?? "") &&
      Date.parse(retreat.lodging_final_end_date ?? "")
    ) {
      const formatter = Intl.DateTimeFormat("en-us", {
        dateStyle: "medium",
        timeStyle: undefined,
        timeZone: "UTC",
      })
      let startDateParts = formatter.format(
        new Date(retreat.lodging_final_start_date!)
      )
      let endDateParts = formatter.format(
        new Date(retreat.lodging_final_end_date!)
      )
      setDatesOverview(`${startDateParts} - ${endDateParts}`)
    } else {
      const DEFAULT_DATES = "Dates: TBD"
      setDatesOverview(DEFAULT_DATES)
    }
  }, [
    setDatesOverview,
    retreat.lodging_final_end_date,
    retreat.lodging_final_start_date,
  ])

  let [destinationOverview, setDestinationOverview] = useState<
    string | undefined
  >(retreat.lodging_final_destination)
  useEffect(() => {
    setDestinationOverview(retreat.lodging_final_destination)
  }, [retreat.lodging_final_destination])

  let numBookedFlights = attendees
    .filter((attendee) => ["INFO_ENTERED"].includes(attendee.info_status))
    .filter(
      (attendee) =>
        attendee.flight_status &&
        ["OPT_OUT", "BOOKED"].includes(attendee.flight_status)
    ).length

  let [lodgingOverview, setLodgingOverview] = useState<string | undefined>(
    undefined
  )
  let selectedHotel = useSelector((state: RootState) => {
    if (retreat.lodging_final_hotel_id != null) {
      return state.lodging.hotels[retreat.lodging_final_hotel_id]
    }
  })
  useEffect(() => {
    if (
      retreat.lodging_final_hotel_id != null &&
      (!selectedHotel || selectedHotel.id !== retreat.lodging_final_hotel_id)
    ) {
      dispatch(getHotels([retreat.lodging_final_hotel_id]))
    }
  }, [retreat.lodging_final_hotel_id, dispatch, selectedHotel])
  useEffect(() => {
    if (selectedHotel) {
      setLodgingOverview(selectedHotel.name)
    } else {
      setLodgingOverview(undefined)
    }
  }, [setLodgingOverview, selectedHotel])

  const PlanningItems: {
    title: string
    explanation: string
    redirectPage: FlokPageName
    completed: boolean
  }[] = [
    {
      title: "Complete Event Profile Form",
      explanation: "Help us get to know you",
      redirectPage: "RetreatRfpPage",
      completed: !!retreat.request_for_proposal_id,
    },
    {
      title: "Review Hotel Proposals",
      explanation: "Find the perfect venue",
      redirectPage: "RetreatLodgingProposalsPage",
      completed: retreat.lodging_final_hotel_id != null,
    },
    {
      title: "Sign Hotel Contract",
      explanation: "Make it official",
      redirectPage: "RetreatLodgingContractPage",
      completed: retreat.lodging_final_hotel_id != null,
    },
    {
      title: "Create Event Website",
      explanation: "Become a developer",
      redirectPage: "LandingPageGeneratorHome",
      completed: !!retreat.attendees_website_id,
    },
    {
      title: "Set Flight Policies",
      explanation: "Create budgets, rules, and instructions",
      redirectPage: "RetreatFlightsOptionsPage",
      completed: !!retreat.flights_page_id,
    },
    {
      title: "Create Attendee Registration Form",
      explanation: "What do we need to know about everyone?",
      redirectPage: "RetreatAttendeesRegFormBuilderPage",
      completed: !!retreat.attendees_registration_form_id,
    },
    {
      title: "Add Attendees to Event",
      explanation:
        getRegisteredAttendees(attendees).length +
          getAttendeesToRegister(attendees).length ===
        0
          ? "Add employees"
          : `Add employees (currently ${
              getRegisteredAttendees(attendees).length +
              getAttendeesToRegister(attendees).length
            } added)`,
      redirectPage: "RetreatAttendeesPage",
      completed:
        getRegisteredAttendees(attendees).length +
          getAttendeesToRegister(attendees).length >=
        5,
    },
    {
      title: "Invite Attendees to the Event!",
      explanation: "Send out the emails",
      redirectPage: "RetreatAttendeesPage",
      completed: retreat.registration_live,
    },
    {
      title: "Create your Itinerary",
      explanation: "Plan activities",
      redirectPage: "RetreatItineraryBuilderPage",
      completed: !!retreat.itinerary_id,
    },
    {
      title: "Add Itinerary to Event Website",
      explanation: "Give attendees access to the schedule",
      redirectPage: "RetreatItineraryBuilderConfig",
      completed: !!retreat.show_itinerary_on_landing,
    },
  ]

  return (
    <PageBody appBar>
      <div className={classes.section}>
        <div className={classes.overviewHeader}>
          <Typography variant="h1">Overview</Typography>
          <div className={classes.retreatNameLine}>
            <Typography variant="body1">{getRetreatName(retreat)}</Typography>
            &nbsp;
            <IconButton
              size="small"
              component={ReactRouterLink}
              to={AppRoutes.getPath("RetreatSettingsPage", {
                retreatIdx: retreatIdx.toString(),
              })}>
              <Settings />
            </IconButton>
          </div>
          <Typography variant="body1">{datesOverview}</Typography>
        </div>
        <AppOverviewCardList>
          <AppOverviewCard
            label="Destination"
            Icon={Room}
            value={destinationOverview}
          />
          <AppOverviewCard
            label="Lodging"
            Icon={Apartment}
            value={lodgingOverview}
          />
          <AppOverviewCard
            label="Attendees"
            Icon={People}
            value={
              getRegisteredAttendees(attendees).length +
                getAttendeesToRegister(attendees).length >
              0
                ? `${getRegisteredAttendees(attendees).length.toString()} / ${
                    getRegisteredAttendees(attendees).length +
                    getAttendeesToRegister(attendees).length
                  }`
                : "-- / --"
            }
            moreInfo={"# of attendees successfully registered for your retreat"}
          />
          <AppOverviewCard
            label="Flights"
            Icon={Flight}
            value={
              getRegisteredAttendees(attendees).length
                ? `${numBookedFlights} / ${getRegisteredAttendees(
                    attendees
                  ).length.toString()}`
                : "-- / --"
            }
            moreInfo={
              "# of attendees who've confirmed their flights for your retreat"
            }
          />
        </AppOverviewCardList>
      </div>
      <div className={classes.section}>
        <div>
          <AppTypography variant="h2">Planning Process</AppTypography>
          <Timeline align="left" className={classes.timeline}>
            {PlanningItems.map((item, i) => {
              return (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color={item.completed ? "primary" : "grey"}>
                      {item.completed ? (
                        <Done />
                      ) : (
                        <Box
                          height={"24px"}
                          width={"24px"}
                          alignItems="center"
                          justifyContent={"center"}
                          display="flex">
                          {i + 1}
                        </Box>
                      )}
                    </TimelineDot>
                    {i !== PlanningItems.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    {item.completed ? (
                      <Box pt={1}>
                        <Link
                          component={RouterLink}
                          to={AppRoutes.getPath(item.redirectPage, {
                            retreatIdx: retreatIdx.toString(),
                          })}
                          underline="hover"
                          color="inherit">
                          <Typography variant="h4">{item.title}</Typography>
                        </Link>
                      </Box>
                    ) : (
                      <Link
                        underline="none"
                        component={RouterLink}
                        to={AppRoutes.getPath(item.redirectPage, {
                          retreatIdx: retreatIdx.toString(),
                        })}>
                        <Paper
                          className={classes.timelineElement}
                          variant="outlined"
                          style={{
                            padding: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            dispatch(
                              push(
                                AppRoutes.getPath(item.redirectPage, {
                                  retreatIdx: retreatIdx.toString(),
                                })
                              )
                            )
                          }}>
                          <Typography variant="h4">{item.title}</Typography>
                          {!item.completed && (
                            <Typography variant="body1" color="textSecondary">
                              {item.explanation}
                            </Typography>
                          )}
                        </Paper>
                      </Link>
                    )}
                  </TimelineContent>
                </TimelineItem>
              )
            })}
          </Timeline>
        </div>
      </div>
    </PageBody>
  )
}
// function setTodoTasksExtra(arg0: any[]) {
//   throw new Error("Function not implemented.")
// }

// function setTodoTasks(
//   tasks_todo: import("../../models/retreat").RetreatToTask[]
// ) {
//   throw new Error("Function not implemented.")
// }
