import {Action} from "redux"
import {
  NotificationModel,
  ResourceNotFound,
  ResourceNotFoundType,
} from "../../models"
import {
  AttendeeApiResponse,
  AttendeeLandingWebsiteApiResponse,
  AttendeeLandingWebsitePageApiResponse,
  AttendeeLandingWebsitePageBlockApiResponse,
  PresetImagesApiResponse,
  RetreatAttendeesApiResponse,
  TripApiResponse,
} from "../../models/api"
import {
  AttendeeLandingWebsiteBlockModel,
  AttendeeLandingWebsiteModel,
  AttendeeLandingWebsitePageModel,
  CommentModel,
  CommentThreadApiModel,
  CommentThreadModel,
  EmailTemplateModel,
  FileModel,
  HotelGroup,
  ItineraryEventModel,
  ItineraryModel,
  PresetImageModel,
  PresetImageType,
  RetreatAttendeeModel,
  RetreatModel,
  RetreatTripModel,
  RFPModel,
} from "../../models/retreat"
import {LabelModel, LabelToTaskModel, TaskModel} from "../../models/task"
import {UserModel} from "../../models/user"
import {ApiAction} from "../actions/api"
import {DELETE_SELECTED_HOTEL_SUCCESS} from "../actions/lodging"
import {
  DELETE_BLOCK_SUCCESS,
  DELETE_FILE_TO_EVENT_SUCCESS,
  DELETE_ITINERARY_EVENT_SUCCESS,
  DELETE_PAGE_SUCCESS,
  DELETE_RECEIPT_TO_ATTENDEE_SUCCESS,
  DELETE_RETREAT_ATTENDEES_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_ATTENDEE_SUCCESS,
  GET_BLOCK_SUCCESS,
  GET_COMMENT_SUCCESS,
  GET_COMMENT_THREAD_SUCCESS,
  GET_EMAIL_TEMPLATE_SUCCESS,
  GET_HOTEL_GROUP_SUCCESS,
  GET_ITINERARY_EVENT_SUCCESS,
  GET_ITINERARY_SUCCESS,
  GET_MY_ATTENDEE_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_PAGE_SUCCESS,
  GET_PRESET_IMAGES_SUCCESS,
  GET_RETREAT_ATTENDEES_SUCCESS,
  GET_RETREAT_BY_GUID_FAILURE,
  GET_RETREAT_BY_GUID_SUCCESS,
  GET_RETREAT_SUCCESS,
  GET_RFP_SUCCESS,
  GET_TRIPS_SUCCESS,
  GET_TRIP_SUCCESS,
  GET_USER_SUCCESS,
  GET_WEBSITE_BY_ATTENDEE_SUCCESS,
  GET_WEBSITE_SUCCESS,
  INSTANTIATE_ATTENDEE_TRIPS_SUCCESS,
  PATCH_ATTENDEE_SUCCESS,
  PATCH_ATTENDEE_TRAVEL_SUCCESS,
  PATCH_BLOCK_SUCCESS,
  PATCH_EMAIL_TEMPLATE_SUCCESS,
  PATCH_ITINERARY_EVENT_SUCCESS,
  PATCH_ITINERARY_SUCCESS,
  PATCH_PAGE_SUCCESS,
  PATCH_RETREAT_SUCCESS,
  PATCH_TRIP_SUCCESS,
  PATCH_WEBSITE_SUCCESS,
  POST_ATTENDEE_REG_SUCCESS,
  POST_BLOCK_SUCCESS,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_THREAD_SUCCESS,
  POST_EMAIL_TEMPLATE_SUCCESS,
  POST_FLIGHTS_LIVE_SUCCESS,
  POST_INITIAL_WEBSITE_SUCCESS,
  POST_ITINERARY_EVENT_SUCCESS,
  POST_ITINERARY_SUCCESS,
  POST_PAGE_SUCCESS,
  POST_REGISTRATION_LIVE_SUCCESS,
  POST_RETREAT_ATTENDEES_BATCH_SUCCESS,
  POST_RETREAT_ATTENDEES_SUCCESS,
  POST_RFP_SUCCESS,
  POST_SELECTED_HOTEL_SUCCESS,
  POST_TEMPLATED_PAGE_SUCCESS,
  POST_USER_SUCCESS,
  PUT_RETREAT_PREFERENCES_SUCCESS,
  PUT_RETREAT_TASK_SUCCESS,
  SEE_NOTIFICATION_SUCCESS,
} from "../actions/retreat"
import {
  DELETE_ASSIGNEE_TO_TASK_SUCCESS,
  DELETE_FILE_TO_TASK_SUCCESS,
  DELETE_LABEL_TO_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  GET_LABEL_SUCCESS,
  GET_TASK_SUCCESS,
  PATCH_TASK_SUCCESS,
  POST_ASSIGNEE_TO_TASK_SUCCESS,
  POST_LABEL_SUCCESS,
  POST_LABEL_TO_TASK_SUCCESS,
  POST_TASK_SUCCESS,
} from "../actions/task"

export type RetreatState = {
  retreats: {
    [id: number]: RetreatModel | undefined
  }
  retreatsByGuid: {
    [guid: string]: RetreatModel | ResourceNotFoundType | undefined
  }
  retreatAttendees: {[id: number]: number[] | undefined}
  attendees: {
    [id: number]: RetreatAttendeeModel
  }
  trips: {
    [id: number]: RetreatTripModel
  }
  websites: {
    [id: number]: AttendeeLandingWebsiteModel | undefined
  }
  pages: {
    [id: number]: AttendeeLandingWebsitePageModel | undefined
  }
  blocks: {
    [id: number]: AttendeeLandingWebsiteBlockModel | undefined
  }
  presetImages: {
    BANNER: PresetImageModel[]
  }
  users: {
    [id: number]: UserModel | undefined
  }
  RFPs: {
    [id: number]: RFPModel | undefined
  }
  hotelGroups: {
    [id: number]: HotelGroup | undefined
  }
  emailTemplates: {
    [id: number]: EmailTemplateModel | undefined
  }
  itineraries: {
    [id: number]: ItineraryModel | undefined
  }
  itineraryEvents: {
    [id: number]: ItineraryEventModel | undefined
  }
  comments: {
    [id: number]: CommentModel | undefined
  }
  commentThreads: {
    [id: number]: CommentThreadModel | undefined
  }
  labels: {
    [id: number]: LabelModel | undefined
  }
  tasks: {
    [id: number]: TaskModel | undefined
  }
  notifications: {
    [id: number]: NotificationModel | undefined
  }
  notificationsHasNext: boolean
  unreadNotificationTotal: number
}

const initialState: RetreatState = {
  retreats: {},
  retreatsByGuid: {},
  retreatAttendees: {},
  attendees: {},
  trips: {},
  websites: {},
  pages: {},
  blocks: {},
  presetImages: {
    BANNER: [],
  },
  users: {},
  RFPs: {},
  hotelGroups: {},
  emailTemplates: {},
  itineraries: {},
  itineraryEvents: {},
  labels: {},
  tasks: {},
  comments: {},
  commentThreads: {},
  notifications: {},
  notificationsHasNext: false,
  unreadNotificationTotal: 0,
}

export default function retreatReducer(
  state: RetreatState = initialState,
  action: Action
): RetreatState {
  var payload
  var retreatId: number, retreat: RetreatModel
  var fileId: number
  var taskId: number
  var labelId: number
  var files: FileModel[]
  var updatedRetreat: RetreatModel
  switch (action.type) {
    case GET_RETREAT_BY_GUID_SUCCESS: // TODO, remove once dashboard release
    case GET_RETREAT_SUCCESS:
    case PUT_RETREAT_PREFERENCES_SUCCESS:
    case PUT_RETREAT_TASK_SUCCESS:
    case POST_SELECTED_HOTEL_SUCCESS:
    case POST_REGISTRATION_LIVE_SUCCESS:
    case POST_FLIGHTS_LIVE_SUCCESS:
    case PATCH_RETREAT_SUCCESS:
    case DELETE_SELECTED_HOTEL_SUCCESS:
      retreat = ((action as ApiAction).payload as {retreat: RetreatModel})
        .retreat
      retreatId = retreat.id
      return {
        ...state,
        retreats: {...state.retreats, [retreatId]: retreat},
        retreatsByGuid: {...state.retreatsByGuid, [retreat.guid]: retreat}, // TODO, remove once dashboard release
      }
    // TODO, remove once dashboard release
    case GET_RETREAT_BY_GUID_FAILURE:
      let retreatGuid = (action as unknown as {meta: {retreatGuid: string}})
        .meta.retreatGuid
      return {
        ...state,
        retreatsByGuid: {
          ...state.retreatsByGuid,
          [retreatGuid]: ResourceNotFound,
        },
      }
    case POST_RETREAT_ATTENDEES_SUCCESS:
    case DELETE_RETREAT_ATTENDEES_SUCCESS:
    case GET_RETREAT_ATTENDEES_SUCCESS:
      retreatId = (action as unknown as {meta: {retreatId: number}}).meta
        .retreatId
      payload = (action as ApiAction).payload as RetreatAttendeesApiResponse
      if (payload) {
        state.retreatAttendees = {
          ...state.retreatAttendees,
          [retreatId]: payload.attendees.map((attendee) => attendee.id),
        }
        state.attendees = payload.attendees.reduce(
          (last: any, curr: RetreatAttendeeModel) => {
            return {...last, [curr.id]: curr}
          },
          {}
        )
      }
      return state
    case GET_ATTENDEE_SUCCESS:
    case PATCH_ATTENDEE_SUCCESS:
    case PATCH_ATTENDEE_TRAVEL_SUCCESS:
    case GET_MY_ATTENDEE_SUCCESS:
    case POST_ATTENDEE_REG_SUCCESS:
      payload = (action as ApiAction).payload as AttendeeApiResponse
      if (payload) {
        state.attendees = {
          ...state.attendees,
          [payload.attendee.id]: payload.attendee,
        }
      }
      return state

    case PATCH_TRIP_SUCCESS:
    case GET_TRIP_SUCCESS:
      payload = (action as ApiAction).payload as TripApiResponse
      if (payload) {
        state.trips = {
          ...state.trips,
          [payload.trip.id]: payload.trip,
        }
      }
      return state
    case GET_TRIPS_SUCCESS:
      payload = (action as ApiAction).payload as {trips: RetreatTripModel[]}
      return {
        ...state,
        trips: {
          ...state.trips,
          ...payload.trips.reduce(
            (prev, curr) => ({...prev, [curr.id]: curr}),
            {}
          ),
        },
      }
    case INSTANTIATE_ATTENDEE_TRIPS_SUCCESS:
      payload = (action as ApiAction).payload as AttendeeApiResponse
      if (payload) {
        state.attendees = {
          ...state.attendees,
          [payload.attendee.id]: payload.attendee,
        }
        if (
          payload &&
          payload.attendee.travel?.arr_trip?.id &&
          payload.attendee.travel?.dep_trip?.id
        ) {
          state.trips = {
            ...state.trips,
            [payload.attendee.travel.arr_trip.id]:
              payload.attendee.travel.arr_trip,
            [payload.attendee.travel.dep_trip.id]:
              payload.attendee.travel.dep_trip,
          }
        }
      }
      return state
    case GET_WEBSITE_SUCCESS:
    case GET_WEBSITE_BY_ATTENDEE_SUCCESS:
    case PATCH_WEBSITE_SUCCESS:
    case POST_INITIAL_WEBSITE_SUCCESS:
      payload = (action as ApiAction)
        .payload as AttendeeLandingWebsiteApiResponse
      return {
        ...state,
        websites: {
          ...state.websites,
          [payload.website.id]: payload.website,
        },
        retreats: {
          ...state.retreats,
          ...(state.retreats[payload.website.retreat_id]
            ? {
                [payload.website.retreat_id]: {
                  ...(state.retreats[
                    payload.website.retreat_id
                  ] as RetreatModel),
                  attendees_website_id: payload.website.id,
                },
              }
            : {}),
        },
      }
    case GET_PAGE_SUCCESS:
    case PATCH_PAGE_SUCCESS:
      payload = (action as ApiAction)
        .payload as AttendeeLandingWebsitePageApiResponse
      return {
        ...state,
        pages: {...state.pages, [payload.page.id]: payload.page},
      }
    case GET_BLOCK_SUCCESS:
    case PATCH_BLOCK_SUCCESS:
      payload = (action as ApiAction)
        .payload as AttendeeLandingWebsitePageBlockApiResponse
      return {
        ...state,
        blocks: {...state.blocks, [payload.block.id]: payload.block},
      }
    case POST_BLOCK_SUCCESS:
      payload = (action as ApiAction)
        .payload as AttendeeLandingWebsitePageBlockApiResponse
      return {
        ...state,
        blocks: {...state.blocks, [payload.block.id]: payload.block},
        pages: {
          ...state.pages,
          [payload.block.page_id]: {
            ...state.pages[payload.block.page_id]!,
            block_ids: [
              ...(state.pages[payload.block.page_id]
                ? state.pages[payload.block.page_id]!.block_ids
                : []),
              payload.block.id,
            ],
          },
        },
      }
    case DELETE_BLOCK_SUCCESS:
      let blockId = (action as unknown as {meta: {blockId: number}}).meta
        .blockId
      return {
        ...state,
        blocks: {
          ...Object.keys(state.blocks).reduce(
            (prev, curr) => ({
              ...prev,
              ...(parseInt(curr) !== blockId
                ? {[curr]: state.blocks[parseInt(curr)]}
                : {}),
            }),
            {}
          ),
        },
        pages: {
          ...Object.values(state.pages)
            .map((page) => ({
              ...page!,
              block_ids: page!.block_ids.filter((_id) => _id !== blockId),
            }))
            .reduce((prev, curr) => ({...prev, [curr.id]: curr}), {}),
        },
      }
    case POST_PAGE_SUCCESS:
      payload = (action as ApiAction)
        .payload as AttendeeLandingWebsitePageApiResponse
      return {
        ...state,
        pages: {...state.pages, [payload.page.id]: payload.page},
        websites: {
          ...state.websites,
          [payload.page.website_id]: {
            ...state.websites[payload.page.website_id]!,
            page_ids: [
              ...(state.websites[payload.page.website_id]
                ? state.websites[payload.page.website_id]!.page_ids
                : []),
              payload.page.id,
            ],
          },
        },
      }
    case POST_TEMPLATED_PAGE_SUCCESS:
      payload = (action as ApiAction)
        .payload as AttendeeLandingWebsitePageApiResponse
      let meta = action as unknown as {
        meta: {
          type: "FLIGHTS"
          retreatId: number | undefined
        }
      }
      let oldRetreat =
        state.retreats[meta.meta.retreatId ? meta.meta.retreatId : -1]
      let newRetreats = {...state.retreats}
      if (oldRetreat) {
        let newRetreat = {...oldRetreat, flights_page_id: payload.page.id}
        newRetreats[newRetreat.id] = newRetreat
      }
      return {
        ...state,
        pages: {...state.pages, [payload.page.id]: payload.page},
        websites: {
          ...state.websites,
          [payload.page.website_id]: {
            ...state.websites[payload.page.website_id]!,
            page_ids: [
              ...(state.websites[payload.page.website_id]
                ? state.websites[payload.page.website_id]!.page_ids
                : []),
              payload.page.id,
            ],
          },
        },
        retreats: newRetreats,
      }
    case "ADD_RECEIPT_TO_ATTENDEE":
      payload = action as {
        type: "ADD_RECEIPT_TO_ATTENDEE"
        receipt: FileModel
        attendee_id: number
      }

      return {
        ...state,
        attendees: {
          ...state.attendees,
          [payload.attendee_id]: {
            ...state.attendees[payload.attendee_id],
            receipts: [
              ...state.attendees[payload.attendee_id].receipts,
              payload.receipt,
            ],
          },
        },
      }
    case DELETE_RECEIPT_TO_ATTENDEE_SUCCESS:
      let metaResponse = action as unknown as {
        meta: {
          attendeeId: number
          receiptId: number
        }
      }
      let newReceipts = [
        ...state.attendees[metaResponse.meta.attendeeId].receipts,
      ]
      let indexOfReceipt = newReceipts.findIndex((file) => {
        return file.id === metaResponse.meta.receiptId
      })
      if (indexOfReceipt !== -1) {
        newReceipts.splice(indexOfReceipt, 1)
      }
      return {
        ...state,
        attendees: {
          ...state.attendees,
          [metaResponse.meta.attendeeId]: {
            ...state.attendees[metaResponse.meta.attendeeId],
            receipts: newReceipts,
          },
        },
      }

    case DELETE_PAGE_SUCCESS:
      const pageId = (action as unknown as {meta: {pageId: number}}).meta.pageId
      let websiteId = Object.values(state.websites).find((website) =>
        website?.page_ids.includes(pageId)
      )?.id
      let newPages = {...state.pages}
      delete newPages[pageId]
      return {
        ...state,
        websites: {
          ...state.websites,
          [websiteId!]: {
            ...state.websites[websiteId!]!,
            page_ids: [
              ...state.websites[websiteId!]!.page_ids.filter(
                (id) => id !== pageId
              ),
            ],
          },
        },
        pages: newPages,
      }
    case POST_RETREAT_ATTENDEES_BATCH_SUCCESS:
      retreatId = (action as unknown as {meta: {retreatId: number}}).meta
        .retreatId
      payload = (action as ApiAction).payload as RetreatAttendeesApiResponse
      let newState = {...state}
      if (payload) {
        newState.retreatAttendees = {
          ...state.retreatAttendees,
          [retreatId]: [
            ...(state.retreatAttendees[retreatId] !== undefined
              ? state.retreatAttendees[retreatId]!
              : []),
            ...payload.attendees.map((attendee) => attendee.id),
          ],
        }
        newState.attendees = {
          ...state.attendees,
          ...payload.attendees.reduce(
            (last: any, curr: RetreatAttendeeModel) => {
              return {...last, [curr.id]: curr}
            },
            {}
          ),
        }
      }
      return newState
    case GET_HOTEL_GROUP_SUCCESS:
      action = action as unknown as ApiAction
      payload = (action as unknown as ApiAction).payload as {
        group: HotelGroup
      }
      return {
        ...state,
        hotelGroups: {
          ...state.hotelGroups,
          [payload.group.id]: payload.group,
        },
      }
    case GET_LABEL_SUCCESS:
      payload = (action as unknown as ApiAction).payload as {
        label: LabelModel
      }
      return {
        ...state,
        labels: {
          ...state.labels,
          [payload.label.id]: payload.label,
        },
      }
    case GET_PRESET_IMAGES_SUCCESS:
      let type = (action as unknown as {meta: {type: PresetImageType}}).meta
        .type
      payload = (action as ApiAction).payload as PresetImagesApiResponse
      let newPresetState = {...state}
      if (payload) {
        newPresetState = {
          ...newPresetState,
          presetImages: {[type]: payload.preset_images},
        }
      }
      return newPresetState
    case GET_USER_SUCCESS:
      payload = (action as unknown as ApiAction).payload as {
        user: UserModel
      }
      return {
        ...state,
        users: {
          ...state.users,
          [payload.user.id]: payload.user,
        },
      }
    case POST_USER_SUCCESS:
      payload = (action as unknown as ApiAction).payload as {
        user: UserModel
      }
      retreatId = (action as unknown as {meta: {retreatId: number}}).meta
        .retreatId

      let allRetreats = {...state.retreats}
      let currentRetreat = allRetreats[retreatId]
      if (currentRetreat) {
        currentRetreat.users = [...currentRetreat.users, payload.user.id]
      }
      return {
        ...state,
        users: {
          ...state.users,
          [payload.user.id]: payload.user,
        },
        retreats: {
          [retreatId]: currentRetreat,
        },
      }
    case DELETE_USER_SUCCESS:
      let userId = (
        action as unknown as {meta: {userId: number; retreatId: number}}
      ).meta.userId
      let updatedUser = {...state.users[userId]}
      let currentRetreatId = (
        action as unknown as {meta: {userId: number; retreatId: number}}
      ).meta.retreatId

      let indexOfRetreatId =
        updatedUser && updatedUser.retreat_ids
          ? updatedUser.retreat_ids.indexOf(currentRetreatId)
          : -1
      if (indexOfRetreatId !== -1 && updatedUser.retreat_ids) {
        updatedUser.retreat_ids.splice(indexOfRetreatId, 1)
      }
      let retreats = {...state.retreats}
      updatedRetreat = retreats[currentRetreatId] as RetreatModel
      if (updatedRetreat) {
        let indexOfUserId = updatedRetreat.users.indexOf(userId)
        if (indexOfUserId !== -1) {
          updatedRetreat.users.splice(indexOfUserId, 1)
        }
      }
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: updatedUser as UserModel,
        },
        retreats: {
          ...state.retreats,
          [currentRetreatId]: updatedRetreat,
        },
      }

    case POST_RFP_SUCCESS:
    case GET_RFP_SUCCESS:
      payload = (action as ApiAction).payload as {
        request_for_proposal: RFPModel
      }
      let newRFPState = {...state}

      newRFPState.RFPs = {
        ...newRFPState.RFPs,
        [payload.request_for_proposal.id]: payload.request_for_proposal,
      }
      return newRFPState
    case POST_EMAIL_TEMPLATE_SUCCESS:
    case GET_EMAIL_TEMPLATE_SUCCESS:
    case PATCH_EMAIL_TEMPLATE_SUCCESS:
      payload = (action as ApiAction).payload as {
        email_template: EmailTemplateModel
      }
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          [payload.email_template.id]: payload.email_template,
        },
      }

    case "ADD_FILE_TO_EVENT":
      payload = action as {
        type: "ADD_FILE_TO_EVENT"
        file: FileModel
        event_id: number
      }

      return {
        ...state,
        itineraryEvents: {
          ...state.itineraryEvents,
          [payload.event_id]: {
            ...state.itineraryEvents[payload.event_id],
            files: [
              ...(state.itineraryEvents[payload.event_id]
                ?.files as FileModel[]),
              payload.file,
            ],
          } as ItineraryEventModel,
        },
      }
    case GET_ITINERARY_SUCCESS:
    case POST_ITINERARY_SUCCESS:
    case PATCH_ITINERARY_SUCCESS:
      payload = (action as ApiAction).payload as {
        itinerary: ItineraryModel
      }
      let newItineraryRetreat = state.retreats[payload.itinerary.retreat_id]
      return {
        ...state,
        itineraries: {
          ...state.itineraries,
          [payload.itinerary.id]: payload.itinerary,
        },
        retreats: {
          ...state.retreats,
          [payload.itinerary.retreat_id]: newItineraryRetreat
            ? {...newItineraryRetreat, itinerary_id: payload.itinerary.id}
            : undefined,
        },
      }
    case POST_ITINERARY_EVENT_SUCCESS:
      payload = (action as ApiAction).payload as {
        event: ItineraryEventModel
      }
      let itineraryId = payload.event.itinerary_id

      return {
        ...state,
        itineraryEvents: {
          ...state.itineraryEvents,
          [payload.event.id]: payload.event,
        },
        itineraries: {
          ...state.itineraries,
          [itineraryId]: {
            ...state.itineraries[itineraryId],
            event_ids: [
              ...(state.itineraries[itineraryId]?.event_ids as number[]),
              payload.event.id,
            ],
          } as ItineraryModel,
        },
      }
    case GET_ITINERARY_EVENT_SUCCESS:
    case PATCH_ITINERARY_EVENT_SUCCESS:
      payload = (action as ApiAction).payload as {
        event: ItineraryEventModel
      }
      return {
        ...state,
        itineraryEvents: {
          ...state.itineraryEvents,
          [payload.event.id]: payload.event,
        },
      }
    case DELETE_FILE_TO_EVENT_SUCCESS:
      fileId = (action as unknown as {meta: {eventId: number; fileId: number}})
        .meta.fileId
      let eventId = (
        action as unknown as {meta: {eventId: number; fileId: number}}
      ).meta.eventId
      files = state.itineraryEvents[eventId]?.files as FileModel[]
      let indexOfFile = files.findIndex((file) => file.id === fileId)
      files.splice(indexOfFile, 1)
      return {
        ...state,
        itineraryEvents: {
          ...state.itineraryEvents,
          [eventId]: {
            ...state.itineraryEvents[eventId],
            files: files,
          } as ItineraryEventModel,
        },
      }
    case DELETE_ITINERARY_EVENT_SUCCESS:
      let iEventId = (
        action as unknown as {meta: {eventId: number; itineraryId: number}}
      ).meta.eventId
      let events = {...state.itineraryEvents}
      delete events[iEventId]
      return {
        ...state,
        itineraries: Object.fromEntries(
          Object.entries(state.itineraries).map(([itineraryId, itinerary]) => {
            let events = itinerary?.event_ids.filter(
              (id) => id !== iEventId
            ) as number[]
            let updatedItinerary = {...itinerary, event_ids: events}
            return [itineraryId, updatedItinerary as ItineraryModel]
          })
        ),
        itineraryEvents: events,
      }
    case POST_COMMENT_THREAD_SUCCESS:
    case GET_COMMENT_THREAD_SUCCESS:
      payload = (action as ApiAction).payload as {
        comment_thread: CommentThreadApiModel
      }
      let comments = [...payload.comment_thread.comments]
      let commentMap: {[id: number]: CommentModel} = {}
      comments.forEach((comment) => {
        commentMap[comment.id] = comment
      })
      return {
        ...state,
        commentThreads: {
          ...state.commentThreads,
          [payload.comment_thread.id]: {
            ...payload.comment_thread,
            comment_ids: [
              ...payload.comment_thread.comments.map((comment) => comment.id),
            ],
          },
        },
        comments: {
          ...state.comments,
          ...commentMap,
        },
      }
    case POST_COMMENT_SUCCESS:
      payload = (action as ApiAction).payload as {
        comment: CommentModel
      }
      return {
        ...state,
        commentThreads: {
          ...state.commentThreads,
          [payload.comment.comment_thread_id]: {
            ...state.commentThreads[payload.comment.comment_thread_id],
            comment_ids: [
              ...(state.commentThreads[payload.comment.comment_thread_id]
                ?.comment_ids as number[]),
              payload.comment.id,
            ],
          } as CommentThreadModel,
        },
        comments: {
          ...state.comments,
          [payload.comment.id]: payload.comment,
        },
      }
    case GET_COMMENT_SUCCESS:
      payload = (action as ApiAction).payload as {
        comment: CommentModel
      }
      return {
        ...state,
        comments: {
          ...state.comments,
          [payload.comment.id]: payload.comment,
        },
      }
    case "ADD_FILE_TO_TASK":
      payload = action as {
        type: "ADD_FILE_TO_TASK"
        file: FileModel
        task_id: number
      }

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [payload.task_id]: {
            ...state.tasks[payload.task_id],
            files: [
              ...(state.tasks[payload.task_id]?.files as FileModel[]),
              payload.file,
            ],
          } as TaskModel,
        },
      }
    case "ADD_ASSIGNEE_TO_TASK":
      payload = action as {
        type: "ADD_ASSIGNEE_TO_TASK"
        assignee: number
        task_id: number
      }

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [payload.task_id]: {
            ...state.tasks[payload.task_id],
            assignees: [
              ...(state.tasks[payload.task_id]?.assignees as number[]),
              payload.assignee,
            ],
          } as TaskModel,
        },
      }
    case POST_TASK_SUCCESS:
      payload = (action as ApiAction).payload as {
        task: TaskModel
      }
      updatedRetreat = state.retreats[payload.task.retreat_id] as RetreatModel

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [payload.task.id]: payload.task,
        },
        retreats: {
          ...state.retreats,
          [payload.task.retreat_id]: updatedRetreat
            ? ({
                ...updatedRetreat,
                task_ids: [...updatedRetreat.task_ids, payload.task.id],
              } as RetreatModel)
            : ({...state.retreats[payload.task.retreat_id]} as RetreatModel),
        },
      }

    case GET_TASK_SUCCESS:
    case PATCH_TASK_SUCCESS:
    case POST_ASSIGNEE_TO_TASK_SUCCESS:
    case DELETE_ASSIGNEE_TO_TASK_SUCCESS:
      payload = (action as ApiAction).payload as {
        task: TaskModel
      }
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [payload.task.id]: payload.task,
        },
      }
    case POST_LABEL_TO_TASK_SUCCESS:
      payload = (action as ApiAction).payload as {
        label_to_task: LabelToTaskModel
      }

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [payload.label_to_task.task_id]: {
            ...state.tasks[payload.label_to_task.task_id],
            labels: [
              ...(state.tasks[payload.label_to_task.task_id]
                ?.labels as number[]),
              payload.label_to_task.label_id,
            ],
          },
        } as TaskModel,
      }
    case DELETE_LABEL_TO_TASK_SUCCESS:
      labelId = (action as unknown as {meta: {taskId: number; labelId: number}})
        .meta.labelId
      taskId = (action as unknown as {meta: {taskId: number; labelId: number}})
        .meta.taskId
      let newLabels = [...(state.tasks[taskId]?.labels as number[])]
      newLabels.splice(
        newLabels.findIndex((label) => label === labelId),
        1
      )

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [taskId]: {
            ...state.tasks[taskId],
            labels: newLabels,
          } as TaskModel,
        },
      }
    case POST_LABEL_SUCCESS:
      payload = (action as ApiAction).payload as {
        label: LabelModel
      }
      let labeledRetreat: RetreatModel | undefined = state.retreats[
        payload.label.retreat_id
      ]
        ? ({
            ...state.retreats[payload.label.retreat_id],
            labels: [
              ...state.retreats[payload.label.retreat_id]!.labels,
              payload.label.id,
            ],
          } as RetreatModel)
        : undefined
      return {
        ...state,
        labels: {
          ...state.labels,
          [payload.label.id]: payload.label,
        },
        retreats: {
          ...state.retreats,
          [payload.label.retreat_id]: labeledRetreat,
        },
      }

    case DELETE_TASK_SUCCESS:
      taskId = (
        action as unknown as {meta: {taskId: number; retreatId: number}}
      ).meta.taskId
      retreatId = (
        action as unknown as {meta: {taskId: number; retreatId: number}}
      ).meta.retreatId
      let newTasks = {...state.tasks}
      updatedRetreat = {...state.retreats[retreatId]} as RetreatModel

      delete newTasks[taskId]
      return {
        ...state,
        tasks: newTasks,
        retreats: {
          ...state.retreats,
          [retreatId]: {
            ...updatedRetreat,
            task_ids: [...updatedRetreat.task_ids].filter(
              (id) => id !== taskId
            ),
          },
        },
      }

    case DELETE_FILE_TO_TASK_SUCCESS:
      fileId = (action as unknown as {meta: {taskId: number; fileId: number}})
        .meta.fileId
      taskId = (action as unknown as {meta: {taskId: number; fileId: number}})
        .meta.taskId
      files = [...(state.tasks[taskId]?.files as FileModel[])]
      files.splice(
        files.findIndex((file) => file.id === fileId),
        1
      )
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [taskId]: {
            ...state.tasks[taskId],
            files: files,
          } as TaskModel,
        },
      }
    case GET_NOTIFICATIONS_SUCCESS:
      payload = (action as ApiAction).payload as {
        notifications: NotificationModel[]
        has_next: boolean
        total_unread: number
      }
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...Object.fromEntries(
            payload.notifications.map((notification) => [
              notification.id,
              notification,
            ])
          ),
        },
        unreadNotificationTotal: payload.total_unread,
        notificationsHasNext: payload.has_next,
      }
    case SEE_NOTIFICATION_SUCCESS:
      payload = (action as ApiAction).payload as {
        notification: NotificationModel
      }
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [payload.notification.id]: payload.notification,
        },
        unreadNotificationTotal: state.unreadNotificationTotal - 1,
      }
    default:
      return state
  }
}
