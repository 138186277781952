import {Box, Paper} from "@material-ui/core"
import {push} from "connected-react-router"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import {CalendarDayEventModel, ItineraryEventModel} from "../../models/retreat"
import LoadingPage from "../../pages/misc/LoadingPage"
import NotFound404Page from "../../pages/misc/NotFound404Page"
import {AppRoutes} from "../../Stack"
import {RootState} from "../../store"
import {getUserHome} from "../../store/actions/user"
import {replaceDashes} from "../../utils"
import {
  useAttendeeLandingWebsiteName,
  useItinerary,
  useItineraryEvents,
  useRetreat,
} from "../../utils/retreatUtils"
import CalendarDay, {CalendarHours} from "../itinerary/CalendarDay"
import {addToDate} from "../itinerary/ItineraryForm"
import PageBody from "../page/PageBody"
import PageContainer from "../page/PageContainer"
import AttendeeSite from "./website/AttendeeSite"

export function getEarliestTime(events: CalendarDayEventModel[]) {
  let earliestTime = 24
  events.forEach((event) => {
    if (event.start_time) {
      let date = new Date(`December 14, 2026 ${event.start_time}`)
      earliestTime = Math.min(earliestTime, date.getHours())
    }
  })
  return earliestTime
}

export default function AttendeeSiteItineraryPage() {
  let {params} = useRouteMatch<{websiteName: string}>()
  let websiteName = params.websiteName
  let [website, websiteLoading] = useAttendeeLandingWebsiteName(
    replaceDashes(websiteName)
  )
  let [retreat, retreatLoading] = useRetreat(website?.retreat_id ?? -1)
  // let [attendee] = useMyAttendee(retreat ? retreat.id : -1)
  // let page = useAttendeeLandingPage(
  //   retreat !== ResourceNotFound && retreat && retreat.flights_page_id
  //     ? retreat?.flights_page_id
  //     : -1
  // )
  let [itinerary] = useItinerary(retreat ? retreat.itinerary_id ?? -1 : -1)
  let [itineraryEvents] = useItineraryEvents(itinerary?.event_ids ?? [])
  let dispatch = useDispatch()
  let user = useSelector((state: RootState) => state.user.user)
  let loginStatus = useSelector((state: RootState) => state.user.loginStatus)
  let [loadingUser, setLoadingUser] = useState(true)
  useEffect(() => {
    async function gatherUser() {
      if (loginStatus === "UNKNOWN" || !user) {
        setLoadingUser(true)
        await dispatch(getUserHome())
        setLoadingUser(false)
      } else {
        setLoadingUser(false)
      }
    }
    gatherUser()
  }, [dispatch, loginStatus, user])

  let startHour = Math.min(
    getEarliestTime(
      Object.values(itineraryEvents).filter((event) => {
        return (
          itinerary &&
          event &&
          0 < event.day &&
          event.day <= itinerary.number_of_days
        )
      }) as ItineraryEventModel[]
    ),
    7
  )

  if (!loadingUser && loginStatus !== "LOGGED_IN") {
    dispatch(
      push(
        AppRoutes.getPath(
          "SigninPage",
          {
            websiteName: websiteName,
          },
          {
            next: encodeURIComponent(
              AppRoutes.getPath("AttendeeSiteFlightsPage", {
                websiteName: websiteName,
              })
            ),
            "login-type": "attendee",
          }
        )
      )
    )
  }

  return websiteLoading || retreatLoading || !retreat || loadingUser ? (
    <LoadingPage />
  ) : !website || !retreat ? (
    <NotFound404Page />
  ) : (
    <PageContainer>
      <PageBody>
        <AttendeeSite
          hideLogo
          retreat={retreat}
          website={website}
          activePage="itinerary"
          pageBody={
            // <div className={classes.pageBody}>
            <>
              <Box maxWidth={"100%"} overflow="scroll">
                <Box clone width="fit-content">
                  <Paper>
                    <Box display={"flex"} padding={3}>
                      <Box marginTop={"52px"}>
                        <CalendarHours startHour={startHour} />
                      </Box>

                      {[...new Array(itinerary?.number_of_days)].map((u, i) => {
                        return (
                          <CalendarDay
                            startHour={startHour}
                            viewer
                            width={230}
                            title={
                              itinerary && itinerary.start_date
                                ? new Intl.DateTimeFormat("en-US", {
                                    weekday: "long",
                                    day: "numeric",
                                    month: "long",
                                  }).format(
                                    new Date(
                                      addToDate(itinerary.start_date, i + 2)
                                    )
                                  )
                                : `Day ${i + 1}`
                            }
                            events={Object.values(itineraryEvents)
                              .filter((event) => event && event.day === i + 1)
                              .map((event) => {
                                return event as CalendarDayEventModel
                              })}
                          />
                        )
                      })}
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </>
            // {/* </div> */}
          }
        />
      </PageBody>
    </PageContainer>
  )
}
