import {
  Box,
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
} from "@material-ui/core"
import {useDispatch} from "react-redux"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {getRetreat} from "../../store/actions/retreat"
import {applyTaskTemplateGroup} from "../../store/actions/task"
import {useTaskTemplateGroups} from "../../utils/retreatUtils"
import AppTypography from "../base/AppTypography"

type TaskTemplateGroupMenuProps = {
  anchorEl: HTMLElement | null
  onClose: () => void
}
export default function TaskTemplateGroupMenu(
  props: TaskTemplateGroupMenuProps
) {
  let dispatch = useDispatch()
  let [taskTemplateGroups, loadingTaskTemplateGroups] = useTaskTemplateGroups()
  let [retreat] = useRetreat()
  return !loadingTaskTemplateGroups ? (
    <Menu
      open={props.anchorEl != null}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      getContentAnchorEl={null}
      anchorOrigin={{vertical: "bottom", horizontal: "left"}}>
      <ListSubheader>Add tasks from our templates</ListSubheader>
      {Object.values(taskTemplateGroups).map((templategroup) => {
        return (
          <Box key={templategroup.id}>
            <ListItem button>
              <ListItemText
                primary={
                  <AppTypography variant="inherit">
                    <AppTypography variant="inherit" fontWeight="bold">
                      {`${templategroup.title}`}{" "}
                    </AppTypography>
                    {`(${templategroup.tasks.length} tasks)`}
                  </AppTypography>
                }
                secondary={templategroup.description}
                onClick={async () => {
                  await dispatch(
                    applyTaskTemplateGroup(retreat.id, templategroup.id)
                  )
                  await dispatch(getRetreat(retreat.id))
                }}
              />
            </ListItem>
          </Box>
        )
      })}
    </Menu>
  ) : (
    <></>
  )
}
