import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
} from "@material-ui/core"
import {useFormik} from "formik"
import {useDispatch} from "react-redux"
import {RetreatModel} from "../../models/retreat"
import {ApiAction} from "../../store/actions/api"
import {patchItinerary, patchRetreat} from "../../store/actions/retreat"
import {FlokTheme} from "../../theme"
import {useItinerary} from "../../utils/retreatUtils"

export function addToDate(dateString: string, days: number) {
  // must pass in date in string format
  let date = new Date(dateString)
  date.setDate(date.getDate() + days)
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - offset * 60 * 1000)
  return date.toISOString().split("T")[0]
}

function differenceInDays(dateString1: string, dateString2: string) {
  // must pass in dates in string format
  let date1 = new Date(dateString1)
  let date2 = new Date(dateString2)
  let difference = date1.getTime() - date2.getTime()
  let differenceInDays = Math.ceil(difference / (1000 * 3600 * 24))
  return differenceInDays
}

let useStyles = makeStyles((theme: FlokTheme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
    gap: theme.spacing(3),
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  timesWrapper: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },
  submitButton: {
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  },
}))
export default function ItineraryForm(props: {
  itineraryId: number
  onSubmit: () => void
  retreat: RetreatModel
}) {
  let [itinerary] = useItinerary(props.itineraryId)
  let classes = useStyles()
  let dispatch = useDispatch()
  let formik = useFormik({
    initialValues: {
      number_of_days: itinerary?.number_of_days ?? 4,
      start_date: itinerary?.start_date,
      end_date: itinerary?.start_date
        ? addToDate(itinerary.start_date, itinerary?.number_of_days ?? 4)
        : undefined,
      show_itinerary_on_landing:
        props.retreat.show_itinerary_on_landing ?? false,
    },
    onSubmit: async (values) => {
      let retreatValues = {
        show_itinerary_on_landing: values.show_itinerary_on_landing,
      }
      let itineraryValues = {...values} as unknown as {
        number_of_days: number
        start_date: string
        end_date: string | undefined

        show_itinerary_on_landing: boolean | undefined
      }
      delete itineraryValues.show_itinerary_on_landing
      let response = (await dispatch(
        patchItinerary(itineraryValues, props.itineraryId)
      )) as unknown as ApiAction
      let retreatResponse = (await dispatch(
        patchRetreat(props.retreat.id, retreatValues)
      )) as unknown as ApiAction
      if (!response.error && !retreatResponse.error) {
        props.onSubmit()
      }
    },
    enableReinitialize: true,
  })

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <TextField
        type="number"
        fullWidth
        value={formik.values.number_of_days}
        id="number_of_days"
        label="Number of Days"
        onChange={(e) => {
          if (parseInt(e.target.value)) {
            // formik.setFieldValue("number_of_days", parseInt(e.target.value))
            dispatch(
              patchItinerary(
                {number_of_days: parseInt(e.target.value)},
                props.itineraryId
              )
            )
            if (formik.values.start_date) {
              formik.setFieldValue(
                "end_date",
                addToDate(formik.values.start_date, parseInt(e.target.value))
              )
            }
          } else {
            formik.setFieldValue("number_of_days", "")
            // dispatch(patchItinerary({number_of_days: 0}, props.itineraryId))
            if (formik.values.start_date) {
              formik.setFieldValue(
                "end_date",
                addToDate(formik.values.start_date, 1)
              )
            }
          }
        }}
        InputLabelProps={{shrink: true}}
      />
      <TextField
        type="date"
        fullWidth
        value={formik.values.start_date}
        id="start_date"
        label="Start Date"
        InputLabelProps={{shrink: true}}
        onChange={(e) => {
          if (e.target.value) {
            // formik.setFieldValue("start_date", e.target.value)
            dispatch(
              patchItinerary({start_date: e.target.value}, props.itineraryId)
            )
            if (formik.values.number_of_days) {
              formik.setFieldValue(
                "end_date",
                addToDate(e.target.value, formik.values.number_of_days)
              )
            }
          } else {
            // formik.setFieldValue("start_date", "")
            dispatch(patchItinerary({start_date: ""}, props.itineraryId))
            if (formik.values.start_date) {
              formik.setFieldValue(
                "end_date",
                addToDate(formik.values.start_date, 1)
              )
            }
          }
        }}
      />
      <TextField
        type="date"
        fullWidth
        value={formik.values.end_date}
        label="End Date"
        InputLabelProps={{shrink: true}}
        onChange={(e) => {
          if (e.target.value) {
            formik.setFieldValue("end_date", e.target.value)
            if (formik.values.start_date) {
              dispatch(
                patchItinerary(
                  {
                    number_of_days:
                      differenceInDays(
                        e.target.value,
                        formik.values.start_date
                      ) + 1,
                  },
                  props.itineraryId
                )
              )
            }
          } else {
            formik.setFieldValue("end_date", "")
          }
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={formik.values.show_itinerary_on_landing}
            onChange={(e, checked) => {
              dispatch(
                patchRetreat(props.retreat.id, {
                  show_itinerary_on_landing: checked,
                })
              )
            }}
            id="show_itinerary_on_landing"
            name={"Show on Attendee Website?"}
            color="primary"
          />
        }
        label={"Show on Attendee Website?"}
      />
    </form>
  )
}
