import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  Fade,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import {Add, CalendarToday, List, Tune} from "@material-ui/icons"
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab"
import {push} from "connected-react-router"
import {RawDraftContentState} from "draft-js"
import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import {getEarliestTime} from "../../components/attendee-site/AttendeeSiteItineraryPage"
import AppHeaderWithSettings from "../../components/base/AppHeaderWithSettings"
import AppTypography from "../../components/base/AppTypography"
import AppConfirmationModal from "../../components/base/ConfirmationModal"
import CommentThreads, {
  ThreadObject,
} from "../../components/comments/CommentThreads"
import CalendarDay, {
  CalendarHours,
} from "../../components/itinerary/CalendarDay"
import ItineraryEventDropDown from "../../components/itinerary/ItineraryEventDropdown"
import ItineraryEventForm, {
  ITINERARY_EVENT_LABELS,
} from "../../components/itinerary/ItineraryEventForm"
import ItineraryForm, {
  addToDate,
} from "../../components/itinerary/ItineraryForm"
import PageBody from "../../components/page/PageBody"
import {CalendarDayEventModel, ItineraryEventModel} from "../../models/retreat"
import {AppRoutes} from "../../Stack"
import {ApiAction} from "../../store/actions/api"
import {
  deleteItineraryEvent,
  getNotifications,
  patchItinerary,
  postComment,
  postCommentThread,
  postFileToEvent,
  postItinerary,
  postItineraryEvent,
} from "../../store/actions/retreat"
import {FlokTheme} from "../../theme"
import {useQuery, useQueryAsList} from "../../utils"
import {
  useAttendeeLandingWebsite,
  useItinerary,
  useItineraryEvents,
} from "../../utils/retreatUtils"
import LoadingPage from "../misc/LoadingPage"
import {useRetreat} from "../misc/RetreatProvider"

let useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    // overflow: "hidden",
    margin: theme.spacing(2),
    "& > *:not(:first-child)": {
      // paddingLeft: theme.spacing(1),
    },
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
}))

function ItineraryBuilderPage() {
  let classes = useStyles()
  let [retreat, retreatIdx] = useRetreat()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getNotifications(retreat.id))
  }, [retreat, dispatch])
  let [itinerary, loadingItinerary] = useItinerary(retreat.itinerary_id ?? -1)
  let [events, loadingEvents] = useItineraryEvents(itinerary?.event_ids ?? [])
  let [itineraryEventFormOpen, setItineraryEventFormOpen] =
    useState<boolean>(false)
  let website = useAttendeeLandingWebsite(retreat.attendees_website_id ?? -1)
  let route = useRouteMatch<{retreatIdx: string; eventId: string}>()
  let eventId = parseInt(route.params.eventId)
  let config = route.path === AppRoutes.getPath("RetreatItineraryBuilderConfig")

  let itineraryLabels = [...ITINERARY_EVENT_LABELS, "MISC"]
  let [labelQuery, setLabelQuery] = useQueryAsList("label")

  function renderDays() {
    let returnValue = []
    if (itinerary?.number_of_days) {
      for (let i = 0; i < itinerary.number_of_days; i++) {
        returnValue.push(
          <div key={i}>
            <Box marginBottom={2}>
              <Typography variant="h2">
                {itinerary.start_date
                  ? new Intl.DateTimeFormat("en-US", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                    }).format(new Date(addToDate(itinerary.start_date, i + 2)))
                  : `Day ${i + 1}`}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gridGap={8}>
              {Object.values(events).filter(
                (event) =>
                  event &&
                  event.day === i + 1 &&
                  (labelQuery.length === 0 ||
                    (event.label && labelQuery.indexOf(event.label) !== -1) ||
                    (!event.label && labelQuery.indexOf("MISC") !== -1))
              ).length === 0 ? (
                <Box clone padding={2}>
                  <Paper variant="outlined"> No events scheduled</Paper>
                </Box>
              ) : (
                (
                  Object.values(events).filter(
                    (event) =>
                      (event &&
                        event.day === i + 1 &&
                        (labelQuery.length === 0 ||
                          (event.label &&
                            labelQuery.indexOf(event.label) !== -1))) ||
                      (!event?.label && labelQuery.indexOf("MISC") !== -1)
                  ) as ItineraryEventModel[]
                )
                  .sort((a, b) => {
                    return a.start_time > b.start_time ? 1 : -1
                  })
                  .map((event) => {
                    return (
                      <ItineraryEventRow
                        key={event.id}
                        itineraryId={retreat.itinerary_id ?? -1}
                        event={event}
                        onClick={() => {
                          dispatch(
                            push(
                              AppRoutes.getPath(
                                "RetreatItineraryBuilderEvent",
                                {
                                  retreatIdx: retreatIdx.toString(),
                                  eventId: event.id.toString(),
                                }
                              )
                            )
                          )
                        }}
                      />
                    )
                  })
              )}
            </Box>
          </div>
        )
      }
      if (
        (Object.values(events) as ItineraryEventModel[]).filter(
          (event) => itinerary && event.day > itinerary.number_of_days
        ).length > 0
      ) {
        returnValue.unshift(
          <div key="u">
            <Box marginBottom={2}>
              <Typography variant="h2">Unscheduled Events</Typography>
            </Box>
            <Box display="flex" flexDirection="column" gridGap={8}>
              {(Object.values(events) as ItineraryEventModel[])
                .filter(
                  (event) =>
                    itinerary &&
                    event.day > itinerary.number_of_days &&
                    (labelQuery.length === 0 ||
                      (event.label && labelQuery.indexOf(event.label) !== -1) ||
                      (!event.label && labelQuery.indexOf("MISC") !== -1))
                )
                .sort((a, b) => {
                  return a.start_time > b.start_time ? 1 : -1
                })
                .map((event) => {
                  return (
                    <ItineraryEventRow
                      key={event.id}
                      itineraryId={retreat.itinerary_id ?? -1}
                      event={event}
                      onClick={() => {
                        dispatch(
                          push(
                            AppRoutes.getPath("RetreatItineraryBuilderEvent", {
                              retreatIdx: retreatIdx.toString(),
                              eventId: event.id.toString(),
                            })
                          )
                        )
                      }}
                    />
                  )
                })}
            </Box>
          </div>
        )
      }
    }
    if (
      (Object.values(events) as ItineraryEventModel[]).filter(
        (event) => !event.day
      ).length > 0
    ) {
      returnValue.unshift(
        <div key="e">
          <Box marginBottom={2}>
            <Typography variant="h2">Unscheduled Events</Typography>
          </Box>
          <Box display="flex" flexDirection="column" gridGap={8}>
            {(Object.values(events) as ItineraryEventModel[])
              .filter((event) => !event.day)
              .sort((a, b) => {
                return a.start_time > b.start_time ? 1 : -1
              })
              .map((event) => {
                return (
                  <ItineraryEventRow
                    key={event.id}
                    itineraryId={retreat.itinerary_id ?? -1}
                    event={event}
                    onClick={() => {
                      dispatch(
                        push(
                          AppRoutes.getPath("RetreatItineraryBuilderEvent", {
                            retreatIdx: retreatIdx.toString(),
                            eventId: event.id.toString(),
                          })
                        )
                      )
                    }}
                  />
                )
              })}
          </Box>
        </div>
      )
    }
    return returnValue
  }
  let startHour = Math.min(
    getEarliestTime(
      (Object.values(events) as ItineraryEventModel[]).filter((event) => {
        return (
          itinerary && 0 < event.day && event.day <= itinerary.number_of_days
        )
      })
    ),
    7
  )
  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )
  let [calendarView, setCalendarView] = useState<"list" | "calendar">("list")

  let [commentsQuery, setCommentsQuery] = useQuery("comments")
  let commentsOpen = commentsQuery === "open"

  const handleChangeFilter = (label: string, checked: boolean) => {
    if (checked) {
      if (labelQuery[0] === "none") {
        setLabelQuery([label])
      } else if (labelQuery.length === itineraryLabels.length - 1) {
        setLabelQuery([])
      } else {
        setLabelQuery([...labelQuery, label])
      }
    } else {
      let labels = [...labelQuery]
      const index = labels.indexOf(label)

      if (index !== -1) {
        labels.splice(index, 1)
      } else {
        labels = [...itineraryLabels]
        const index = labels.indexOf(label)
        if (index > -1) {
          labels.splice(index, 1)
        }
      }
      if (labels.length === 0) {
        labels.push("none")
      }
      setLabelQuery(labels)
    }
  }
  if (loadingItinerary || loadingEvents) {
    return <LoadingPage />
  }
  return (
    <PageBody appBar>
      <Drawer
        anchor="right"
        open={config}
        onClose={() => {
          dispatch(
            push(
              AppRoutes.getPath("RetreatItineraryBuilderPage", {
                retreatIdx: retreatIdx.toString(),
              })
            )
          )
        }}>
        <Box margin={2} display="flex" flexDirection={"column"} gridGap={8}>
          <Typography variant="h2">Itinerary Settings</Typography>
          {itinerary && (
            <ItineraryForm
              retreat={retreat}
              itineraryId={itinerary.id}
              onSubmit={() => {
                dispatch(
                  push(
                    AppRoutes.getPath("RetreatItineraryBuilderPage", {
                      retreatIdx: retreatIdx.toString(),
                    })
                  )
                )
              }}
            />
          )}
        </Box>
      </Drawer>
      <div className={classes.section}>
        <Box display={"flex"}>
          <AppHeaderWithSettings
            viewPageLink={AppRoutes.getPath("AttendeeSiteItineraryPage", {
              websiteName: website?.name ?? "",
            })}
            primaryHeader="Itinerary"
            secondaryHeader="Builder"
            onClickSettings={() => {
              dispatch(
                push(
                  AppRoutes.getPath("RetreatItineraryBuilderConfig", {
                    retreatIdx: retreatIdx.toString(),
                  })
                )
              )
            }}
          />

          {itinerary && (
            <ItineraryEventForm
              startDate={itinerary.start_date}
              open={itineraryEventFormOpen || !!eventId}
              onClose={() => {
                setItineraryEventFormOpen(false)
                dispatch(
                  push(
                    AppRoutes.getPath("RetreatItineraryBuilderPage", {
                      retreatIdx: retreatIdx.toString(),
                    })
                  )
                )
              }}
              days={itinerary.number_of_days}
              itineraryId={itinerary.id}
              onSubmit={() => {
                setItineraryEventFormOpen(false)
              }}
              eventId={eventId ? eventId : undefined}
            />
          )}
        </Box>
        {!itinerary && (
          <Box display={"flex"} flexDirection="column">
            <Box
              marginLeft="auto"
              marginRight="auto"
              marginBottom={2}
              display={"flex"}
              flexDirection="column"
              maxWidth={"600px"}
              gridGap={8}
              textAlign="center">
              <AppTypography variant="h2">
                We're excited for you to start building your itinerary.
              </AppTypography>
              <AppTypography variant="body1">
                You will be able to create and schedule events, all of which can
                be seen by attendees through the attendee website.
              </AppTypography>
            </Box>
            <Box marginLeft="auto" marginRight="auto" marginBottom={2}></Box>

            <Box maxWidth={"400px"} marginLeft="auto" marginRight="auto">
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={() => {
                  dispatch(postItinerary({number_of_days: 4}, retreat.id))
                }}>
                Get Started
              </Button>
            </Box>
          </Box>
        )}
        {itinerary && (
          <Box display={"flex"} gridGap={16} maxHeight={"100%"}>
            {commentsOpen && isSmallScreen ? (
              <></>
            ) : calendarView === "list" ? (
              <Box flex={1} minWidth={0} overflow="scroll">
                <Box width="100%" display={"flex"} flexDirection="column">
                  <TopToolbar
                    filterList={labelQuery}
                    onChangeFilter={handleChangeFilter}
                    onToggle={(e, value) => {
                      if (value) {
                        setCalendarView(value)
                      }
                    }}
                    toggleValue={calendarView}
                    onAdd={() => {
                      setItineraryEventFormOpen(true)
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={16}
                  flex={1}
                  overflow="auto">
                  {renderDays()}
                </Box>
              </Box>
            ) : (
              <Box flex={1} minWidth={0} overflow="scroll">
                <TopToolbar
                  filterList={labelQuery}
                  onChangeFilter={handleChangeFilter}
                  onToggle={(e, value) => {
                    if (value) {
                      setCalendarView(value)
                    }
                  }}
                  toggleValue={calendarView}
                  onAdd={() => {
                    setItineraryEventFormOpen(true)
                  }}
                />
                <Box
                  width={"fit-content"}
                  padding={2}
                  marginBottom={3}
                  display={"flex"}
                  clone>
                  <Paper variant="outlined">
                    <Box display={"flex"} marginRight={3}>
                      <Box marginTop={"52px"}>
                        <CalendarHours startHour={startHour} />
                      </Box>
                      {[...new Array(itinerary?.number_of_days)].map((u, i) => {
                        return (
                          <CalendarDay
                            key={i}
                            startHour={startHour}
                            width={230}
                            title={
                              itinerary && itinerary.start_date
                                ? new Intl.DateTimeFormat("en-US", {
                                    weekday: "long",
                                    day: "numeric",
                                    month: "long",
                                  }).format(
                                    new Date(
                                      addToDate(itinerary.start_date, i + 2)
                                    )
                                  )
                                : `Day ${i + 1}`
                            }
                            events={(
                              Object.values(events) as ItineraryEventModel[]
                            )
                              .filter(
                                (event) =>
                                  event.day === i + 1 &&
                                  (labelQuery.length === 0 ||
                                    (event.label &&
                                      labelQuery.indexOf(event.label) !== -1) ||
                                    (!event.label &&
                                      labelQuery.indexOf("MISC") !== -1))
                              )
                              .map((event) => {
                                let calendarEvent =
                                  event as CalendarDayEventModel
                                calendarEvent.onClick = () => {
                                  dispatch(
                                    push(
                                      AppRoutes.getPath(
                                        "RetreatItineraryBuilderEvent",
                                        {
                                          retreatIdx: retreatIdx.toString(),
                                          eventId: event.id.toString(),
                                        }
                                      )
                                    )
                                  )
                                }
                                return calendarEvent
                              })}
                          />
                        )
                      })}
                    </Box>
                  </Paper>
                </Box>
              </Box>
            )}
            {itinerary && (
              <CommentThreads
                onOpen={() => {
                  setCommentsQuery("open")
                }}
                open={commentsOpen}
                onClose={() => {
                  setCommentsQuery(null)
                }}
                threadObjects={[
                  ...Object.values(events)
                    .filter((event) => event && event.comment_thread_id)
                    .map((event) => event as ItineraryEventModel)
                    .map<ThreadObject>((event) => {
                      return {
                        title: event?.day
                          ? `${event.title}  |  Day ${event.day}`
                          : event.title,
                        threadId: event.comment_thread_id,
                        onSubmit: async (values) => {
                          dispatch(
                            postComment({
                              ...values,
                              comment_thread_id: event.comment_thread_id,
                              retreat_id: retreat.id,
                              redirect: `/itinerary/builder/events/${event.id}?comment=:id`,
                            })
                          )
                        },
                      }
                    }),
                  {
                    title: "General",
                    sticky: true,
                    threadId: itinerary.comment_thread_id,
                    onSubmit: async (values: {
                      comment_text: RawDraftContentState
                    }) => {
                      if (itinerary && itinerary.comment_thread_id) {
                        dispatch(
                          postComment({
                            ...values,
                            comment_thread_id: itinerary.comment_thread_id,
                            retreat_id: retreat.id,
                            redirect: `/itinerary/builder?comments=open`,
                          })
                        )
                      } else {
                        let threadResponse = (await dispatch(
                          postCommentThread()
                        )) as unknown as ApiAction
                        if (!threadResponse.error && itinerary) {
                          let eventResponse = (await dispatch(
                            patchItinerary(
                              {
                                comment_thread_id:
                                  threadResponse.payload.comment_thread.id,
                              },
                              itinerary.id
                            )
                          )) as unknown as ApiAction

                          if (!eventResponse.error) {
                            dispatch(
                              postComment({
                                ...values,
                                comment_thread_id:
                                  threadResponse.payload.comment_thread.id,
                                retreat_id: retreat.id,
                                redirect: `/itinerary/builder?comments=open`,
                              })
                            )
                          }
                        }
                      }
                    },
                  },
                ]}
              />
            )}
          </Box>
        )}
      </div>
    </PageBody>
  )
}

export default ItineraryBuilderPage

function ItineraryEventRow(props: {
  event: ItineraryEventModel
  onClick: () => void
  itineraryId: number
}) {
  let dispatch = useDispatch()
  let [deleteOpen, setDeleteOpen] = useState(false)
  return (
    <>
      <AppConfirmationModal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onSubmit={async () => {
          await dispatch(deleteItineraryEvent(props.event.id))
          setDeleteOpen(false)
        }}
        text="This action cannot be undone."
        title="Are you sure you want to delete this event?"
      />
      <Box
        clone
        display="flex"
        width={"100%"}
        padding={2}
        alignItems="center"
        justifyContent="space-between">
        <Paper variant="outlined">
          <Box
            display={"flex"}
            flexDirection="column"
            style={{cursor: "pointer"}}
            flex={1}
            onClick={(e) => {
              props.onClick()
            }}>
            <div>
              <AppTypography fontWeight="bold">
                {props.event.title}
              </AppTypography>
            </div>
            <Box display={"flex"}>
              <Box color="grey.500">
                <AppTypography variant="body2">Time:</AppTypography>
              </Box>
              &nbsp;
              <div>
                {formatTime(props.event.start_time)} -{" "}
                {formatTime(props.event.end_time)}
              </div>
            </Box>
            {props.event.location && (
              <Box display={"flex"}>
                <Box color="grey.500">
                  <AppTypography variant="body2">Location:</AppTypography>
                </Box>
                &nbsp;
                <div>{props.event.location}</div>
              </Box>
            )}
          </Box>
          <Box display={"flex"}>
            {props.event.label && (
              <Box bgcolor={"red"} clone>
                <Chip
                  label={
                    props.event.label[0].toUpperCase() +
                    props.event.label.slice(1).toLowerCase()
                  }
                />
              </Box>
            )}
            <ItineraryEventDropDown
              onDuplicate={async () => {
                let values = {...props.event} as Partial<ItineraryEventModel>
                delete values.id
                delete values.itinerary_id
                delete values.created_at
                delete values.created_by_user_id
                delete values.files
                delete values.comment_thread_id
                let response = (await dispatch(
                  postItineraryEvent(values, props.event.itinerary_id)
                )) as unknown as ApiAction
                await Promise.all(
                  props.event.files.map(async (file) => {
                    let fileResponse = (await dispatch(
                      postFileToEvent({
                        event_id: response.payload.event.id,
                        file_id: file.id,
                      })
                    )) as unknown as ApiAction
                    if (!fileResponse.error) {
                      dispatch({
                        type: "ADD_FILE_TO_EVENT",
                        file: file,
                        event_id: response.payload.event.id,
                      })
                    }
                  })
                )
              }}
              onDelete={() => {
                setDeleteOpen(true)
              }}
            />
          </Box>
        </Paper>
      </Box>
    </>
  )
}

export function formatTime(time: string) {
  let date = new Date(`December 14, 2026 ${time}`)
  return new Intl.DateTimeFormat("en-US", {
    timeStyle: "short",
  }).format(date)
}

function TopToolbar(props: {
  onAdd: () => void
  toggleValue: "list" | "calendar"
  onToggle: (e: any, value: "list" | "calendar") => void
  onChangeFilter: (label: string, checked: boolean) => void
  filterList: string[]
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const menuOpen = Boolean(anchorEl)
  let itineraryLabels = [...ITINERARY_EVENT_LABELS, "MISC"]

  return (
    <Box
      width="100%"
      display={"flex"}
      flexDirection="column"
      marginBottom={2}
      marginTop={1}>
      <Box
        width="100%"
        display={"flex"}
        flexDirection="row"
        justifyContent={"space-between"}
        marginBottom={1}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={props.onAdd}>
          <Add fontSize="small" /> Add event
        </Button>
        <Box display={"flex"} flexDirection="row" gridGap={12}>
          <ToggleButtonGroup
            // size="small"
            value={props.toggleValue}
            exclusive
            onChange={props.onToggle}
            aria-label="text alignment">
            <ToggleButton value="list" size="small">
              <List fontSize="small" />
            </ToggleButton>
            <ToggleButton value="calendar" size="small">
              <CalendarToday fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={menuOpen}
            onClose={() => {
              setAnchorEl(null)
            }}
            TransitionComponent={Fade}>
            {itineraryLabels.map((label) => {
              return (
                <MenuItem onClick={undefined} key={label}>
                  <Checkbox
                    color="primary"
                    checked={
                      props.filterList.length === 0 ||
                      props.filterList.indexOf(label) !== -1
                    }
                    onChange={(e, checked) => {
                      props.onChangeFilter(label, checked)
                    }}
                  />
                  &nbsp;
                  {label !== "MISC" && (
                    <Chip
                      label={
                        label[0].toUpperCase() + label.slice(1).toLowerCase()
                      }
                    />
                  )}
                </MenuItem>
              )
            })}
          </Menu>
          <Box display={"flex"}>
            <Button
              aria-controls="fade-menu"
              aria-haspopup="true"
              onClick={(event) => {
                event.stopPropagation()
                setAnchorEl(event.currentTarget)
              }}
              variant="contained"
              size="small">
              <Tune fontSize="small" />
              Filter
            </Button>
            {props.filterList.length !== 0 && (
              <Box
                zIndex={"1000"}
                marginLeft={"-10px"}
                marginTop={"-4px"}
                bgcolor={"primary.main"}
                width="25px"
                height={"25px"}
                borderRadius={"50%"}
                display="flex"
                color="common.white"
                justifyContent={"center"}
                alignItems="center">
                {props.filterList[0] !== "none" ? props.filterList.length : 0}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Divider />
    </Box>
  )
}
