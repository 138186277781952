import {Box, Button, CircularProgress, Paper} from "@material-ui/core"
import {useEffect, useState} from "react"
import {useCommentThread} from "../../utils/retreatUtils"
import Comment from "./Comment"

type CommentListProps = {
  commentThreadId: number
  showAll?: boolean
  startCommentId?: number
}
export default function CommentsList(props: CommentListProps) {
  let [thread, threadLoading] = useCommentThread(props.commentThreadId)
  let startIndex =
    thread?.comment_ids && props.startCommentId
      ? thread?.comment_ids.indexOf(props.startCommentId)
      : -1

  let [showNumber, setShowNumber] = useState(
    !props.startCommentId || !thread?.comment_ids
      ? 4
      : Math.max(thread.comment_ids.length - startIndex, 4)
  )
  useEffect(() => {
    if (props.startCommentId) {
      setShowNumber(
        !props.startCommentId || !thread?.comment_ids
          ? 4
          : Math.max(thread.comment_ids.length - startIndex, 4)
      )
    }
  }, [props.startCommentId, startIndex, thread?.comment_ids])

  return (
    <Box display={"flex"} padding={1} flexDirection={"column"} gridGap={8}>
      {!threadLoading ? (
        <Paper elevation={0} style={{padding: "20px 20px"}} variant="outlined">
          {thread && showNumber < thread.comment_ids.length && (
            <Box
              marginBottom={2}
              marginTop={-1}
              marginLeft="auto"
              marginRight="auto"
              width={"fit-content"}>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setShowNumber((showNumber) => showNumber + 4)
                }}>
                Show more comments ({thread.comment_ids.length - showNumber})
              </Button>
            </Box>
          )}
          {thread &&
            props.showAll &&
            thread.comment_ids.map((commentId, i) => {
              return <Comment id={commentId} key={commentId} />
            })}
          {thread &&
            thread.comment_ids &&
            (
              thread.comment_ids.filter((id, i) => {
                return (
                  id && thread && i >= thread.comment_ids.length - showNumber
                )
              }) as number[]
            ).map((commentId, i) => {
              return commentId && <Comment id={commentId} key={commentId} />
            })}
        </Paper>
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
    </Box>
  )
}
