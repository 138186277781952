import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core"
import {
  ArrowDropDown,
  CalendarToday,
  CheckCircle,
  Delete,
  ErrorOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons"
import {push} from "connected-react-router"
import React, {ReactElement, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import {RetreatModel} from "../../models/retreat"
import {
  SortingTaskPriorityInteger,
  taskFilteringOptions,
  TaskModel,
  TaskPriorityName,
  taskSortingOptions,
  TaskStatusEnum,
  TaskStatusName,
} from "../../models/task"
import {UserModel} from "../../models/user"
import LoadingPage from "../../pages/misc/LoadingPage"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import {RootState} from "../../store"
import {postComment} from "../../store/actions/retreat"
import {deleteTask, patchTask, postLabel} from "../../store/actions/task"
import {useQuery} from "../../utils"
import {useLabel, useLabels, useTasks, useUsers} from "../../utils/retreatUtils"
import AppHeaderWithSettings from "../base/AppHeaderWithSettings"
import AppTypography from "../base/AppTypography"
import AppConfirmationModal from "../base/ConfirmationModal"
import CommentThreads, {ThreadObject} from "../comments/CommentThreads"
import PageBody from "../page/PageBody"
import {FilteringPopper} from "./FilteringPopper"
import {GroupByOption, GroupByPopper} from "./GroupByPopper"
import {SortingPopper} from "./SortingPopper"
import TaskFormModal from "./TaskFormModal"
import TaskTemplateGroupMenu from "./TaskTemplateMenu"
import TaskThreeDotDropdown from "./TaskThreeDotDropdown"
import TaskViewer from "./TaskViewer"

const dateFormatShort = (date: Date) =>
  new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  })

let useItemStyles = makeStyles((theme) => ({
  chipContainer: {
    width: "100px",
    alignItems: "center",
    marginLeft: "10px",
  },
  customTaskDueDate: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    marginTop: theme.spacing(0.5),
  },
  taskLabel: {marginRight: 5},
  taskTitle: {marginBottom: 1},
  taskContent: {
    display: "flex",
    minWidth: 0,
    flexDirection: "column",
    cursor: "pointer",
    justifyContent: "space-between",
    flex: 1,
  },
  assigneeList: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "nowrap",
    margin: 0,
    overflow: "auto",
  },
  chip: {marginRight: theme.spacing(0.5)},
}))

const DEFAULT_LABELS = [
  {text: "Venue Selection"},
  {text: "Planning"},
  {text: "Meeting"},
  {text: "Hotel"},
  {text: "Flights"},
  {text: "Website"},
  {text: "Activities"},
  {text: "F&B"},
  {text: "Payment Due"},
  {text: "AV"},
  {text: "Important Date"},
  {text: "Administrative"},
  {text: "Contract"},
  {text: "Approval Needed"},
]

function getDropdownOptions(
  onDelete: () => void,
  onComplete: () => void,
  task: TaskModel
) {
  return [
    {
      text: "Delete Task",
      onClick: onDelete,
      icon: <Delete fontSize="small" />,
    },
    {
      text: "Mark as Completed",
      onClick: onComplete,
      disabled: task.status === TaskStatusEnum.COMPLETED,
      icon: <CheckCircle fontSize="small" />,
    },
  ]
}

function TaskListItem(props: {
  task: TaskModel
  retreatUsers: number[]
  availableLabels: number[]
}) {
  let classes = useItemStyles(props)
  let {task, retreatUsers, availableLabels} = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [retreat, retreatIdx] = useRetreat()

  let dispatch = useDispatch()
  let [groupByQuery] = useQuery("group-by")
  let [sortByQuery] = useQuery("sort-by")
  let [filterByQuery] = useQuery("filter-by")
  let [commentsQuery] = useQuery("comments")
  let [assigneeOptions] = useUsers(retreatUsers)
  let [labelOptions] = useLabels(availableLabels)

  const dropdownOptions = getDropdownOptions(
    () => {
      setDeleteOpen(true)
    },
    () => {
      dispatch(patchTask({status: "COMPLETED"}, task.id))
    },
    task
  )
  let [deleteOpen, setDeleteOpen] = useState(false)
  return (
    <>
      <AppConfirmationModal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onSubmit={async () => {
          await dispatch(deleteTask(task.id, task.retreat_id))
          setDeleteOpen(false)
        }}
        text="This action cannot be undone."
        title="Are you sure you want to delete this task?"
      />
      <Box
        clone
        display="flex"
        width={"100%"}
        padding={2}
        alignItems="center"
        justifyContent="space-between">
        <Paper variant="outlined">
          <Box
            className={classes.taskContent}
            onClick={() =>
              dispatch(
                push(
                  AppRoutes.getPath(
                    "TaskPage",
                    {
                      retreatIdx: retreatIdx.toString(),
                      taskId: task.id.toString(),
                    },
                    {
                      ...(groupByQuery && {"group-by": groupByQuery}),
                      ...(commentsQuery && {comments: commentsQuery}),
                      ...(filterByQuery && {"filter-by": filterByQuery}),
                      ...(sortByQuery && {"sort-by": sortByQuery}),
                    }
                  )
                )
              )
            }>
            <Box className={classes.taskTitle}>
              <AppTypography fontWeight="bold">{task.title}</AppTypography>
            </Box>
            <div className={classes.customTaskDueDate}>
              <AppTypography
                variant="body2"
                color="textSecondary"
                className={classes.taskLabel}>
                Due date:
              </AppTypography>
              <Chip
                label={
                  task.due_date
                    ? dateFormatShort(new Date(task.due_date))
                    : "N/A"
                }
                icon={<CalendarToday />}
                size="small"
              />
            </div>

            <div className={classes.customTaskDueDate}>
              <AppTypography
                variant="body2"
                color="textSecondary"
                className={classes.taskLabel}>
                Priority:
              </AppTypography>

              <Chip
                label={
                  task.priority ? `${TaskPriorityName[task.priority]}` : "N/A"
                }
                icon={<ErrorOutline />}
                size="small"
              />
            </div>
            {task.labels ? (
              <div className={classes.customTaskDueDate}>
                <AppTypography
                  variant="body2"
                  color="textSecondary"
                  className={classes.taskLabel}>
                  Labels:
                </AppTypography>

                {
                  <div className={classes.assigneeList}>
                    {task.labels.map((labelId) => {
                      return (
                        <Chip
                          label={`${labelOptions[labelId]?.text}`}
                          key={labelId}
                          size="small"
                          className={classes.chip}
                        />
                      )
                    })}
                  </div>
                }
              </div>
            ) : (
              <></>
            )}
            {task.assignees ? (
              <div className={classes.customTaskDueDate}>
                <AppTypography
                  variant="body2"
                  color="textSecondary"
                  className={classes.taskLabel}>
                  Assignees:
                </AppTypography>

                {
                  <div className={classes.assigneeList}>
                    {task.assignees.map((assigneeId) => {
                      return (
                        <Chip
                          label={`${assigneeOptions[assigneeId]?.first_name} ${assigneeOptions[assigneeId]?.last_name}`}
                          key={assigneeId}
                          avatar={
                            <Avatar>
                              {assigneeOptions[assigneeId]?.first_name?.[0]}
                            </Avatar>
                          }
                          size="small"
                          className={classes.chip}
                        />
                      )
                    })}
                  </div>
                }
              </div>
            ) : (
              <></>
            )}
          </Box>
          <Box display={"flex"}>
            {task.status && (
              <div className={classes.chipContainer}>
                <Box bgcolor={"red"} alignSelf={"center"} clone>
                  <Chip label={`${TaskStatusName[task.status]}`} />
                </Box>
              </div>
            )}

            <TaskThreeDotDropdown dropdownOptions={dropdownOptions} />
          </Box>
        </Paper>
      </Box>
    </>
  )
}

let useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    padding: theme.spacing(2),
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  headerButton: {
    margin: "10px",
  },
  paper: {
    border: "1px solid",
    cursor: "pointer",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  menuItem: {
    border: "1px solid",
    cursor: "pointer",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  noTasks: {
    padding: theme.spacing(2),
  },
}))

function renderGroupedTasks(
  groupBy: GroupByOption | null,
  tasks: (TaskModel | undefined)[],
  retreat: RetreatModel,
  assignees: UserModel[]
) {
  if (groupBy === "status" || !groupBy) {
    return (
      <Box marginBottom={2}>
        {Object.entries(TaskStatusName).map(([status, title], i) => {
          return (
            <TaskGroup
              key={`${status}-${i}`}
              title={`${title} (${
                tasks.filter((task) => task && task.status === status).length
              })`}
              items={
                <>
                  {tasks
                    .filter((task) => task && task.status === status)
                    .map((task) => {
                      return (
                        task && (
                          <TaskListItem
                            availableLabels={retreat.labels}
                            task={task}
                            key={task.id}
                            retreatUsers={retreat.users}
                          />
                        )
                      )
                    })}
                  {tasks.filter((task) => task && task.status === status)
                    .length === 0 && (
                    <Paper style={{padding: "16px"}} variant="outlined">
                      No Tasks
                    </Paper>
                  )}
                </>
              }
            />
          )
        })}
      </Box>
    )
  } else if (groupBy === "assignee") {
    return (
      <Box marginBottom={2}>
        {assignees.map((user) => {
          return (
            <TaskGroup
              key={user.id}
              title={`${user.first_name} ${user.last_name} (${
                tasks.filter(
                  (task) => task && task.assignees.indexOf(user.id) !== -1
                ).length
              })`}
              items={
                <>
                  {tasks
                    .filter(
                      (task) => task && task.assignees.indexOf(user.id) !== -1
                    )
                    .map((task) => {
                      return (
                        task && (
                          <TaskListItem
                            availableLabels={retreat.labels}
                            task={task}
                            key={task.id}
                            retreatUsers={retreat.users}
                          />
                        )
                      )
                    })}
                  {tasks.filter(
                    (task) => task && task.assignees.indexOf(user.id) !== -1
                  ).length === 0 && (
                    <Paper style={{padding: "16px"}} variant="outlined">
                      No Tasks Assigned
                    </Paper>
                  )}
                </>
              }
            />
          )
        })}
      </Box>
    )
  } else if (groupBy === "priority") {
    return (
      <Box>
        {Object.entries(TaskPriorityName).map(([priority, title]) => {
          return (
            <TaskGroup
              key={priority}
              title={`${title} (${
                tasks.filter((task) => task && task.priority === priority)
                  .length
              })`}
              items={
                <>
                  {tasks
                    .filter((task) => task && task.priority === priority)
                    .map((task) => {
                      return (
                        task && (
                          <TaskListItem
                            availableLabels={retreat.labels}
                            task={task}
                            key={task.id}
                            retreatUsers={retreat.users}
                          />
                        )
                      )
                    })}
                  {tasks.filter((task) => task && task.priority === priority)
                    .length === 0 && (
                    <Paper style={{padding: "16px"}} variant="outlined">
                      No Tasks
                    </Paper>
                  )}
                </>
              }
            />
          )
        })}
        {tasks.filter((task) => task && !task.priority).length > 0 && (
          <TaskGroup
            title={`No Priority (${
              tasks.filter((task) => task && !task.priority).length
            })`}
            items={
              <>
                {(
                  tasks.filter((task) => task && !task.priority) as TaskModel[]
                ).map((task) => {
                  return (
                    <TaskListItem
                      availableLabels={retreat.labels}
                      task={task}
                      key={task.id}
                      retreatUsers={retreat.users}
                    />
                  )
                })}
              </>
            }
          />
        )}
      </Box>
    )
  } else if (groupBy === "label") {
    return (
      <Box>
        {retreat.labels.map((label) => {
          return (
            <TaskGroup
              key={label}
              header={
                <LabelHeader
                  labelId={label}
                  number={
                    tasks.filter(
                      (task) => task && task.labels.indexOf(label) !== -1
                    ).length
                  }
                />
              }
              items={
                <>
                  {tasks
                    .filter((task) => task && task.labels.indexOf(label) !== -1)
                    .map((task) => {
                      return (
                        task && (
                          <TaskListItem
                            availableLabels={retreat.labels}
                            task={task}
                            key={task.id}
                            retreatUsers={retreat.users}
                          />
                        )
                      )
                    })}
                  {tasks.filter(
                    (task) => task && task.labels.indexOf(label) !== -1
                  ).length === 0 && (
                    <Paper style={{padding: "16px"}} variant="outlined">
                      No Tasks
                    </Paper>
                  )}
                </>
              }
            />
          )
        })}
        {
          <TaskGroup
            title={`Unlabeled (${
              (
                tasks.filter(
                  (task) => task && task.labels.length === 0
                ) as TaskModel[]
              ).length
            })`}
            items={
              <>
                {(
                  tasks.filter(
                    (task) => task && task.labels.length === 0
                  ) as TaskModel[]
                ).map((task) => {
                  return (
                    <TaskListItem
                      availableLabels={retreat.labels}
                      task={task}
                      key={task.id}
                      retreatUsers={retreat.users}
                    />
                  )
                })}
                {(
                  tasks.filter(
                    (task) => task && task.labels.length === 0
                  ) as TaskModel[]
                ).length === 0 && (
                  <Paper style={{padding: "16px"}} variant="outlined">
                    No Tasks
                  </Paper>
                )}
              </>
            }
          />
        }
      </Box>
    )
  } else {
    return (
      <Box display="flex" flexDirection="column" gridGap={16}>
        {tasks.map((task) => {
          return (
            task && (
              <TaskListItem
                availableLabels={retreat.labels}
                task={task}
                key={task.id}
                retreatUsers={retreat.users}
              />
            )
          )
        })}
        {tasks.filter((task) => task).length === 0 && (
          <Paper style={{padding: "16px"}} variant="outlined">
            No Tasks
          </Paper>
        )}
      </Box>
    )
  }
}
export default function TaskList() {
  let classes = useStyles()

  let taskList = useSelector((state: RootState) => state.retreat.tasks)
  let [taskModal, setTaskModal] = useState(false)
  let [commentsQuery, setCommentsQuery] = useQuery("comments")

  let [groupByQuery, setGroupByQuery] = useQuery("group-by")
  let [sortByQuery, setSortByQuery] = useQuery("sort-by")
  let [filterByQuery, setFilterByQuery] = useQuery("filter-by")
  let route = useRouteMatch<{retreatIdx: string; taskId: string}>()
  let taskId = parseInt(route.params.taskId)
  let [retreat, retreatIdx] = useRetreat()
  let dispatch = useDispatch()
  let [deleteOpen, setDeleteOpen] = useState(false)
  let [tasks, loadingTasks] = useTasks(retreat.task_ids)
  let [retreatLabels, loadingLabels] = useLabels(retreat.labels)
  let isMissingDefaultLabels =
    DEFAULT_LABELS.filter((defaultLabel) => {
      return (
        Object.values(retreatLabels).findIndex(
          (label) => label?.text === defaultLabel.text
        ) === -1
      )
    }).length > 0

  let [postingLabels, setPostingLabels] = useState(false)

  function sortTasks(
    arrayOfTasks: (TaskModel | undefined)[],
    key: keyof TaskModel,
    order?: string
  ) {
    function comparator(
      taskA: TaskModel | undefined,
      taskB: TaskModel | undefined,
      key: keyof TaskModel,
      order: string
    ) {
      let valA =
        key === ("priority" as keyof TaskModel) && taskA?.priority
          ? SortingTaskPriorityInteger[taskA?.priority]
          : taskA?.[key]
      let valB =
        key === ("priority" as keyof TaskModel) && taskB?.priority
          ? SortingTaskPriorityInteger[taskB?.priority]
          : taskB?.[key]

      if (order === "ASC") {
        if (valA === valB) return 0
        if (!valA) return -1
        if (!valB) return 1
        return valA > valB ? 1 : -1
      }
      if (order === "DESC") {
        if (valA === valB) return 0
        if (!valA) return 1
        if (!valB) return -1
        return valA > valB ? -1 : 1
      } else {
        return 0
      }
    }
    if (key && order) {
      return arrayOfTasks
        .filter((t) => t !== undefined)
        .sort((a, b) => comparator(a, b, key as keyof TaskModel, order))
    } else {
      return arrayOfTasks
        .filter((t) => t !== undefined)
        .sort((a, b) => comparator(a, b, "created_at", "DESC"))
    }
  }
  function filterTasks(
    arrayOfTasks: (TaskModel | undefined)[],
    key?: keyof TaskModel,
    filter?: string
  ) {
    if (key && filter && filter !== "undefined") {
      if (key === ("due_date" as keyof TaskModel)) {
        let today = new Date().toISOString().split("T")[0]
        let tomorrow = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000
        ).toISOString()
        let next7Days = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000 * 7
        ).toISOString()
        let next14Days = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000 * 14
        ).toISOString()
        return arrayOfTasks.filter((task) => {
          if (filter === "Due before today") {
            return task?.due_date && task?.due_date.toString() < today
          }
          if (filter === "Due today") {
            return task?.due_date && task?.due_date.toString() === today
          }
          if (filter === "Due tomorrow") {
            return (
              task?.due_date &&
              task?.due_date.toString() > today &&
              task?.due_date.toString() < tomorrow
            )
          }
          if (filter === "Due next 7 days") {
            return (
              task?.due_date &&
              task?.due_date.toString() > today.split("T")[0] &&
              task?.due_date.toString() < next7Days
            )
          }
          if (filter === "Due next 14 days") {
            return (
              task?.due_date &&
              task?.due_date.toString() > today &&
              task?.due_date.toString() < next14Days
            )
          } else {
            return false
          }
        })
      }
      if (!isNaN(filter as any)) {
        if (key === ("labels" as keyof TaskModel)) {
          return arrayOfTasks.filter(
            (task) => task && task?.labels.includes(parseInt(filter))
          )
        }
        if (key === ("assignees" as keyof TaskModel)) {
          return arrayOfTasks.filter(
            (task) => task && task?.assignees.includes(parseInt(filter))
          )
        }
      }
      return arrayOfTasks.filter((task) => task && task[key] === filter)
    } else {
      return arrayOfTasks
    }
  }

  function getTasksToDisplay() {
    return sortTasks(
      filterTasks(
        Object.values(taskList),
        filterByQuery?.split(":")[0] as keyof TaskModel,
        filterByQuery?.split(":")[1]
      ),
      sortByQuery?.split(":")[0] as keyof TaskModel,
      sortByQuery?.split(":")[1]
    )
  }

  const tasksToDisplay = useMemo(getTasksToDisplay, [
    taskList,
    filterByQuery,
    sortByQuery,
  ])
  let [users, loadingUsers] = useUsers(retreat.users)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  if (loadingLabels || loadingTasks || loadingUsers) {
    return <LoadingPage />
  }
  return (
    <PageBody appBar>
      {taskId && taskList[taskId] ? (
        <TaskViewer
          key={taskId}
          retreatUsers={retreat.users}
          labels={retreatLabels}
          open={!!taskId}
          onClose={() =>
            dispatch(
              push(
                AppRoutes.getPath(
                  "TaskListPage",
                  {
                    retreatIdx: retreatIdx.toString(),
                  },
                  {
                    ...(groupByQuery && {"group-by": groupByQuery}),
                    ...(commentsQuery && {comments: commentsQuery}),
                    ...(filterByQuery && {"filter-by": filterByQuery}),
                    ...(sortByQuery && {"sort-by": sortByQuery}),
                  }
                )
              )
            )
          }
          onSubmit={() =>
            dispatch(
              push(
                AppRoutes.getPath("TaskListPage", {
                  retreatIdx: retreatIdx.toString(),
                })
              )
            )
          }
          threeDotDropdownOptions={getDropdownOptions(
            () => {
              setDeleteOpen(true)
            },
            () => {
              dispatch(patchTask({status: "COMPLETED"}, taskId))
            },
            taskList[taskId]!
          )}
          task={taskList[taskId] as TaskModel}
        />
      ) : undefined}
      {taskId && taskList[taskId] ? (
        <AppConfirmationModal
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          onSubmit={async () => {
            await dispatch(deleteTask(taskId, taskList[taskId]!.retreat_id))
            setDeleteOpen(false)
          }}
          text="This action cannot be undone."
          title="Are you sure you want to delete this task?"
        />
      ) : undefined}
      <div className={classes.section}>
        <Box display={"flex"}>
          {taskModal ? (
            <TaskFormModal
              open={taskModal}
              sideNav={true}
              retreat_id={retreat.id}
              retreatUsers={retreat.users}
              labels={retreatLabels}
              onClose={() => {
                setTaskModal(false)
              }}
            />
          ) : (
            <></>
          )}
        </Box>

        {!isMissingDefaultLabels ? (
          <Box display={"flex"} gridGap={16} width={"100%"}>
            <Box flex={1}>
              <Box
                display={"flex"}
                justifyContent={"space-betweeen"}
                marginBottom={0.5}>
                <Box marginRight={"auto"}>
                  <ButtonGroup
                    variant="contained"
                    className={classes.headerButton}
                    color="primary">
                    <Button
                      onClick={() => {
                        setTaskModal(true)
                      }}>
                      Add task
                    </Button>
                    <Button size="small" onClick={handleClick}>
                      <ArrowDropDown />
                    </Button>
                    <TaskTemplateGroupMenu
                      anchorEl={anchorEl}
                      onClose={handleClose}
                    />
                  </ButtonGroup>
                </Box>
                <Box>
                  <Box
                    display={"inline-flex"}
                    justifyContent={"space-between"}
                    marginRight={"0px"}
                    marginLeft={"auto"}>
                    <GroupByPopper
                      selected={(groupByQuery as GroupByOption) ?? "status"}
                      setGroupBy={(value) => {
                        if (value === "status") {
                          setGroupByQuery(null)
                        } else {
                          setGroupByQuery(value)
                        }
                      }}
                      showBadge={!!groupByQuery}
                    />
                    <SortingPopper
                      sortingState={{
                        key:
                          (sortByQuery?.split(":")[0] as keyof TaskModel) ??
                          "created_at",
                        order: sortByQuery?.split(":")[0]
                          ? sortByQuery?.split(":")[1] || "ASC"
                          : "DESC",
                      }}
                      setSortingState={(sortObject: {
                        key: keyof TaskModel
                        order: string
                      }) => {
                        if (
                          sortObject.key === "created_at" &&
                          sortObject.order === "DESC"
                        ) {
                          return setSortByQuery(null)
                        }
                        return setSortByQuery(
                          `${sortObject.key}:${sortObject.order}`
                        )
                      }}
                      sortingOptions={taskSortingOptions}
                      showBadge={
                        !!sortByQuery && !sortByQuery.includes("undefined")
                      }
                    />
                    <FilteringPopper
                      filteringState={{
                        key: filterByQuery?.split(":")[0] as keyof TaskModel,
                        filter: filterByQuery?.split(":")[1],
                      }}
                      setFilteringState={(filterObject) => {
                        return setFilterByQuery(
                          `${filterObject.key}:${filterObject.filter}`
                        )
                      }}
                      filteringOptions={taskFilteringOptions}
                      showBadge={
                        !!filterByQuery && !filterByQuery.includes("undefined")
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Divider />
              {taskList && (
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={16}
                  flex={1}
                  marginTop={2}
                  overflow="auto">
                  {renderGroupedTasks(
                    groupByQuery as GroupByOption,
                    tasksToDisplay,
                    retreat,
                    Object.values(users).filter((user) => user) as UserModel[]
                  )}
                </Box>
              )}
            </Box>
            <CommentThreads
              emptyMessage="No comment threads available.  Comment on a task to start one"
              onClose={() => {
                setCommentsQuery(null)
              }}
              onOpen={() => {
                setCommentsQuery("open")
              }}
              open={commentsQuery === "open"}
              threadObjects={(
                Object.values(taskList).filter(
                  (task) => task && task.comment_thread_id
                ) as TaskModel[]
              ).map<ThreadObject>((task) => {
                return {
                  onSubmit: async (values) => {
                    dispatch(
                      postComment({
                        ...values,
                        comment_thread_id: task.comment_thread_id,
                        retreat_id: retreat.id,
                        redirect: `/tasks/${task.id}?comment=:id`,
                      })
                    )
                  },
                  title: task.title,
                  threadId: task.comment_thread_id,
                }
              })}
            />
          </Box>
        ) : (
          <Box>
            <AppHeaderWithSettings
              primaryHeader="Task"
              secondaryHeader="List"
              hideSettingsIcon
            />
            <Box display={"flex"} flexDirection="column">
              <Box
                marginLeft="auto"
                marginRight="auto"
                marginBottom={2}
                display={"flex"}
                flexDirection="column"
                maxWidth={"600px"}
                gridGap={8}
                textAlign="center">
                <AppTypography variant="h2">
                  We're excited for you to get started with Tasks
                </AppTypography>
                <AppTypography variant="body1">
                  In this module, you will be able to create and assign tasks in
                  order to efficiently plan your retreat. You can use the add
                  button on the next page to add task templates or create brand
                  new tasks!
                </AppTypography>
              </Box>
              <Box marginLeft="auto" marginRight="auto" marginBottom={2}></Box>

              <Box maxWidth={"400px"} marginLeft="auto" marginRight="auto">
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={postingLabels}
                  onClick={async () => {
                    setPostingLabels(true)
                    await Promise.all(
                      DEFAULT_LABELS.filter((defaultLabel) => {
                        return (
                          Object.values(retreatLabels).findIndex(
                            (label) => label?.text === defaultLabel.text
                          ) === -1
                        )
                      }).map((defaultLabel) => {
                        return dispatch(
                          postLabel({
                            text: defaultLabel.text,
                            retreat_id: retreat.id,
                          })
                        )
                      })
                    )
                    setPostingLabels(false)
                  }}>
                  Get Started
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </div>
    </PageBody>
  )
}

function LabelHeader(props: {labelId: number; number: number}) {
  let [label] = useLabel(props.labelId)
  return (
    <Typography variant="h2">{`${label?.text} (${props.number})`}</Typography>
  )
}

function TaskGroup(props: {
  title?: string
  items: ReactElement
  header?: ReactElement
}) {
  let [expanded, setExpanded] = useState(true)
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      width="100%"
      gridGap={16}
      marginBottom={2}>
      <Box
        onClick={() => {
          setExpanded((expanded) => !expanded)
        }}
        display={"flex"}
        flexDirection="row"
        width={"100%"}
        alignItems={"center"}
        justifyContent="space-between">
        {props.header ? (
          props.header
        ) : (
          <Typography variant="h2">{props.title}</Typography>
        )}
        <IconButton>
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Box>
      {expanded && props.items}
    </Box>
  )
}
