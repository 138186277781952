import {RetreatAttendeeModel} from "../../models/retreat"
import AppTypography from "../base/AppTypography"
import FormResponseViewer from "../forms/FormResponseViewer"

type AttendeeRegResponseTabProps = {
  attendee: RetreatAttendeeModel
}

export default function AttendeeFlightTab(props: AttendeeRegResponseTabProps) {
  return (
    <>
      <AppTypography variant="h1">Registration Form Response</AppTypography>
      {props.attendee.registration_form_response_id != null ? (
        <FormResponseViewer
          formResponseId={props.attendee.registration_form_response_id}
        />
      ) : (
        <></>
      )}
    </>
  )
}
