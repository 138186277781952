import {Badge, Box, Fade, Menu, MenuItem} from "@material-ui/core"
import {Notifications} from "@material-ui/icons"
import {push} from "connected-react-router"
import {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NotificationModel} from "../../models"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {RootState} from "../../store"
import {seeNotification} from "../../store/actions/retreat"
import {useNotifications} from "../../utils/retreatUtils"
import AppTypography from "./AppTypography"

function redirectToPush(redirect: string, retreatIdx: number) {
  return redirect.replace("%%RETREAT_BASE_URL%%", `/r/${retreatIdx}`)
}
export default function AppNotificationsMenuItem() {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const menuOpen = Boolean(anchorEl)
  let [retreat, retreatIdx] = useRetreat()
  let [offset, setOffset] = useState(0)
  let [notifications] = useNotifications(retreat.id, offset)
  let dispatch = useDispatch()
  let hasNext = useSelector((state: RootState) => {
    return state.retreat.notificationsHasNext
  })
  let unreadTotal = useSelector((state: RootState) => {
    return state.retreat.unreadNotificationTotal
  })
  return (
    <>
      <Box maxHeight={"400px"}>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={menuOpen}
          onClose={() => {
            setAnchorEl(null)
          }}
          TransitionComponent={Fade}>
          {(Object.values(notifications) as NotificationModel[])
            .sort((notificationA, notificationB) => {
              return new Date(notificationB.created_at) <
                new Date(notificationA.created_at)
                ? -1
                : 1
            })
            .map((notification) => {
              return (
                <MenuItem
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 16,
                  }}
                  onClick={() => {
                    dispatch(
                      push(redirectToPush(notification.redirect, retreatIdx))
                    )
                    dispatch(seeNotification(notification.id))
                    setAnchorEl(null)
                  }}>
                  <Box display={"flex"} flexDirection="row">
                    <Box display="flex" flexDirection={"column"}>
                      <AppTypography> {notification.message}</AppTypography>
                      <p
                        style={{
                          textAlign: "left",
                          color: "gray",
                          marginTop: 2,
                        }}>
                        {new Intl.DateTimeFormat("en-US", {
                          timeStyle: "short",
                          dateStyle: "short",
                        }).format(new Date(notification.created_at))}
                      </p>
                    </Box>
                    <Box marginLeft={1} height={"15px"} width={"15px"}>
                      {!notification.seen && (
                        <Box
                          marginTop={1}
                          // marginLeft={1}
                          height={"15px"}
                          width={"15px"}
                          borderRadius={"50%"}
                          bgcolor={"primary.main"}></Box>
                      )}
                    </Box>
                  </Box>
                </MenuItem>
              )
            })}
          {Object.values(notifications).length === 0 && (
            <MenuItem>No notifications available</MenuItem>
          )}
          {hasNext && (
            <MenuItem
              onClick={() => {
                setOffset((offset) => offset + 5)
              }}>
              See more
            </MenuItem>
          )}
        </Menu>
      </Box>
      <MenuItem
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={(event) => {
          event.stopPropagation()
          setAnchorEl(event.currentTarget)
        }}>
        <Box minWidth={"35px"}>
          <Badge
            color={"secondary"}
            invisible={unreadTotal < 1}
            overlap="circle"
            badgeContent={unreadTotal}>
            <Notifications />
          </Badge>
        </Box>
      </MenuItem>
    </>
  )
}
