import {useEffect, useState} from "react"
import {useQuery} from "."

/**
 * Meant to be used to scroll to a comment within a component on load.
 * Can be more generalized later if we want ability to scroll to other items
 * Do not use with another Query with key "comment"
 */
export function useScrollToComment() {
  let [commentIdQuery, setCommentIdQuery] = useQuery("comment")
  let [commentId, setCommentId] = useState(commentIdQuery)
  useEffect(() => {
    if (commentIdQuery) {
      setCommentId(commentIdQuery)
    }
  }, [commentIdQuery])
  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`comment-${commentId}`) &&
        document
          .getElementById(`comment-${commentId}`)!
          .scrollIntoView({behavior: "smooth"})
      setCommentIdQuery(null)
    }, 500)
  }, [commentId, setCommentIdQuery])
  return commentId ? parseInt(commentId) : undefined
}
