import {Box, IconButton, Paper, Slide, useTheme} from "@material-ui/core"
import {ArrowForward, Comment} from "@material-ui/icons"
import {RawDraftContentState} from "draft-js"
import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {RootState} from "../../store"
import CommentThread from "./CommentThread"

export type ThreadObject = {
  title: string
  threadId?: number
  onSubmit: (values: {comment_text: RawDraftContentState}) => void
  sticky?: true
}
export default function CommentThreads(props: {
  open: boolean
  onClose: () => void
  threadObjects: ThreadObject[]
  onOpen: () => void
  emptyMessage?: string
}) {
  function getWindowSize() {
    const {innerWidth, innerHeight} = window
    return {innerWidth, innerHeight}
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowSize, setWindowSize] = useState(getWindowSize())
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])
  let allCommentThreads = useSelector((state: RootState) => {
    return state.retreat.commentThreads
  })
  let comments = useSelector((state: RootState) => {
    return state.retreat.comments
  })
  let theme = useTheme()
  return props.open ? (
    <Slide direction="left" in={props.open} mountOnEnter unmountOnExit>
      <Box
        height={"100%"}
        style={{overflowY: "scroll"}}
        display={"flex"}
        flexDirection={"column"}
        gridGap={16}
        width={"350px"}>
        <Box display="flex" justifyContent={"space-between"}>
          <Box
            bgcolor={"primary.main"}
            height="35px"
            width="35px"
            padding={2}
            clone
            color="common.white">
            <IconButton size="medium" disabled={!props.open}>
              <Comment />
            </IconButton>
          </Box>
          <IconButton size="small" onClick={props.onClose}>
            <ArrowForward />
          </IconButton>
        </Box>
        {props.threadObjects.length === 0 && (
          <Box>
            <Paper style={{padding: theme.spacing(2)}}>
              {props.emptyMessage ?? "No Comment threads created yet"}
            </Paper>
          </Box>
        )}
        {props.threadObjects
          .sort((objA, objB) => {
            if (objA.sticky && objB.sticky) {
              return 0
            } else if (objA.sticky || objB.sticky) {
              return objA.sticky ? -1 : 1
            }
            if (objA && objB && objA.threadId && objB.threadId) {
              let threadA = allCommentThreads[objA.threadId]
              let threadB = allCommentThreads[objB.threadId]
              if (threadA && threadB) {
                let lastCommentA =
                  comments[threadA.comment_ids[threadA.comment_ids.length - 1]]
                let lastCommentB =
                  comments[threadB.comment_ids[threadB.comment_ids.length - 1]]
                if (lastCommentA && lastCommentB) {
                  return new Date(lastCommentB.created_at) <
                    new Date(lastCommentA.created_at)
                    ? -1
                    : 1
                } else {
                  return 0
                }
              } else {
                return 0
              }
            } else {
              return 0
            }
          })
          .map((obj) => {
            return (
              <CommentThread
                key={obj.threadId}
                onSubmit={obj.onSubmit}
                title={obj.title}
                threadId={obj.threadId}
              />
            )
          })}
      </Box>
    </Slide>
  ) : (
    <Box
      bgcolor={"primary.main"}
      height="35px"
      width="35px"
      padding={2}
      clone
      color="common.white">
      <IconButton size="medium" onClick={props.onOpen}>
        <Comment />
      </IconButton>
    </Box>
  )
}
