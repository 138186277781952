import {
  Badge,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
  withStyles,
} from "@material-ui/core"
import {ArrowDownward, ArrowUpward, ImportExport} from "@material-ui/icons"
import React from "react"

import {TaskModel} from "../../models/task"

let useStyles = makeStyles((theme) => ({
  headerButton: {
    margin: "10px",
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

type SortingPopperProps = {
  setSortingState: (sortObject: {key: keyof TaskModel; order: string}) => void
  sortingState: {key: keyof TaskModel; order: string}
  sortingOptions: {key?: string; label: string}[]
  showBadge: boolean
}
export function SortingPopper(props: SortingPopperProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  let {setSortingState, sortingState, sortingOptions, showBadge} = props
  let classes = useStyles()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  let open = Boolean(anchorEl)
  const id = open ? "sorting-popper" : undefined

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  function handleMenuClick(
    newSorting: {key: keyof TaskModel; order: string},
    oldSorting: {key: keyof TaskModel; order: string}
  ) {
    if (newSorting.key === oldSorting.key) {
      setSortingState({
        ...oldSorting,
        order: oldSorting.order === "ASC" ? "DESC" : "ASC",
      })
    } else {
      setSortingState(newSorting)
    }
  }

  return (
    <div>
      <Button
        aria-describedby={id}
        aria-controls="fade-menu"
        aria-haspopup="true"
        type="button"
        variant="contained"
        className={classes.headerButton}
        onClick={handleClick}>
        <ImportExport fontSize="small" />

        <Badge color={"primary"} variant={"dot"} invisible={!showBadge}>
          <Typography>Sort</Typography>
        </Badge>
      </Button>

      <StyledMenu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disableAutoFocusItem
        onClose={handleMenuClose}
        keepMounted>
        {sortingOptions.map((option) => {
          return (
            <MenuItem
              onClick={() =>
                handleMenuClick(
                  {key: option.key as keyof TaskModel, order: "ASC"},
                  sortingState
                )
              }
              key={option.key}>
              {option.key ? (
                <ListItemIcon>
                  {sortingState.key === option.key ? (
                    sortingState.order === "ASC" ? (
                      <ArrowUpward color="action" fontSize="small" />
                    ) : (
                      <ArrowDownward color="action" fontSize="small" />
                    )
                  ) : (
                    <></>
                  )}
                </ListItemIcon>
              ) : (
                <Box width={"39%"}></Box>
              )}
              <ListItemText primary={option.label} />
            </MenuItem>
          )
        })}
      </StyledMenu>
    </div>
  )
}
