import {Box, IconButton, useTheme} from "@material-ui/core"
import {Send} from "@material-ui/icons"
import {convertToRaw, EditorState, RawDraftContentState} from "draft-js"
import {useFormik} from "formik"
import _ from "lodash"
import {useDispatch} from "react-redux"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {useUsers} from "../../utils/retreatUtils"
import {AppWysiwygEditor} from "../base/AppWysiwyg"

export default function CommentForm(props: {
  onSubmit: (values: {
    comment_text: RawDraftContentState
    user_ids?: number[]
  }) => void
  disableToolbar?: true
}) {
  let formik = useFormik({
    initialValues: {
      comment_text: EditorState.createEmpty(),
    },
    onSubmit: (values) => {
      props.onSubmit({
        comment_text: convertToRaw(values.comment_text.getCurrentContent()),
      })
    },
  })
  let dispatch = useDispatch()
  let theme = useTheme()
  let [retreat] = useRetreat()
  let [users] = useUsers(retreat.users)

  return (
    <Box display={"flex"} gridGap={8} alignItems="center">
      <Box
        flex={1}
        border="1px solid grey"
        maxWidth={"calc(100% - 70px)"}
        bgcolor={"white"}
        borderRadius={theme.shape.borderRadius}>
        <AppWysiwygEditor
          mentionSuggestions={Object.values(users)
            .filter((user) => user)
            .map((user) => {
              return {
                text: `${user?.first_name} ${user?.last_name}`,
                value: `${user?.first_name} ${user?.last_name}`,
                url: `${user?.id}`,
              }
            })}
          disableToolbar={props.disableToolbar}
          editorState={formik.values.comment_text}
          onEditorStateChange={(val) => {
            formik.setFieldValue("comment_text", val)
          }}
        />
      </Box>
      <Box clone bgcolor={"primary.main"} color={"common.white"}>
        <IconButton
          disabled={_.isEqual(
            convertToRaw(formik.values.comment_text.getCurrentContent()),
            convertToRaw(formik.initialValues.comment_text.getCurrentContent())
          )}
          onClick={() => {
            let mentionsArray = Object.values(
              convertToRaw(formik.values.comment_text.getCurrentContent())
                .entityMap
            )
              .filter((entity) => entity.type === "MENTION")
              .map((entity) => entity.data)
            props.onSubmit({
              comment_text: convertToRaw(
                formik.values.comment_text.getCurrentContent()
              ),
              user_ids: mentionsArray.map((data) => data.url),
            })
            formik.resetForm()
          }}>
          <Send />
        </IconButton>
      </Box>
    </Box>
  )
}
