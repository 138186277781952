import {
  Badge,
  Box,
  Button,
  makeStyles,
  Menu,
  MenuProps,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import {Tune} from "@material-ui/icons"
import React, {useState} from "react"
import {useSelector} from "react-redux"
import {TaskModel} from "../../models/task"
import {RootState} from "../../store"

let useStyles = makeStyles((theme) => ({
  paper: {
    cursor: "pointer",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  select: {minWidth: "160px"},
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  headerButton: {
    margin: "10px",
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

//FILTERING POPPER BUTTON - START
type FilteringPopperProps = {
  filteringState: {key: keyof TaskModel; filter: any}
  setFilteringState: (filterObject: {key: keyof TaskModel; filter: any}) => void
  filteringOptions: {
    [key: string]: {
      key?: string
      label?: string
      optionsArray: {optionValue?: string | number; optionLabel?: string}[]
    }
  }
  showBadge: boolean
}
export function FilteringPopper(props: FilteringPopperProps) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  let {filteringState, setFilteringState, filteringOptions, showBadge} = props
  let labelOptions = useSelector((state: RootState) => {
    return state.retreat.labels
  })
  let assigneeOptions = useSelector((state: RootState) => {
    return state.retreat.users
  })

  filteringOptions = {
    ...filteringOptions,
    labels: {
      key: "label",
      label: "Label",
      optionsArray: Object.values(labelOptions).map((label) => {
        return {
          optionValue: label?.id,
          optionLabel: label?.text,
        }
      }),
    },
    assignees: {
      key: "assignees",
      label: "Assignee",
      optionsArray: Object.values(assigneeOptions).map((user) => {
        return {
          optionValue: user?.id,
          optionLabel: `${user?.first_name} ${user?.last_name}`,
        }
      }),
    },
  }
  filteringOptions.labels.optionsArray.unshift({
    optionValue: undefined,
    optionLabel: undefined,
  })
  filteringOptions.assignees.optionsArray.unshift({
    optionValue: undefined,
    optionLabel: undefined,
  })
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  let [availableFilterOptions, setAvailableFilterOptions] = useState(
    [] as any[]
  )

  const handleKeyChange = (
    key: keyof TaskModel,
    availableOptions: object[]
  ) => {
    setFilteringState({key: key as keyof TaskModel, filter: undefined})
    setAvailableFilterOptions(filteringOptions[key].optionsArray)
  }

  //Pending filters: Assignee, CreatedBy,

  const open = Boolean(anchorEl)
  const id = open ? "filtering-popper" : undefined
  return (
    <div>
      <Button
        aria-describedby={id}
        aria-controls="fade-menu"
        aria-haspopup="true"
        type="button"
        variant="contained"
        className={classes.headerButton}
        onClick={handleClick}>
        <Tune fontSize="small" />
        <Badge color={"primary"} variant={"dot"} invisible={!showBadge}>
          <Typography>Filter</Typography>
        </Badge>
      </Button>

      <StyledMenu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        disableAutoFocusItem
        keepMounted>
        <Box className={classes.paper}>
          <Box>
            <Box className={classes.container}>
              <Box margin="7px">
                <TextField
                  select
                  label="Field"
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{shrink: true}}
                  value={filteringState.key}
                  variant="outlined"
                  size="small"
                  className={classes.select}
                  onChange={(e) =>
                    typeof e.target.value === "string" &&
                    handleKeyChange(
                      e.target.value as keyof TaskModel,
                      filteringOptions[e.target.value].optionsArray
                    )
                  }>
                  {Object.keys(filteringOptions).map(
                    (filteringOption: string) => {
                      return (
                        <option value={filteringOption} key={filteringOption}>
                          {filteringOptions[filteringOption].label}
                        </option>
                      )
                    }
                  )}
                </TextField>
              </Box>
              <Box margin="7px">
                <TextField
                  label="Filter"
                  InputLabelProps={{shrink: true}}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  disabled={
                    filteringState.key === ("showAll" as keyof TaskModel)
                      ? true
                      : false
                  }
                  value={filteringState.filter}
                  variant="outlined"
                  size="small"
                  className={classes.select}
                  onChange={(e) => {
                    setFilteringState({
                      ...filteringState,
                      filter: e.target.value,
                    })
                  }}>
                  {availableFilterOptions ? (
                    availableFilterOptions.map((availableFilterOption) => {
                      return (
                        <option
                          value={availableFilterOption.optionValue}
                          key={availableFilterOption.optionValue}>
                          {availableFilterOption.optionLabel}
                        </option>
                      )
                    })
                  ) : (
                    <></>
                  )}
                </TextField>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledMenu>
    </div>
  )
}

//FILTERING POPPER BUTTON - END
